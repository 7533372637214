import React, {useContext} from 'react';
import cl from "./TarifDivForOrder.module.css";
import NumberInput from "../UI/input/NumberInput";
import Button from '@mui/material/Button';
import {ThemeProvider} from "@mui/material";
import {AuthContext} from "../context";

const TarifDivForOrder = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const {theme,setModalCollectionVisible,setImageCollection,setImageCollectionWithSignature} = useContext(AuthContext)

    function getNameAndPhoto() {
        if (props.tarif.minPhoto > 0 && props.tarif.maxPhoto > 0) {
            if (props.tarif.minPhoto === props.tarif.maxPhoto) {
                return props.tarif.tarifName + '  ' + props.tarif.minPhoto + ' фото';
            } else {
                return props.tarif.tarifName + '  ' + props.tarif.minPhoto + '-' + props.tarif.maxPhoto + ' фото';
            }
        } else {
            return props.tarif.tarifName;
        }
    }

    return (
        <div className={cl.tarif_block_conteiner}>
            <ThemeProvider theme={theme}>
                {
                    props.tarif.imageCollection &&
                    <Button
                        color="primary"
                        // className={cl.info__button}
                        style={{position: 'absolute', left: '-15px', top: '-18px'}}
                        variant="contained"
                        onClick={(event) => {
                            setImageCollectionWithSignature(false)
                            setImageCollection(props.tarif.imageCollection)
                            setModalCollectionVisible(true)
                            }}>
                        Примеры результата
                    </Button>

                }
                <div className={cl.tarif_desc_conteiner}>
                    <div>
                        ' '
                    </div>
                    <div
                        className={cl.tarif_block_name}
                        // onClick={event => setModalByCollection(event)}
                    >
                        {getNameAndPhoto()}
                    </div>
                    <div className={cl.tarif_desc_style}>
                        {props.tarif.descStylish}
                    </div>
                    <div className={cl.tarif_desc_posing}>
                        {props.tarif.descPosing}
                    </div>
                    {props.tarif.tarifType === "PHOTO" &&
                        <div className={cl.tarif_desc_posing}>
                            Отпаривание
                        </div>
                    }
                </div>
                <div className={cl.tarif_block_cost}>
                    <NumberInput
                        ident={props.ident}
                        tarifId={props.tarif.id}
                        modelId={props.modelId}
                        shootId={props.shootId}
                        backId={props.backId}
                        name={props.tarif.tarifName}
                        oneTime={props.tarif.oneTime}
                        structureRequest={props.structureRequest}
                        setStructureRequest={props.setStructureRequest}
                        baseCost={props.tarif.baseCost}/>
                </div>
            </ThemeProvider>
        </div>
    );
};

export default TarifDivForOrder;