import React, {useContext} from 'react';
import {Route, Routes} from "react-router-dom";
import TimeTable from "./Pages/TimeTable";
import Admin from "./Pages/Admin";
import ShowModel from "./Pages/ShowModel";
import AllModels from "./Pages/AllModels";
import Login from "./Pages/Login";
import {AuthContext} from "./context";
import Tarifs from "./Pages/Tarifs";
import AdminRequest from "./Pages/AdminRequest";
import AdminShooting from "./Pages/AdminShooting";
import AdminOrder from "./Pages/AdminOrder";
import AdminShoot from "./Pages/AdminShoot";
import AdminLocation from "./Pages/AdminLocation";
import AdminTarif from "./Pages/AdminTarif";
import AdminFinance from "./Pages/AdminFinance";
import AdminClient from "./Pages/AdminClient";
import AdminPattern from "./Pages/AdminPattern";
import AdminModelCollection from "./Pages/AdminModelCollection";
import AdminBooking from "./Pages/AdminBooking";

const AppRouter = () => {
    const {isAuth, role} = useContext(AuthContext)
    return (
        <Routes>
            <Route exact path="/" element={<TimeTable/>}></Route>
            <Route exact path="/model/:id/" element={<ShowModel/>}></Route>
            <Route exact path="/tarifs/" element={<Tarifs/>}></Route>
            <Route exact path="/model/:id/day/:dayid" element={<ShowModel/>}></Route>
            <Route exact path="/model_collection/:id" element={<AllModels/>}></Route>
            <Route path="/login" element={<Login/>}/>
            <Route path="/models/" element={<AllModels/>}/>
            {/*Заготовка только для Админов*/}
            {isAuth && role === "ADMIN" &&
                [
                    <Route path="/admin/cost_pattern" element={<AdminPattern/>}/>,
                    <Route path="/admin/finances" element={<AdminFinance/>}/>
                ]
            }
            {/*Заготовка только для менеджеров и админов*/}
            {isAuth && (role === "MANAGER" || role === "ADMIN") &&
                [
                    <Route path="/admin/requests" element={<AdminRequest/>}/>,
                    <Route path="/admin/tarifs" element={<AdminTarif/>}/>,
                    <Route path="/admin/locations" element={<AdminLocation/>}/>,
                    <Route path="/admin/orders" element={<AdminOrder/>}/>,
                    <Route path="/admin/clients" element={<AdminClient/>}/>
                ]
            }
            {/*Заготовка для всех остальных*/}
            {isAuth &&
                [
                    <Route path="/admin" element={<Admin/>}/>,
                    <Route path="/admin/shootings" element={<AdminShooting/>}/>,
                    <Route path="/admin/bookings" element={<AdminBooking/>}/>,
                    <Route path="/admin/shoot" element={<AdminShoot/>}/>,
                    <Route path="/admin/model_collection" element={<AdminModelCollection/>}/>,
                ]
            }
            <Route path="*" element={<TimeTable/>}/>
        </Routes>
    );
};

export default AppRouter;