import React from 'react';
import cl from "./TarifBlock.module.css"
import Button from "@mui/material/Button";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

const TarifsBlock = (props) => {

    return (
        <div className={cl.tarif_conteiner}>
            <ToggleButtonGroup
                sx={{ flexWrap: "wrap"}}
                color="primary"
                value={props.tarif}
                exclusive
                aria-label="Platform"
                fullWidth
            >
                {props.tarifs.map((tar) => (
                    <ToggleButton
                        size='small'
                        key={tar.id}
                        onClick={() => (props.setTarif(tar))}
                        value={tar.id}
                    >
                        {tar.tarifTypeName} - {tar.goodTypeName} - {tar.tarifName} - {tar.baseCost}
                    </ToggleButton>
                ))
                }
            </ToggleButtonGroup>

        </div>
    );
};

export default TarifsBlock;