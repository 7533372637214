import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import ModelService from "../API/ModelService";
import Loader from "../UI/Loader/Loader";
import ModelListMain from "../UI/list/ModelListMain";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";
import UniversalFilter from "../Forms/UniversalFilter";
import {AuthContext} from "../context";
import {ThemeProvider} from "@mui/material";

const AllModels = () => {
    const [models, setModels] = useState([])

    const {
        theme, startModelCustomFilter, modelCustomFilter, setModelCustomFilter
    } = useContext(AuthContext)

    const params = useParams();

    const [title, setTitle] = useState("Все модели");

    const [filterModelsModal, setFilterModelsModal] = useState(false);

    const [fetchModels, isModelLoading, modelError] = useFetching(async () => {
        if (params.id != null) {
            // Запросим коллекцию
            const modelCollection = await ModelService.getModelCollection(params.id);
            console.log(modelCollection.collection)
            setModels(modelCollection.collection)
            setTitle("Подборка моделей " + modelCollection.collectionName)
        } else {
            const models = await ModelService.getAllOptimized(modelCustomFilter);
            console.log(models)
            setModels(models)
        }
    })

    useEffect(() => {
        console.log("fetch models")
        if (filterModelsModal === false) {
            fetchModels()
        }
    }, [modelCustomFilter])

    function onApplyFilterModal() {
        fetchModels()
        setFilterModelsModal(false)
    }

    function clearModelsFilter() {
        setModelCustomFilter(startModelCustomFilter)
    }

    return (
        <div>
            <Helmet>
                <title>IXOR Production - Съёмки для маркетплейсов - {title}</title>
                <meta name="description" content="Выбрать модель для индивидуальной или сборной съемки"/>
            </Helmet>
            {modelError &&
                <h3> Произошла ошибка ${modelError}</h3>}
            {isModelLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                : <div>
                    <ThemeProvider theme={theme}>
                        <div style={{marginTop: "3px", marginLeft: "5px"}}>
                            <UniversalFilter
                                onApply={onApplyFilterModal}
                                clearFilter={clearModelsFilter}
                                mainFilter={modelCustomFilter}
                                setMainFilter={setModelCustomFilter}
                                setVisibleModal={setFilterModelsModal}
                                visibleModal={filterModelsModal}
                            />
                        </div>
                    </ThemeProvider>
                    <ModelListMain models={models} title={title}/>
                </div>
            }
        </div>
    );
};

export default AllModels;