import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import OrderItem from "../UI/list/OrderItem";
import MyModal90 from "../UI/MyModal/MyModal90";
import ClientsModalForm from "../Forms/ClientsModalForm";
import {
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import MyModal from "../UI/MyModal/MyModal";
import TarifService from "../API/TarifService";
import LocationsService from "../API/LocationService";
import ModelForm from "../Forms/ModelForm";
import FileUpload from "../UI/Loader/FileUpload";
import ClientForm from "../Forms/ClientForm";
import {AuthContext} from "../context";
import UniversalFilter from "../Forms/UniversalFilter";


const AdminTarif = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [clients, setClients] = useState([]);
    const [client, setClient] = useState({});
    const [visibleClientForm, setVisibleClientForm] = useState(false);
    const [filterModal, setFilterModal] = useState(false);

    const {
        startClientFilter, clientFilter, setClientFilter, setOrderFilter
    } = useContext(AuthContext)
    const navigate = useNavigate();


    const [fetchClients, isClientsLoading, clientsError] = useFetching(async () => {
        console.log("fetch clients")
        const orders = await RequestService.getAllFilteredClients(clientFilter);
        console.log(orders)
        setClients(orders)
    })

    useEffect(() => {
        console.log("use effect clients")
        fetchClients()
        clearClient()
    }, [clientFilter]);


    function clearClient() {
        setClient({
            name: ''
            , sale: 0
            , comment: ''
            , contacts: []
        })
    }

    function clearFilter() {
        setClientFilter(startClientFilter)
    }

    function onApplyFilterModal() {
        fetchClients()
        setFilterModal(false)
    }

    return (
        <div className="vertical_left_conteiner">
            <MyModal90 visible={visibleClientForm} setVisible={setVisibleClientForm}>
                <ClientForm clearClient={clearClient} client={client} setClient={setClient}
                            setVisibleClientForm={setVisibleClientForm}/>
            </MyModal90>
            <UniversalFilter
                onApply={onApplyFilterModal}
                clearFilter={clearFilter}
                mainFilter={clientFilter}
                setMainFilter={setClientFilter}
                setVisibleModal={setFilterModal}
                visibleModal={filterModal}
            />
            <Button
                onClick={(e) => {
                    clearClient();
                    setVisibleClientForm(true)
                }}>Создать нового клиента</Button>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{padding: "2px"}}>№</TableCell>
                            <TableCell sx={{padding: "2px"}}>Имя</TableCell>
                            <TableCell sx={{padding: "2px"}}>Заказы</TableCell>
                            <TableCell sx={{padding: "2px"}} align="right">Коммент</TableCell>
                            <TableCell sx={{padding: "2px"}} align="right">Контакты</TableCell>
                            <TableCell sx={{padding: "2px"}} align="right">Скидка</TableCell>
                            <TableCell sx={{padding: "2px"}} align="right">Ред</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clients.map((client) => (
                            <TableRow key={client.id} sx={{padding: "2px"}}>
                                <TableCell sx={{padding: "2px"}}>{client.id}</TableCell>
                                <TableCell sx={{padding: "2px"}}>{client.name}</TableCell>
                                <TableCell sx={{padding: "2px"}}>
                                    <Button
                                        sx={{padding: "0px"}}
                                        size="small"
                                        onClick={(e) => {
                                            setOrderFilter((oldOrderFilter) => oldOrderFilter.map(el => el.code !== "clientName"
                                                ? {...el, checked: false}
                                                : {...el, checked: true, value: client.name, valueForChip: client.name}
                                            ).map(el => el.code !== "sortid"
                                                ? {...el}
                                                : {...el, checked: true, value: "desc", valueForChip: "По убыванию"}))
                                            navigate("/admin/orders");
                                        }}
                                    >Заказы</Button>
                                </TableCell>
                                <TableCell sx={{padding: "2px"}}>{client.comment}</TableCell>
                                <TableCell sx={{padding: "2px"}}>
                                    <div className="vertical_left_conteiner">
                                        {
                                            client.contacts.map((cont) => (
                                                <div className="horizont_left_conteiner"
                                                     style={{border: "1px solid teal", borderRadius: "5px"}}
                                                     key={cont.id}>
                                                    {cont.contactType === "PHONE" &&
                                                        <div style={{margin: "2px"}}>
                                                            <a href={"tel:" + cont.value}>Позвонить {cont.comment} {cont.value}</a>
                                                        </div>
                                                    }
                                                    {cont.contactType === "TELEGRAM" &&
                                                        <div style={{margin: "2px"}}>
                                                            <a href={"tg://resolve?domain=" + (cont.value.startsWith("@") ? cont.value.substring(1) : cont.value)}>Телеграмм {cont.comment} {cont.value}</a>
                                                        </div>
                                                    }
                                                    {cont.contactType === "WHATSAPP" &&
                                                        <div style={{margin: "2px"}}>
                                                            <a href={"whatsapp://send?phone=" + cont.value}>WhatsApp {cont.comment} {cont.value}</a>
                                                        </div>
                                                    }
                                                    {cont.contactType === "COMMON_TELEGRAM" &&
                                                        <div style={{margin: "2px"}}>
                                                            <a href={"tg://resolve?domain=" + (cont.value.startsWith("@") ? cont.value.substring(1) : cont.value)}>Общий
                                                                чат {cont.comment} {cont.value}</a>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </TableCell>
                                <TableCell sx={{padding: "2px"}}>{client.sale}</TableCell>
                                <TableCell sx={{padding: "2px"}}>
                                    <Button
                                        sx={{padding: "0px"}}
                                        size="small"
                                        onClick={(e) => {
                                            setClient(client)
                                            setVisibleClientForm(true)
                                        }}>Ред</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AdminTarif;