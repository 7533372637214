import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import OrderItem from "../UI/list/OrderItem";
import MyModal90 from "../UI/MyModal/MyModal90";
import ClientsModalForm from "../Forms/ClientsModalForm";
import {
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MyModal from "../UI/MyModal/MyModal";
import TarifService from "../API/TarifService";
import LocationsService from "../API/LocationService";
import ModelForm from "../Forms/ModelForm";
import FileUpload from "../UI/Loader/FileUpload";


const AdminTarif = () => {

    const [tarifs, setTarifs] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesModal, setFilesModal] = useState(false);
    const [editRow, setEditRow] = useState({tarifName: ''});

    const [fetchTarifs, isTarifsLoading, tarifsError] = useFetching(async () => {
        const tarifs = await TarifService.getAllTarifs();
        console.log(tarifs)
        setTarifs(tarifs)
    })

    useEffect(() => {
        fetchTarifs()
    }, []);

    const tarifType =
        [
            {code: "STREET_PHOTO", name: "Street Фото"},
            {code: "STREET_VIDEO", name: "Street Видео"},
            {code: "PHOTO", name: "Фото"},
            {code: "VIDEO", name: "Видео"},
            {code: "DOP_OPTION", name: "Дополнительные опции"},
            {code: "AFTER_SHOOT", name: "Услуги после съемки"},
            {code: "SPETIAL", name: "Доступен только для менеджеров"},
            {code: "GOOD", name: "Тариф для предметки"},
        ]

    const goodTypeEnum =
        [
            {code: "CLOTHES_SHOES", name: "Одежда/Обувь"},
            {code: "LINGERIE", name: "Бельё"},
            {code: "ACCESSORIES", name: "Аксесуары/косметика"}
        ]

    const apiUrl = process.env.REACT_APP_API_URL;

    function saveCollection() {
        axios.post(
            apiUrl + 'tarifs/add',
            {
                ...editRow, imageCollection: {
                    ...editRow.imageCollection,
                    collection: [...files],
                    id: editRow.imageCollection ? editRow.imageCollection.id : null
                }
            }
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setFilesModal(false)
                setFiles([])
                fetchTarifs()
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div style={{minHeight: "90vh"}}>
            <MyModal90 visible={filesModal} setVisible={setFilesModal}>
                <div className="vertical_left_conteiner_bordered" style={{overflowY: "auto",height: "auto",maxHeight: "70vh"}}>
                    <div>
                    <TextField
                        required
                        label="Название"
                        value={editRow.tarifName}
                        onChange={e => setEditRow({...editRow, tarifName: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        required
                        type="number"
                        label="базовая цена"
                        value={editRow.baseCost}
                        onChange={e => setEditRow({...editRow, baseCost: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        required
                        type="number"
                        label="Минимальное кол-во фото"
                        value={editRow.minPhoto}
                        onChange={e => setEditRow({...editRow, minPhoto: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        required
                        type="number"
                        label="Максимальное кол-во фото"
                        value={editRow.maxPhoto}
                        onChange={e => setEditRow({...editRow, maxPhoto: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        required
                        label="Стилизация"
                        multiline
                        value={editRow.descStylish}
                        onChange={e => setEditRow({...editRow, descStylish: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        required
                        id="outlined-required1"
                        label="Позинг"
                        multiline
                        value={editRow.descPosing}
                        onChange={e => setEditRow({...editRow, descPosing: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                        <TextField
                            required
                            id="outlined-required1"
                            label="Ссылка на пример видео"
                            value={editRow.videoLink}
                            onChange={e => setEditRow({...editRow, videoLink: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                    <FormControlLabel control={
                        <Checkbox
                            checked={editRow.oneTime}
                            onChange={e => setEditRow({...editRow, oneTime: e.target.checked})}
                            inputProps={{'aria-label': 'controlled'}}
                            label='Берется один раз, больше 1 ввести не получится, например доставка'
                        />
                    } label="Берется один раз, больше 1 ввести не получится, например доставка"/>
                    <Select
                        labelId="demo-simple-select-label11"
                        id="demo-simple-select"
                        label="Типы товара для фотосъемки"
                        size="small"
                        onChange={e => setEditRow({...editRow, goodTypeEnum: e.target.value})}
                        value={editRow.goodTypeEnum}
                        sx={{minWidth: "150px", marginTop: "5px"}}
                    >
                        <MenuItem value={null}>Не определено</MenuItem>
                        {goodTypeEnum.map((option) => (
                            <MenuItem key={option.id} value={option.code}>{option.name}</MenuItem>
                        ))
                        }
                    </Select>
                    <Select
                        labelId="demo-simple-select-label123"
                        id="demo-simple-select"
                        label="Тип тарифа"
                        size="small"
                        onChange={e => setEditRow({...editRow, tarifType: e.target.value})}
                        value={editRow.tarifType}
                        sx={{minWidth: "150px", marginTop: "5px"}}
                    >
                        {tarifType.map((option) => (
                            <MenuItem key={option.id} value={option.code}>{option.name}</MenuItem>
                        ))
                        }
                    </Select>
                    </div>
                    <FileUpload files={files} setFiles={setFiles}/>
                    <Button onClick={saveCollection}>Сохранить</Button>
                </div>
            </MyModal90>
            {tarifsError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isTarifsLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '70px'}}><Loader/></div>
                :
                <div className={cl.main_conteiner}>
                    <h3>Тарифы</h3>
                    <Button onClick={(e) => {
                        setFiles([])
                        setEditRow({tarifName: "", baseCost: 0, videoLink : ""})
                        setFilesModal(true)
                    }}>Добавить тариф</Button>
                    {tarifs.map((tarif) => (
                        <div key={tarif.id} className="vertical_left_conteiner" style={{cursor: "pointer"}}
                             onClick={(e) => {
                                 setFiles(tarif.imageCollection ? tarif.imageCollection.collection : [])
                                 setEditRow(tarif)
                                 setFilesModal(true)
                             }}>
                            <div>{tarif.tarifTypeName} {tarif.goodTypeName} {tarif.tarifName}</div>
                            <div className="horizont_left_conteiner">
                                {tarif.imageCollection && tarif.imageCollection.collection.map((image) => (
                                    <div key={image.path} className="vertical_conteiner">
                                        <img className="example_img" src={`/images/small/${image.path}`}
                                             alt={image.alt}/>
                                        <div>{image.alt}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                    }
                </div>
            }
        </div>
    );
};

export default AdminTarif;