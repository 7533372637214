import React, {useContext} from 'react';
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../context";

const Login = () => {

    const [login, setLogin] = React.useState({username: "", password: ""});
    const {isAuth,setIsAuth,setRole} = useContext(AuthContext)

    const navigate = useNavigate();

    // axios.defaults.withCredentials = true;
    const apiUrl = process.env.REACT_APP_API_URL;


    function signup() {
        axios.post(
            apiUrl+ 'login',
            login,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                // let coockielist = document.cookie.split('; ')
                // let needCoockie;
                // console.log(coockielist)
                // for (let i =0 ;i< coockielist.length; i++) {
                //     console.log(coockielist[i])
                //     if (coockielist[i].startsWith("secret_cookie_masha")) {
                //         needCoockie = i;
                //         localStorage.setItem(coockielist[needCoockie].split("=")[0], coockielist[needCoockie].split("=")[1])
                //     }
                // }
                // console.log(needCoockie)
                console.log(document.cookie)
                setIsAuth(true)
                localStorage.setItem('auth', 'true')
                setRole(res.data.role)
                localStorage.setItem('role', res.data.role)
                navigate(`/admin/shootings`)

            })
            .catch(err => {
                console.log(err);
            })
    }


    return (
        <div style={{display: 'flex', flexDirection:"column", justifyContent:"center",alignItems : "center", height: '85vh'}}>
            <TextField
                required
                id="outlined-required"
                label="Логин"
                value={login.username}
                onChange={e => setLogin({...login, username: e.target.value})}
                margin="dense"
            />
            <TextField
                type="password"
                autoComplete="current-password"
                required
                id="outlined-required1"
                label="Пароль"
                value={login.password}
                onChange={e => setLogin({...login, password: e.target.value})}
                margin="dense"
            />
            <Button variant="outlined" onClick={signup}>Залогиниться</Button>


        </div>
    );
};

export default Login;