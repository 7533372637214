import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import MyModal90 from "../UI/MyModal/MyModal90";
import {
    FormControlLabel,
    MenuItem, Paper,
    Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import CostService from "../API/CostService";
import {usePosts} from "../hooks/MyHooks";
import ModelService from "../API/ModelService";
import ShootService from "../API/ShootService";
import LocationsService from "../API/LocationService";
import MyModal from "../UI/MyModal/MyModal";
import ModelForm from "../Forms/ModelForm";
import ShootCreateForm from "../Forms/ShootCreateForm";
import ShootList from "../UI/list/ShootList";
import PostFilter from "../PostFilter";
import ModelList from "../UI/list/ModelList";
import UniversalFilter from "../Forms/UniversalFilter";
import {AuthContext} from "../context";


const AdminModelCollection = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const {startModelFilter, modelFilter,setModelFilter} = useContext(AuthContext)

    const [editRow, setEditRow] = useState({collectionName: ''});
    const [indexEditCollection, setIndexEditCollection] = useState(0)
    const [modelCollections, setModelCollections] = useState([]);
    const [models, setModels] = useState([])
    const [modelCollectionModal, setModelCollectionModal] = useState(false);

    const [filterModelsModal, setFilterModelsModal] = useState(false);


    const [fetchModels, isModelLoading, modelError] = useFetching(async () => {
        const models = await ModelService.getAllFiltered(modelFilter);
        console.log(models)
        setModels(models)
    })

    const [fetchModelCollections, isModelCollectionLoading, modelCollectionError] = useFetching(async () => {
        const modelCollections = await ModelService.getAllModelCollections();
        console.log(modelCollections)
        setModelCollections(modelCollections)
    })


    useEffect(() => {
        console.log("fetch model collection")
        fetchModelCollections()
    }, [])

    useEffect(() => {
        console.log("fetch models")
        if (filterModelsModal === false) {
            fetchModels()
        }
    }, [modelFilter])

    function clearModelsFilter() {
        setModelFilter(startModelFilter)
    }

    function onApplyFilterModal() {
        fetchModels()
        setFilterModelsModal(false)
    }


    function removeModel(modelCollectionId, modelId) {
        axios.post(
            apiUrl + 'model_collections/del_model',
            {
                id : modelCollectionId,
                modelId: modelId
            }
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchModelCollections()
            })
            .catch(err => {
                console.log(err);
            })
    }

    function addToCollection(model) {
        axios.post(
            apiUrl + 'model_collections/add_model',
            {
                id : indexEditCollection,
                modelId: model.id
            }
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchModelCollections()
            })
            .catch(err => {
                console.log(err);
            })
    }


    function saveCollection() {
        console.log(editRow)
        axios.post(
            apiUrl + 'model_collections/add',
            editRow
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setModelCollectionModal(false)
                setIndexEditCollection(0)
                fetchModelCollections()
            })
            .catch(err => {
                console.log(err);
            })
    }

    function deleteCollection(mc) {
        console.log(mc)
        axios.post(
            apiUrl + 'model_collections/del',
            mc
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setIndexEditCollection(0)
                fetchModelCollections()
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className="App">
            <MyModal visible={modelCollectionModal} setVisible={setModelCollectionModal}>
                <div className="vertical_conteiner">
                    <TextField
                        required
                        label="Название подборки"
                        size="small"
                        fullWidth
                        value={editRow.collectionName}
                        onChange={e => setEditRow({...editRow, collectionName: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <Button onClick={saveCollection}>Добавить/Сохранить</Button>
                </div>
            </MyModal>
            <Button onClick={(e) => {
                setEditRow({collectionName: ''})
                setModelCollectionModal(true)
            }
            }>Добавить коллекцию</Button>

            {modelCollectionError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isModelCollectionLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                : <div>
                    <div className="vertical_left_conteiner">
                        {modelCollections.map((mc, index) => (
                            <div key={mc.id} style={{display: "grid", gridTemplateColumns: "1fr 4fr 1fr",border: "1px solid teal", borderRadius: "5px", width: "100%"}}>
                                <div style={{cursor: "pointer"}}
                                    onClick={(e)=> setIndexEditCollection(mc.id)}>
                                    {indexEditCollection === mc.id
                                        ? <strong>{index}.{mc.collectionName}</strong>
                                        : <div>{index}.{mc.collectionName}</div>
                                    }
                                </div>
                                <div className="horizont_left_conteiner">
                                    {mc.collection.map((model)=> (
                                        <div key={model.id} className="vertical_conteiner">
                                            <div>{model.name}</div>
                                            <img src={`/images/small/${model.photo}`} style={{height:"100px", width: "auto"}}/>
                                            <Button
                                                sx={{padding: "0px"}}
                                                size="small"
                                                onClick={(e) => {
                                                    removeModel(mc.id, model.id)
                                                }}>Удалить</Button>
                                        </div>
                                    ))
                                    }
                                </div>
                                <div className="horizont_left_conteiner">
                                    <Link target="_blank" rel="noopener noreferrer" to={`/model_collection/${mc.id}`}>Ссылка</Link>
                                    <Button
                                        sx={{padding: "0px"}}
                                        size="small"
                                        onClick={(e) => {
                                            setEditRow(mc)
                                            setModelCollectionModal(true)
                                        }}>Редакт</Button>
                                    <Button
                                        sx={{padding: "0px"}}
                                        size="small"
                                        onClick={(e) => {
                                            deleteCollection(mc);
                                        }}>Удалить</Button>
                                </div>


                            </div>
                        ))
                        }
                    </div>

                </div>
            }
            <UniversalFilter
                onApply={onApplyFilterModal}
                clearFilter={clearModelsFilter}
                mainFilter={modelFilter}
                setMainFilter={setModelFilter}
                setVisibleModal={setFilterModelsModal}
                visibleModal={filterModelsModal}
            />
            {modelError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isModelLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                :
                <ModelList models={models} shoots={[]} addToModelCollection={addToCollection}
                           title='Список моделей' modelCollection={true}/>
            }
        </div>
    );
};

export default AdminModelCollection;