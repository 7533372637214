import React, {useContext, useEffect, useState} from 'react';
import MyModal from "../UI/MyModal/MyModal";
import ModelForm from "../Forms/ModelForm";
import ShootCreateForm from "../Forms/ShootCreateForm";
import Loader from "../UI/Loader/Loader";
import ShootList from "../UI/list/ShootList";
import PostFilter from "../PostFilter";
import ModelList from "../UI/list/ModelList";

import {usePosts} from "../hooks/MyHooks";
import {useFetching} from "../hooks/useFetching";
import ModelService from "../API/ModelService";
import ShootService from "../API/ShootService";
import "../../styles/App.css"
import Button from "@mui/material/Button";
import {Checkbox, FormControlLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import LocationsService from "../API/LocationService";
import UniversalFilter from "../Forms/UniversalFilter";
import {AuthContext} from "../context";
import axios from "axios";
import BookingForm from "../Forms/BookingForm";

const AdminBooking = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const bookStatus =
        [
            {code: "PLANNING", name: "Планируется"},
            {code: "REQUESTED", name: "Запрошено подтверждение"},
            {code: "DISCUSSION_BOOK", name: "Переговоры о подтверждение"},
            {code: "BOOKED", name: "Подтверждена"},
            {code: "REQUESTED_CANCEL", name: "Запрошена отмена"},
            {code: "DISCUSSION_CANCEL", name: "Переговоры об отмене"},
            {code: "CANCELED_BY_ME", name: "Отменена"},
            {code: "CANCELED_BY_MODEL", name: "Отменена моделью"},
        ]

    const startModel = {
        name: ''
        , fio: ''
        , linkToPortpholio: ''
        , lingerie: false
        , male: false
        , clothes1Hour: 2000
        , clothes2Hour: 0
        , lingerie1Hour: 0
        , lingerie2Hour: 0
        , street1Hour: 0
        , shoeSize: '36'
        , plusSize: false
        , child: false
        , visible: true
        , deleted: false
        , good: false
        , cupSize: ''
        , clothingSize: ''
        , breastSize: 90
        , singularity: ''
        , waistSize: 60
        , hipSize: 90
        , height: 170
        , selfAssembly: 0
        , addCheck: 0
        , addCheckLingerie: 0
        , managerComment: ''
        , photo: ''
        , contacts: []
        , linkToSingularity: ''
    }

    const [editModel, setEditModel] = useState(startModel)
    const [modelModal, setModelModal] = useState(false)

    function clearModel() {
        setEditModel(startModel)
    }

    const addModel = (newModel) => {
        setModels(models.map((model) => model.id !== newModel.id ? model : newModel))
        setModelModal(false)
    }

    const [files, setFiles] = useState([]);
    const [models, setModels] = useState([])
    const [shoots, setShoots] = useState([])
    const [editBooking, setEditBooking] = useState()
    const [modalBookingVisible, setModalBookingVisible] = useState(false)


    const [shootsFilter, setShootsFilter] = useState({date: ''})


    function clearModelsFilter() {
        setModelFilter(startModelFilter)
    }

    const {startModelFilter, modelFilter, setModelFilter} = useContext(AuthContext)

    const [filterShootsModal, setFilterShootsModal] = useState(false);
    const [filterModelsModal, setFilterModelsModal] = useState(false);

    const [fetchModels, isModelLoading, modelError] = useFetching(async () => {
        const models = await ModelService.getAllFiltered(modelFilter);
        console.log(models)
        setModels(models)
    })

    const [fetchShoots, isShootLoading, shootsError] = useFetching(async () => {
        const shoots = await ShootService.getFilteredShoots(shootsFilter);
        console.log(shoots)
        setShoots(shoots)
    })

    useEffect(() => {
        console.log("fetch shoots")
        if (filterShootsModal === false) {
            fetchShoots()
        }
    }, [shootsFilter]);


    useEffect(() => {
        console.log("fetch models")
        if (filterModelsModal === false) {
            fetchModels()
        }
    }, [modelFilter])

    const removeModel = (id) => {
        setModels(models.filter((model) => id !== model.id))
    }

    const removeShoot = (id) => {
        setShoots(shoots.filter((shoot) => id !== shoot.id))
    }

    function onClickImg(booking) {
        // Фильтр по имени
        setEditBooking(booking)
        setModelFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== "name"
            ? {
                ...el,
                checked: false
            } : {
                ...el,
                value: booking.modelPerson.fio,
                valueForChip: booking.modelPerson.fio,
                checked: true
            }))
    }

    function onApplyFilterModal() {
        fetchModels()
        setFilterModelsModal(false)
    }

    function saveTiming() {
        console.log(editBooking)
        axios.post(
            apiUrl + 'booking/set_timing',
            editBooking
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchShoots()
                setModalBookingVisible(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className="App">
            <BookingForm visible={modalBookingVisible} setVisible={setModalBookingVisible}
                         setEditBooking={setEditBooking} editBooking={editBooking} saveTiming={saveTiming}
            />
            <ModelForm visible={modelModal} setVisible={setModelModal} clearModel={clearModel} create={addModel}
                       model={editModel} setModel={setEditModel}
                       setFiles={setFiles} files={files}/>
            <div className="horizont_left_conteiner">
                <Button size="small" variant="outlined" onClick={(e) => setFilterShootsModal(true)}>
                    Фильтр
                </Button>
                <Button size="small" variant="outlined" onClick={(e) => {
                    setShootsFilter({date: ''})
                }}>
                    Сбросить
                </Button>
            </div>

            <MyModal visible={filterShootsModal} setVisible={setFilterShootsModal}>
                <TextField
                    type='date'
                    variant='standard'
                    size='small'
                    id="outlined-textareaa"
                    label="месяц с даты"
                    onChange={(e) => setShootsFilter({...shootsFilter, date: e.target.value})}
                    value={shootsFilter.date}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                />
                <Button onClick={(e) => {
                    fetchShoots()
                    setFilterShootsModal(false)
                }}>Применить</Button>
            </MyModal>
            {shootsError &&
                <h3> Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isShootLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                : <ShootList
                    onClickImg={onClickImg}
                    shoots={shoots}
                    showDelButton={false}
                    showDelShootButton={false}
                    title='Ближайшие съемки'
                    booking={true}
                />
            }
            <hr style={{margin: '15px 0'}}/>
            <UniversalFilter
                onApply={onApplyFilterModal}
                clearFilter={clearModelsFilter}
                mainFilter={modelFilter}
                setMainFilter={setModelFilter}
                setVisibleModal={setFilterModelsModal}
                visibleModal={filterModelsModal}
            />
            {modelError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isModelLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                :
                <ModelList setModelModal={setModelModal} setFiles={setFiles}  files={files}  models={models} shoots={shoots} setModel={setEditModel}
                           title='Бронирования' booking={true}
                           editBooking={editBooking} setModalBookingVisible={setModalBookingVisible}/>
            }
        </div>
    );
};

export default AdminBooking;