import "./styles/App.css"
import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppRouter from "./components/AppRouter";
import Navbar from "./components/UI/HeaderFooter/Navbar";
import './styles/App.css'
import Footer from "./components/UI/HeaderFooter/Footer";
import {AuthContext} from "./components/context";
import {HelmetProvider} from "react-helmet-async";
import DrawerList from "./components/UI/HeaderFooter/DrawerList";
import Drawer from "@mui/material/Drawer";
import {createTheme} from "@mui/material";
import ModalImageCollection from "./components/UI/MyModal/ModalImageCollection";


function App() {

    const startOrderFilter = [
        {
            code: "type",
            value: "MY",
            type: "select",
            checked: true,
            valueForChip: "Только мои",
            values: [
                {
                    value: "MY",
                    name: "Только мои"
                },
                {
                    value: "ALL",
                    name: "Все"
                }
            ],
            label: "Тип"
        },
        {
            code: "status",
            value: "IN_PROGRES",
            type: "select",
            checked: true,
            valueForChip: "В работе",
            values: [
                {
                    value: "IN_PROGRES",
                    name: "В работе"
                },
                {
                    value: "ALL",
                    name: "Все"
                }
            ],
            label: "Статус"
        },
        {
            code: "payStatus",
            value: "ALL",
            type: "select",
            checked: true,
            valueForChip: "Все",
            values: [
                {
                    value: "ALL",
                    name: "Все"
                },
                {
                    value: "NOT_PAYED",
                    name: "Не оплаченные"
                }
            ],
            label: "Статус оплаты"
        },
        {
            code: "orderId",
            value: "",
            type: "number",
            checked: false,
            valueForChip: "",
            label: "Номер заказа"
        },
        {
            code: "date",
            value: "",
            type: "date",
            checked: false,
            valueForChip: "",
            label: "Дата съемки"
        },
        {
            code: "clientName",
            value: "",
            type: "text",
            checked: false,
            valueForChip: "",
            label: "Имя клиента"
        },
        {
            code: "modelName",
            value: "",
            type: "text",
            checked: false,
            valueForChip: "",
            label: "Имя модели(полное)"
        },
        {
            code: "sortid",
            value: "desc",
            type: "sort",
            checked: true,
            valueForChip: "По убыванию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сортировка по номеру заказа"
        },
        {
            code: "sortpayd",
            value: "desc",
            type: "sort",
            checked: false,
            valueForChip: "По убыванию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сортировка по оплачено"
        }
    ]

    const [orderFilter, setOrderFilter] = useState(startOrderFilter);

    const startModelFilter = [
        {
            code: "name",
            value: "",
            type: "text",
            checked: false,
            valueForChip: "",
            label: "псевдоним"
        },
        {
            code: "male",
            value: "false",
            type: "select",
            checked: false,
            valueForChip: "Женский",
            values: [
                {
                    value : "false",
                    name : "Женский"
                },
                {
                    value : "true",
                    name : "Мужской"
                }
            ],
            label: "Пол"
        },
        {
            code: "lingerie",
            value: false,
            type: "checkbox",
            checked: false,
            valueForChip: "не снимает белье",
            valueForChipTrue: "снимает белье",
            valueForChipFalse: "не снимает белье",
            label: "снимает белье?"
        },
        {
            code: "plusSize",
            value: false,
            type: "checkbox",
            checked: false,
            valueForChip: "не +size",
            valueForChipTrue: "+size",
            valueForChipFalse: "не +size",
            label: "+size?"
        },
        {
            code: "child",
            value: false,
            type: "checkbox",
            checked: false,
            valueForChip: "Взрослая съемка",
            valueForChipTrue: "Детская съемка",
            valueForChipFalse: "Взрослая съемка",
            label: "Детская съемка?"
        },
        {
            code: "sortheight",
            value: "desc",
            type: "sort",
            checked: false,
            valueForChip: "По убыванию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сортировка по росту"
        },
        {
            code: "sortbreastSize",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по обхвату груди"
        },
        {
            code: "sortwaistSize",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по обхвату талии"
        },
        {
            code: "sorthipSize",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по обхвату бёдер"
        },
        {
            code: "sortclothes1Hour",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по цене(одежда) час"
        },
        {
            code: "sortlingerie1Hour",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По убыванию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по цене(белье) час"
        }
    ]
    const [modelFilter, setModelFilter] = useState(startModelFilter)

    const startModelCustomFilter = [
        {
            code: "name",
            value: "",
            type: "text",
            checked: false,
            valueForChip: "",
            label: "Псевдоним"
        },
        {
            code: "male",
            value: "false",
            type: "select",
            checked: false,
            valueForChip: "Женский",
            values: [
                {
                    value: "false",
                    name: "Женский"
                },
                {
                    value: "true",
                    name: "Мужской"
                }
            ],
            label: "Пол"
        },
        {
            code: "lingerie",
            value: false,
            type: "checkbox",
            checked: false,
            valueForChip: "Не снимает белье",
            valueForChipTrue: "Снимает белье",
            valueForChipFalse: "Не снимает белье",
            label: "снимает белье?"
        },
        {
            code: "plusSize",
            value: false,
            type: "checkbox",
            checked: false,
            valueForChip: "не +size",
            valueForChipTrue: "+size",
            valueForChipFalse: "не +size",
            label: "+size?"
        },
        {
            code: "child",
            value: false,
            type: "checkbox",
            checked: false,
            valueForChip: "Взрослая съемка",
            valueForChipTrue: "Детская съемка",
            valueForChipFalse: "Взрослая съемка",
            label: "Детская съемка?"
        },
        {
            code: "sortheight",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сортировка по росту"
        },
        {
            code: "sortbreastSize",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по обхвату груди"
        },
        {
            code: "sortwaistSize",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по обхвату талии"
        },
        {
            code: "sorthipSize",
            value: "asc",
            type: "sort",
            checked: false,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сорт по обхвату бёдер"
        }
    ]
    const [modelCustomFilter, setModelCustomFilter] = useState(startModelCustomFilter)

    const startClientFilter = [
        {
            code: "id",
            value: "0",
            type: "number",
            checked: false,
            valueForChip: "0",
            label: "Номер клиента"
        },
        {
            code: "name",
            value: "",
            type: "text",
            checked: false,
            valueForChip: "",
            label: "Имя клиента"
        },
        {
            code: "sortname",
            value: "asc",
            type: "sort",
            checked: true,
            valueForChip: "По возрастанию",
            values: [
                {
                    value: "desc",
                    name: "По убыванию"
                },
                {
                    value: "asc",
                    name: "По возрастанию"
                }
            ],
            label: "Сортировка по имени"
        }
    ]
    const [clientFilter, setClientFilter] = useState(startClientFilter)


    const [isAuth, setIsAuth] = useState(false);
    const [role, setRole] = useState("");
    const [adminPanelOpen, setAdminPanelOpen] = useState(false);
    const [modalCollectionVisible, setModalCollectionVisible] = useState(false);
    const [imageCollection, setImageCollection] = useState([]);
    const [imageCollectionWithSignature, setImageCollectionWithSignature] = useState(false);
    const [ticket,setTicket] = useState()

    const theme = createTheme({
        palette: {
            primary: {
                light: '#4a4a4a',
                main: '#000000',
                dark: '#9c9b9b',
                contrastText: '#fff'
            },
            secondary:
                {
                    light: '#dcd9d9',
                    main: '#5a5a5a',
                    dark: '#323030',
                    contrastText: '#fff'
                }
        },
    });

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            setIsAuth(true)
        }
        if (localStorage.getItem('role')) {
            setRole(localStorage.getItem('role'))
        }
    }, []);

    return (
        <AuthContext.Provider value={{
            isAuth,
            setIsAuth,
            adminPanelOpen,
            setAdminPanelOpen,
            theme,
            ticket,
            setTicket,
            startOrderFilter,
            orderFilter,
            setOrderFilter,
            startModelFilter,
            modelFilter,
            setModelFilter,
            startModelCustomFilter,
            modelCustomFilter,
            setModelCustomFilter,
            startClientFilter,
            clientFilter,
            setClientFilter,
            role,
            setRole,
            setModalCollectionVisible,
            setImageCollection,
            setImageCollectionWithSignature
        }}><HelmetProvider>
            <BrowserRouter>
                <Drawer open={adminPanelOpen} onClose={e => setAdminPanelOpen(false)}>
                    <DrawerList setOpen={setAdminPanelOpen} role = {role}/>
                </Drawer>
                {
                    imageCollection && modalCollectionVisible &&
                    <ModalImageCollection
                        visible={modalCollectionVisible}
                        setVisible={setModalCollectionVisible}
                        imageCollection={imageCollection}
                        imageCollectionWithSignature={imageCollectionWithSignature}
                    />
                }
                <Navbar/>
                <AppRouter/>
                <Footer/>
            </BrowserRouter>
        </HelmetProvider>
        </AuthContext.Provider>
    );
}

export default App;
