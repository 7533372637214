import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import cl from "./ChangeBackFromDay.module.css";

const ChangeBackFromDay = (props) => {
    return (
        <div style={{marginTop: '1px'}}>
            {props.day && props.day.shootingLocation &&
                <div className={cl.back_buttons_container}>
                    <div>{props.day.shootingLocation.shootType}</div>
                    {props.day.room &&
                        <div>{props.day.room.roomName}</div>
                    }
                    {props.day.room && props.day.room.backgrounds.length > 0 &&
                    <div style={{marginTop: '5px'}}>
                        Можете выбрать подходящий фон
                    </div>
                    }
                    {props.day.room &&
                        <ToggleButtonGroup
                            color="primary"
                            value={props.backId}
                            exclusive
                            aria-label="Platform"
                        >
                            {props.day.room.backgrounds.map((back) => (
                                <div key={back.id} className={cl.back_button}>
                                    <ToggleButton
                                        onClick={() => props.setBackId(back.id)}
                                        value={back.id}
                                    >
                                        {back.backName}
                                    </ToggleButton>
                                    {back.imageCollection && props.showExample &&
                                        <button className={cl.back_button_example}
                                                onClick={(e) => {
                                                    props.setImageCollection(back.imageCollection);
                                                    props.setImageCollectionWithSignature(false)
                                                    props.setModalCollectionVisible(true)
                                                }}
                                        >
                                            Пример
                                        </button>
                                    }

                                </div>
                            ))
                            }
                        </ToggleButtonGroup>
                    }
                </div>
            }
        </div>
    );
};

export default ChangeBackFromDay;