import React from 'react';
import ModelItem from "./ModelItem";
import cl from "./ModelList.module.css"


const ModelList = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;


    if (!props.models.length) {
        return (<h2 style={{textAlign: 'center'}}>
                Модели не найдены
            </h2>
        )
    }

    return (
        <div>
            <h2 style={{textAlign: "center"}}>{props.title}</h2>
            <div className={cl.card_list}>
                {props.models.map((post, index) =>
                    <ModelItem key={post.id} remove={props.remove} addToCollection={props.addToCollection} files={props.files} setFiles={props.setFiles}
                               post={post} shoots={props.shoots} number={index + 1} setModel={props.setModel} addToModelCollection={props.addToModelCollection}
                               setModelModal={props.setModelModal} booking={props.booking} shooting={props.shooting} modelCollection={props.modelCollection}
                               editBooking={props.editBooking} setModalBookingVisible={props.setModalBookingVisible}/>
                )}
            </div>
        </div>
    );
};

export default ModelList;