import React, {useState} from 'react';
import axios from "axios";
import Button from "@mui/material/Button";
import {FormControl, Input, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import cl from "./ShootCreateForm.module.css"


const ChangePayedStatusForm = (props) => {

    return (
        <div className={cl.main_conteiner}>
            <FormControl fullWidth sx={{margin: '4px'}}>
                <div> Необходимо оплатить {props.needToPay}</div>
                <TextField
                    required
                    id="outlined-required"
                    label="Оплачено"
                    value={props.payed}
                    onChange={e => props.setPayed(e.target.value)}
                    margin="dense"
                />
                <Button
                    style={{margin: '5px'}}
                    variant="outlined"
                    onClick={(e)=>(props.changePayStatus(props.payed))}
                >
                    Сменить статус
                </Button>

            </FormControl>
        </div>
    )
        ;
};

export default ChangePayedStatusForm;