import React, {useContext, useEffect, useState} from 'react';



const Admin = () => {

    return (
        <div style={{minHeight : "90vh"}}>
        </div>
    );
};

export default Admin;