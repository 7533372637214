import React, {useContext, useState} from 'react';
import "../../../styles/App.css"
import ShootModelForm from "../../Forms/ShootModelForm";
import MyModal from "../MyModal/MyModal";
import Button from "@mui/material/Button";
import cl from "./ModelList.module.css"
import {AuthContext} from "../../context";
import {useNavigate} from "react-router-dom";

const ModelItem = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const statuses = {
        PLANNING: "white",
        REQUESTED: "lightblue",
        DISCUSSION_BOOK: "lightblue",
        BOOKED: "lightgreen",
        REQUESTED_CANCEL: "lightyellow",
        DISCUSSION_CANCEL: "lightyellow",
        CANCELED_BY_ME: "lightsalmon",
        CANCELED_BY_MODEL: "lightcoral",
    }

    const [shootModal, setShootModal] = useState(false)

    const {setOrderFilter} = useContext(AuthContext)
    const navigate = useNavigate();


    function showShootModal(model) {
        setShootModal(true)
    }


    function showModalForEdit() {
        props.setModel(props.post)
        props.setModelModal(true)
        if (props.post.imageCollection) {
            const imageCollection = props.post.imageCollection.collection.map((img) => {
                return {
                    ...img, load: false
                }
            })
            console.log(imageCollection)
            props.setFiles(imageCollection)
        } else {
            props.setFiles([])
        }

    }

    const lingerie = props.post.lingerie ? `Снимается в нижнем белье` + "\n" : `Не снимается в нижнем белье` + "\n";


    const singular = props.post.singularity ? `Особенности : ${props.post.singularity}` + "\n" : ``;

    const textToCopy = `${props.post.name}` + "\n" +
        `Рост : ${props.post.height}, Обувь: ${props.post.shoeSize}, Одежда: ${props.post.clothingSize}` + "\n" +
        `Параметры : ${props.post.breastSize}/${props.post.waistSize}/${props.post.hipSize}` + "\n" +
        lingerie +
        singular +
        `Портфолио : ${props.post.linkToPortpholio}`;


    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            // alert(`Текст скопирован: ${textToCopy}`);
            console.log(`Текст скопирован: ${textToCopy}`)
        }).catch(err => {
            console.error('Ошибка при копировании: ', err);
        });
    };

    return (
        <div className={cl.model_card}>
            <MyModal visible={shootModal} setVisible={setShootModal}>
                <ShootModelForm model={props.post} shoots={props.shoots} setShootModal={setShootModal}/>
            </MyModal>
            <div className={cl.model_content}>
                <strong>{props.number}. {props.post.name}</strong>
                <div>
                    {props.post.fio}
                </div>
                <div>
                    Рост : {props.post.height}, Обувь: {props.post.shoeSize}, Одежда: {props.post.clothingSize}
                </div>
                {props.post.singularity &&
                    <div>
                        Особенности : {props.post.singularity}
                    </div>
                }
                <div>
                    Параметры : {props.post.breastSize}/{props.post.waistSize}/{props.post.hipSize}
                </div>
                {(props.shooting || props.modelCollection) &&
                    <div>
                        <a target="_blank" rel="noopener noreferrer" href={props.post.linkToPortpholio}
                           target="_blank">Портфолио</a>
                    </div>
                }
                <div> Цена:
                    <div>
                        Одежда: от 1ч {props.post.clothes1Hour}р/от 2ч {props.post.clothes2Hour}р
                    </div>
                    <div>
                        Белье: от 1ч {props.post.lingerie1Hour}р/от 2ч {props.post.lingerie2Hour}р
                    </div>
                    <div>
                        Самосбор: {props.post.selfAssembly}р
                    </div>
                </div>
                {props.post.linkToSingularity &&
                    <div>
                        <a target="_blank" rel="noopener noreferrer" href={props.post.linkToSingularity}
                           target="_blank">Ссылка на особенности</a>
                    </div>
                }
                {props.booking &&
                    <div>
                        <div>Контакты:</div>
                        {props.post.contacts.sort((a, b) => a.id - b.id).map((cont) => (
                            <div>
                                {cont.contactType === "PHONE" &&
                                    <a href={"tel:" + cont.value}>Позвонить {cont.value}</a>
                                }
                                {cont.contactType === "BOOKER_PHONE" &&
                                    <a href={"tel:" + cont.value}>Позвонить букеру {cont.value}</a>
                                }
                                {cont.contactType === "TELEGRAM" &&
                                    <a href={"tg://resolve?domain=" + (cont.value.startsWith("@") ? cont.value.substring(1) : cont.value)}>Телеграмм {cont.value}</a>
                                }
                                {cont.contactType === "BOOKER_TELEGRAM" &&
                                    <a href={"tg://resolve?domain=" + cont.value}>Телеграмм букера{cont.value}</a>
                                }
                                {cont.contactType === "WHATSAPP" &&
                                    <a href={"whatsapp://send?phone=" + cont.value}>WhatsApp {cont.value}</a>
                                }
                                {cont.contactType === "BOOKER_WHATSAPP" &&
                                    <a href={"whatsapp://send?phone=" + cont.value}>WhatsApp букера{cont.value}</a>
                                }
                            </div>
                        ))}
                    </div>
                }
                {props.booking && props.editBooking && props.post.id === props.editBooking.modelPerson.id &&
                    <div>
                        <div>Коомент для бронирования:</div>
                        <div style={{whiteSpace: "pre-wrap"}}>{props.editBooking.comment}</div>
                    </div>
                }
            </div>
            <div className={cl.image_conteiner}>
                <img className={cl.image}
                     src={`/images/small/${props.post.photo}`}
                     alt={props.post.name}/>
            </div>
            <div className={cl.model_btns}>
                {props.shooting &&
                    <Button size="small" variant="outlined" onClick={() => handleCopy()}>Копир</Button>
                }
                {(props.shooting || props.booking) &&
                    <Button size="small" variant="outlined" onClick={() => showModalForEdit(props.post)}>Ред</Button>
                }
                {props.shooting &&
                    <Button size="small" variant="outlined" onClick={() => showShootModal(props.post)}>Записать</Button>
                }
                {props.shooting &&
                    <Button size="small" variant="outlined" onClick={() => {
                        setOrderFilter((oldOrderFilter) => oldOrderFilter.map(el => el.code !== "modelName"
                            ? {...el, checked: false}
                            : {...el, checked: true, value: props.post.fio, valueForChip: props.post.fio}
                        ).map(el => el.code !== "sortid"
                            ? {...el}
                            : {...el, checked: true, value: "desc", valueForChip: "По убыванию"}))
                        navigate("/admin/orders")
                    }}>
                        Заказы</Button>
                }
                {props.booking && props.editBooking && props.post.id === props.editBooking.modelPerson.id &&
                    <Button size="small" variant="outlined"
                            sx={{backgroundColor: (props.editBooking && statuses[props.editBooking.bookingStatus]) ? statuses[props.editBooking.bookingStatus] : "white"}}
                            onClick={() => props.setModalBookingVisible(true)}>Сменить статус</Button>
                }
                {props.modelCollection &&
                    <Button size="small" variant="outlined" onClick={() => props.addToModelCollection(props.post)}>
                        Добавить в подборку
                    </Button>
                }
            </div>
        </div>
    );
};

export default ModelItem;