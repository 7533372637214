import React, {useContext, useState} from 'react';
import cl from "./TarifDiv.module.css";
import NumberInput from "../UI/input/NumberInput";
import ResultForm from "./ResultForm";
import MyModal from "../UI/MyModal/MyModal";
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import MyModal90 from "../UI/MyModal/MyModal90";
import Button from '@mui/material/Button';
import {createTheme, ThemeProvider} from "@mui/material";
import {AuthContext} from "../context";

const TarifDivForOrder = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalImageVisible, setModalImageVisible] = useState(false);
    const [pathToImage, setPathToImage] = useState('');

    const {theme} = useContext(AuthContext)

    function getNameAndPhoto() {
        if (props.minPhoto > 0 && props.maxPhoto > 0) {
            if (props.minPhoto === props.maxPhoto) {
                return props.name + '  ' + props.minPhoto + ' фото';
            } else {
                return props.name + '  ' + props.minPhoto + '-' + props.maxPhoto + ' фото';
            }
        } else {
            return props.name;
        }
    }

    function getPhoto() {
        if (props.minPhoto > 0 && props.maxPhoto > 0) {
            if (props.minPhoto === props.maxPhoto) {
                return props.minPhoto + ' фото';
            } else {
                return props.minPhoto + '-' + props.maxPhoto + ' фото';
            }
        } else {
            return ''
        }
    }



    function setPathToIm(path) {
        setPathToImage(path)
        setModalImageVisible(true)
    }

    function setModalByCollection(e) {
        if (props.imageCollection) {
            setModalVisible(true)
        }
    }

    return (
        <div className={cl.tarif_block_conteiner}>
            <ThemeProvider theme={theme}>
            {
                modalVisible &&
                <MyModal90 visible={modalVisible} setVisible={setModalVisible}>
                    <div className={cl.image_conteiner}>
                        {props.imageCollection.collection.map((img) => (
                            <img className={cl.example_image}
                                 onClick={event => setPathToIm(img.path)}
                                 src={`/images/medium/${img.path}`}
                                 alt='Пример фото'
                            />
                        ))}
                    </div>
                </MyModal90>
            }
            {
                modalImageVisible &&
                <MyModal style={{zIndex: '5'}} visible={modalImageVisible} setVisible={setModalImageVisible}>
                    <button onClick={event => setModalImageVisible(false)} className={cl.close__button}>X</button>
                    <TransformWrapper wheel={{step: '1', smoothStep: '0.005'}}>
                        <TransformComponent>
                            <img src={`/images/${pathToImage}`} alt="Enlarged"
                                 className={cl.fullscreen__image}/>
                        </TransformComponent>
                    </TransformWrapper>
                </MyModal>
            }
                <div className={cl.tarif_desc_conteiner}>
                    <div
                        className={cl.tarif_block_name}
                    >
                        {props.name}
                    </div>
                    <div
                        // className={cl.tarif_block_name}
                    >
                        {getPhoto()}
                    </div>
                    {/*<hr/>*/}
                    <div className={cl.tarif_desc_style}>
                        {props.stylish}
                    </div>
                    {/*<hr/>*/}
                    <div className={cl.tarif_desc_posing}>
                        {props.posing}
                    </div>
                </div>
                <div className={cl.tarif_block_cost}>
                    {props.baseCost} р. за артикул
                </div>
                {
                props.imageCollection &&
                    <Button
                        color = "primary"
                        // className={cl.info__button}
                        // style = {{position: 'absolute',  left: '-15px',top: '-18px'}}
                        variant="contained"
                        onClick={event => setModalVisible(true)}>
                        Примеры результата
                    </Button>

                }
            </ThemeProvider>
        </div>
    );
};

export default TarifDivForOrder;