import React, {useState} from 'react';
import axios from "axios";
import classes from "./ModelForm.module.css";
import {Checkbox, FormControlLabel, FormGroup, MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import FileUpload from "../UI/Loader/FileUpload";
import MyModal from "../UI/MyModal/MyModal";
import MyModal90 from "../UI/MyModal/MyModal90";
// import FileUpload from "../UI/Loader/FileUpload";


const ModelCard = (props) => {

    return (
        <MyModal90 visible={props.visible} setVisible={props.setVisible}>
                <div className="horizont_left_conteiner" style={{overflowY: "auto"}}>
                    <div className="vertical_left_conteiner">
                        <strong>Псевдоним {props.model.name}</strong>
                        <div>Полное имя {props.model.fio}</div>
                        <div>
                            <a target="_blank" rel="noopener noreferrer" href={props.model.linkToPortpholio}
                               target="_blank">Портфолио</a>
                        </div>
                        {props.model.imageCollection &&
                            <div className="horizont_left_conteiner">
                                {props.model.imageCollection.collection.map((img)=> (
                                    <img className="example_img"
                                         src={`/images/small/${img.path}`}
                                         alt={img.alt}/>
                                ))
                                }
                            </div>
                        }
                        {props.model.linkToSingularity &&
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href={props.model.linkToSingularity}
                                   target="_blank">Ссылка на особенности</a>
                            </div>
                        }
                        <div>Коментарий менеджера {props.model.managerComment}</div>
                        {props.model.lingerie ?
                            <div>Снимается в нижнем белье</div>
                            : <div>Не снимается в нижнем белье</div>
                        }
                        {props.model.male ?
                            <div>Мужчина</div>
                            : <div>Женщина</div>
                        }
                        {props.model.plusSize &&
                            <div>+SIZE</div>
                        }
                        {props.model.child &&
                            <div>Детская съемка</div>
                        }
                        {props.model.good &&
                            <div>предметка</div>
                        }
                    </div>
                    <div className={classes.labelColumn}>
                        <div>Параметры {props.model.breastSize}/{props.model.waistSize}/{props.model.hipSize}</div>
                        <div>Особенности {props.model.singularity}</div>
                        <div>Размер обуви {props.model.shoeSize}</div>
                        <div>Размер одежды {props.model.clothingSize}</div>
                        <div>Размер чашки {props.model.cupSize}</div>
                        {/*<div>Обхват груди {props.model.breastSize}</div>*/}
                        {/*<div>Обхват талии {props.model.waistSize}</div>*/}
                        {/*<div>Обхват бедер {props.model.hipSize}</div>*/}
                        <div>Рост {props.model.height}</div>
                    </div>
                    <div className={classes.labelColumn}>
                        <div>Самосборы {props.model.selfAssembly}</div>
                        <div>Стоимость съемки одежды 1 час {props.model.clothes1Hour}</div>
                        <div>Стоимость съемки одежды от 2ух часов {props.model.clothes2Hour}</div>
                        <div>Стоимость съемки нижнего белья 1 час {props.model.lingerie1Hour}</div>
                        <div>Стоимость съемки нижнего белья от 2ух часов {props.model.lingerie2Hour}</div>
                        <div>Стоимость съемки улица 1 час {props.model.street1Hour}</div>
                    </div>
                </div>
                <div>
                    <div>Контакты</div>
                    {props.model.contacts && props.model.contacts.sort((a, b) => a.id - b.id).map((cont) => (
                        <div>
                            {cont.contactType === "PHONE" &&
                                <a href={"tel:" + cont.value}>Позвонить {cont.value}</a>
                            }
                            {cont.contactType === "BOOKER_PHONE" &&
                                <a href={"tel:" + cont.value}>Позвонить букеру {cont.value}</a>
                            }
                            {cont.contactType === "TELEGRAM" &&
                                <a href={"tg://resolve?domain=" + (cont.value.startsWith("@") ? cont.value.substring(1) : cont.value)}>Телеграмм {cont.value}</a>
                            }
                            {cont.contactType === "BOOKER_TELEGRAM" &&
                                <a href={"tg://resolve?domain=" + cont.value}>Телеграмм букера{cont.value}</a>
                            }
                            {cont.contactType === "WHATSAPP" &&
                                <a href={"whatsapp://send?phone=" + cont.value}>WhatsApp {cont.value}</a>
                            }
                            {cont.contactType === "BOOKER_WHATSAPP" &&
                                <a href={"whatsapp://send?phone=" + cont.value}>WhatsApp букера{cont.value}</a>
                            }
                        </div>
                    ))
                    }
                    <Button onClick={(e) => props.setVisible(false)}>Закрыть</Button>
                </div>
        </MyModal90>
    );
};

export default ModelCard;