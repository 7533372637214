import React, {useEffect, useState} from 'react';
import axios from "axios";
import classes from "./ModelForm.module.css";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import FileUpload from "../UI/Loader/FileUpload";
import {useFetching} from "../hooks/useFetching";
import ShootService from "../API/ShootService";
import Loader from "../UI/Loader/Loader";
import ShootList from "../UI/list/ShootList";
import ChangeBackFromDay from "../UI/block/ChangeBackFromDay";
import TarifService from "../API/TarifService";
import TarifsBlock from "../UI/block/TarifsBlock";
import ChangeShootAndModelModal from "../UI/MyModal/ChangeShootAndModelModal";
// import FileUpload from "../UI/Loader/FileUpload";


const OrderRowForm = (props) => {

    const [shoot, setShoot] = useState({})
    const [allTarifs, setAllTarifs] = useState([]);


    const [fetchTarifs, isTarifsLoading, tarifsError] = useFetching(async () => {
        setAllTarifs([])
        if (props.editOrderRow.modelPersonId) {
            const photoTarifs = await TarifService.getAllTarifsByModel(props.editOrderRow.modelPersonId, props.editOrderRow.shootId);
            console.log(photoTarifs)
            setAllTarifs(photoTarifs)
        }
    })

    useEffect(() => {
        if (props.editOrderRow.shootId !== 0) {
            setShoot(props.shoots.find((e) => (e.id === props.editOrderRow.shootId)))
        }
    }, []);

    useEffect(() => {
        console.log("fetch tarifs")
        fetchTarifs()
    }, [props.editOrderRow.modelPersonId, props.editOrderRow.shootId]);

    function onClickImg(booking, shoot) {
        props.setEditOrderRow({
            ...props.editOrderRow,
            fullName: booking.modelPerson.fio,
            modelPersonId: booking.modelPerson.id,
            photo: booking.modelPerson.photo,
            shootId: shoot.id,
            date: shoot.date,
            backgroundId: 0
        })
        setShoot(shoot);
    }

    function setBackId(id) {
        props.setEditOrderRow({...props.editOrderRow, backgroundId: id})
    }


    function setTar(tar) {
        props.setEditOrderRow({
            ...props.editOrderRow,
            tarifId: tar.id,
            baseCost: tar.baseCost,
            cost: props.editOrderRow.quantity * tar.baseCost
        })
    }

    return (
        <div className={classes.main_modal}>
            <form>
                <div className={classes.mainForm}>
                    <div className={classes.labelColumn}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={props.showShoots}
                                onChange={e => props.setShowShoots(e.target.checked)}
                                inputProps={{'aria-label': 'controlled'}}
                                label='Блок изменения даты'
                            />
                        } label="Блок изменения даты"/>
                        {props.showShoots &&
                            <div>
                                <ShootList shoots={props.shoots}
                                           showDelButton={false}
                                           title='Ближайшие съемки'
                                           onClickImg={onClickImg}
                                />
                            </div>
                        }
                        {props.showOrderId &&
                            <div>
                                <TextField
                                    required
                                    id="outlined-required6"
                                    label="№ заказа"
                                    value={props.editOrderRow.orderId}
                                    onChange={e =>
                                        props.setEditOrderRow({
                                            ...props.editOrderRow,
                                            orderId: e.target.value,
                                        })
                                    }
                                    margin="dense"
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                        }
                        <div className="vertical_left_conteiner">
                            <TextField
                                required
                                disabled
                                id="outlined-required6"
                                value={props.editOrderRow.fullName}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                disabled
                                id="outlined-required5"
                                value={props.editOrderRow.date}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required4"
                                label="Количество артикулов"
                                value={props.editOrderRow.quantity}
                                onChange={e =>
                                    props.setEditOrderRow({
                                        ...props.editOrderRow,
                                        quantity: e.target.value,
                                        cost: e.target.value * props.editOrderRow.baseCost
                                    })
                                }
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                disabled
                                id="outlined-required3"
                                label="Стоимость за артикул"
                                value={props.editOrderRow.baseCost}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required2"
                                label="Финальная цена"
                                value={props.editOrderRow.cost}
                                onChange={e => props.setEditOrderRow({...props.editOrderRow, cost: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <TextField
                            required
                            id="outlined-required1"
                            label="Товар"
                            multiline
                            value={props.editOrderRow.good}
                            onChange={e => props.setEditOrderRow({...props.editOrderRow, good: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            id="outlined-required1"
                            label="Детали"
                            multiline
                            value={props.editOrderRow.details}
                            onChange={e => props.setEditOrderRow({...props.editOrderRow, details: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            id="outlined-required1"
                            label="Образ"
                            multiline
                            value={props.editOrderRow.fasion}
                            onChange={e => props.setEditOrderRow({...props.editOrderRow, fasion: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            id="outlined-required1"
                            label="Важно"
                            multiline
                            value={props.editOrderRow.important}
                            onChange={e => props.setEditOrderRow({...props.editOrderRow, important: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <FileUpload files={props.files} setFiles={props.setFiles}/>

                        <Button variant="outlined" onClick={props.addNewOrderRow}>Добавить/Редактировать услугу</Button>
                        <ChangeBackFromDay
                            day={shoot}
                            backId={props.editOrderRow.backgroundId}
                            setBackId={setBackId}
                            showExample={false}
                        />
                        <hr style={{margin: '10px'}}/>
                        <TarifsBlock
                            tarifs={allTarifs}
                            tarif={props.editOrderRow.tarifId}
                            setTarif={setTar}
                        />
                    </div>
                </div>
            </form>

        </div>
    );
};

export default OrderRowForm;