import React, {useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import ModelService from "../API/ModelService";
import Loader from "../UI/Loader/Loader";
import ModelListMain from "../UI/list/ModelListMain";
import {Helmet} from "react-helmet-async";

const TimeTable = () => {

    const [models, setModels] = useState([])


    const [fetchModels, isModelLoading, modelError] = useFetching(async () => {
        const models = await ModelService.getAllActual();
        console.log(models)
        setModels(models)
    })

    useEffect(() => {
        console.log("fetch models")
        fetchModels()
    }, [])

    return (
        <div>
            <Helmet>
                <title>IXOR Production - Съёмки для маркетплейсов - расписание сборных съемок</title>
                <meta name="description" content="Ближайшие съемки - запишитесь на сборную съемку"/>
            </Helmet>
            {modelError &&
                <h3> Произошла ошибка ${modelError}</h3>}
            {isModelLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                :
                <ModelListMain models={models} title='Ближайшие съемки'/>
            }
        </div>
    );
};

export default TimeTable;