import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useFetching} from "../../hooks/useFetching";
import RequestService from "../../API/RequestService";
import cl from "./OrderAccordion.module.css";
import Button from "@mui/material/Button";
import axios from "axios";
import OrderRowForm from "../../Forms/OrderRowForm";
import MyModal90 from "../MyModal/MyModal90";
import ImageService from "../../API/ImageService";
import ChangeShootAndModelModal from "../MyModal/ChangeShootAndModelModal";

const OrderAccordion = (props) => {

    const startEditRow = {
        orderId: props.orderId,
        baseCost: 0,
        cost: 0,
        quantity: 1,
        backgroundId: null,
        backName: null,
        good: "",
        details: "",
        fasion: "",
        important: "",
        modelPersonId: null,
        fullName: "Не определено",
        photo: null,
        shootId: 0,
        date: '',
        tarifId: 0,
        tarifName: "",
        goodTypeEnum: null,
        goodTypeName: "",
    }
    const [editOrderRow, setEditOrderRow] = new useState(startEditRow)
    const [files, setFiles] = useState([]);
    const [orderRowModal, setOrderRowModal] = useState(false)
    const [showShoots, setShowShoots] = useState(false)
    const [showOrderId, setShowOrderId] = useState(false)
    const [visible, setVisible] = useState(false)
    const [orderRows, setOrderRows] = useState([])

    const [fetchOrderRows, isOrderRowsLoading, orderRowsError] = useFetching(async () => {
        const orderRows = await RequestService.getOrderRows(props.orderId);
        console.log(orderRows)
        setOrderRows(orderRows)
    })

    async function fetchFiles(imageCollectionId) {
        if (imageCollectionId !== null) {
            const images = await ImageService.getAllImagesByImageCollection(imageCollectionId);
            console.log(images)
            setFiles(images.collection)
        } else {
            setFiles([])
        }
    }

    function clearEditOrderRow(modelPersonId, fullName, shootId, date) {
        if (modelPersonId !== null) {
            setEditOrderRow({...startEditRow, modelPersonId, fullName, shootId, date})
        } else {
            setEditOrderRow(startEditRow)
        }
    }

    const apiUrl = process.env.REACT_APP_API_URL;

    function handleChange(event, isExpanded) {
        if (isExpanded) {
            console.log("Expanded")
            fetchOrderRows()
        }
    }

    function setShootAndModel(model, shoot) {
        axios.post(
            apiUrl + 'order_rows/change_shoot_and_model',
            {
                modelId: model.id,
                shootId: shoot.id,
                orderId: props.orderId,
                oldModelId: editOrderRow.modelPersonId,
                oldShootId: editOrderRow.shootId
            }
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setVisible(false)
                fetchOrderRows()
            })
            .catch(err => {
                console.log(err);
            })
    }

    function formatWithHiperlink(inputString) {
        if (inputString == null) return <label></label>
        let rx = /(https:\/\/|http:\/\/|www\.)\S+/g
        let arr = inputString.match(rx) || [];
        if (arr.length == 0) return <label>{inputString}</label>
        let arrPosition = inputString.search(rx)
        let rx2 = arr[0]
        let arr2 = inputString.split(rx2)
        return <label>
            {arrPosition !== 0 && <label>{arr2[0]}</label>}
            <a target="_blank" rel="noopener noreferrer" href={rx2}>Ссылка</a>
            {arr2.length > 1 && <label>{formatWithHiperlink(arr2[1])}</label>}
        </label>;
    }

    const addNewOrderRow = (e) => {
        if (editOrderRow.orderId!=='') {
            axios.post(
                apiUrl + 'order_rows/add',
                {
                    ...editOrderRow, date: null, imageCollection: {
                        ...editOrderRow.imageCollection,
                        collection: [...files],
                        id: editOrderRow.imageCollectionId
                    }
                }
                ,
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            )
                .then(res => {
                    console.log(res.data);
                    setOrderRowModal(false)
                    if (editOrderRow.orderId === props.orderId) {
                    clearEditOrderRow()
                    setFiles([])
                    props.setOrder((order) => ({
                        ...order,
                        needToPay: res.data.needToPay,
                        payStatusName: res.data.payStatusName,
                        payStatusEnum: res.data.payStatusEnum,
                        payd: res.data.payd,
                        datePayed: res.data.datePayed
                    }));
                    fetchOrderRows()
                    } else {
                        props.fetchOrders()
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    function deleteOrderRow(id) {
        axios.post(apiUrl + 'orders/rows/delete', {
            id: id, orderId: props.orderId
        }, {
            headers: {'Content-Type': 'application/json'}, withCredentials: true
        })
            .then(res => {
                props.setOrder((order) => ({
                    ...order,
                    needToPay: res.data.needToPay,
                    payStatusName: res.data.payStatusName,
                    payStatusEnum: res.data.payStatusEnum,
                    payd: res.data.payd,
                    datePayed: res.data.datePayed
                }));
                fetchOrderRows()
                console.log(orderRows)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div style={{width: '100%'}}>
            <MyModal90 visible={orderRowModal} setVisible={setOrderRowModal}>
                <OrderRowForm addNewOrderRow={addNewOrderRow}
                              editOrderRow={editOrderRow}
                              setEditOrderRow={setEditOrderRow}
                              showOrderId={showOrderId}
                              showShoots={showShoots}
                              setShowShoots={setShowShoots}
                              files={files}
                              setFiles={setFiles}
                              shoots={props.shoots}
                />
            </MyModal90>
            <ChangeShootAndModelModal
                visible={visible}
                setVisible={setVisible}
                setShootAndModel={setShootAndModel}
                shoots={props.shoots}
            />
            <Accordion onChange={handleChange} style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1-content"
                    id="panel1-header">
                    Состав заказа
                </AccordionSummary>
                <AccordionDetails style={{width: '100%', padding: "1px"}}>
                    <div className="vertical_left_conteiner">
                        {orderRows.map((groupedRows, index) => (
                            <div key={index} className="horizont_grid">
                                <div key={index} className="vertical_left_conteiner_order">
                                    <div>{groupedRows.fullName} {groupedRows.date}</div>
                                    <div><img className={cl.shoot__list_image}
                                              src={`/images/small/${groupedRows.photo}`}
                                              alt={groupedRows.fullName}
                                    /></div>
                                </div>
                                {groupedRows.list.map((row, index) => (
                                    <div key={index} className="vertical_left_conteiner_order" style={{width: "100%"}}>
                                        <div><strong>Фон:</strong>{row.backName}</div>
                                        <div><strong>Услуга:</strong>{row.tarifName}</div>
                                        <div><strong>Тип:</strong>{row.goodTypeName}</div>
                                        <div><strong>Цена:</strong>{row.baseCost}</div>
                                        <div><strong>Кол-во:</strong>{row.quantity}</div>
                                        <div><strong>Сумма:</strong>{row.cost}</div>
                                        <div className="vertical_left_conteiner_bordered">
                                            <strong>Товар:</strong>{row.good}</div>
                                        <div
                                            className="vertical_left_conteiner_bordered">
                                            <strong>Детали:</strong>{formatWithHiperlink(row.details)}</div>
                                        <div
                                            className="vertical_left_conteiner_bordered">
                                            <strong>Образ:</strong>{formatWithHiperlink(row.fasion)}</div>
                                        <div
                                            className="vertical_left_conteiner_bordered">
                                            <strong>Важно:</strong>{formatWithHiperlink(row.important)}</div>
                                        <div><strong>Рефы:</strong>{row.imageCollectionId ? 'Есть' : 'Нет'}</div>
                                        <div className="horizont_left_conteiner">
                                            <Button size='small'
                                                    onClick={(e) => {
                                                        fetchFiles(row.imageCollectionId)
                                                        setShowShoots(false)
                                                        setShowOrderId(false)
                                                        setEditOrderRow(row)
                                                        setOrderRowModal(true)
                                                    }}>Ред</Button>
                                            <Button
                                                onClick={(e) => deleteOrderRow(row.id)}
                                                size='small'>Удалить</Button>
                                            <Button size='small'
                                                    onClick={(e) => {
                                                        fetchFiles(row.imageCollectionId)
                                                        setShowShoots(true)
                                                        setShowOrderId(true)
                                                        setEditOrderRow({...row, orderId: ''})
                                                        setOrderRowModal(true)
                                                    }}>Скопировать</Button>
                                        </div>
                                    </div>))
                                }
                                <div className="vertical_conteiner">
                                    <Button size="small" onClick={(e) => {
                                        clearEditOrderRow(groupedRows.modelPersonId, groupedRows.fullName, groupedRows.shootId, groupedRows.date)
                                        setFiles([])
                                        setShowOrderId(false)
                                        setShowShoots(false)
                                        setOrderRowModal(true)
                                    }}>Добавить услугу</Button>
                                    <Button size="small" onClick={(e) => {
                                        clearEditOrderRow(groupedRows.modelPersonId, groupedRows.fullName, groupedRows.shootId, groupedRows.date)
                                        setVisible(true)
                                    }}>Перенести всё</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <Button size="small" onClick={(e) => {
                            setShowShoots(true)
                            setShowOrderId(false)
                            clearEditOrderRow()
                            setFiles([])
                            setOrderRowModal(true)
                        }}>Добавить модель/съемку</Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default OrderAccordion;