import React, {useState} from 'react';
import axios from "axios";
import classes from "./ModelForm.module.css";
import {Checkbox, FormControlLabel, FormGroup, MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import FileUpload from "../UI/Loader/FileUpload";
import MyModal from "../UI/MyModal/MyModal";
// import FileUpload from "../UI/Loader/FileUpload";


const ModelForm = (props) => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const contactTypes = [
        {code: "PHONE", name: "Телефон"},
        {code: "TELEGRAM", name: "Телеграм"},
        {code: "WHATSAPP", name: "WhatsApp"},
        {code: "VIBER", name: "Viber"},
        {code: "BOOKER_PHONE", name: "Букер Телефон"},
        {code: "BOOKER_TELEGRAM", name: "Букер Телеграм"},
        {code: "BOOKER_WHATSAPP", name: "Букер WhatsApp"},
        {code: "BOOKER_VIBER", name: "Букер Viber"}]

    const addNewModel = (e) => {
        e.preventDefault();
        const modelPerson = {
            ...props.model, imageCollection: {
                ...props.model.imageCollection,
                collection: [...props.files]
            }
        }

        axios.post(
            apiUrl + 'models/add',
            modelPerson
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                if (modelPerson.id !== null) {
                    props.create(res.data)
                }
                props.clearModel();
            })
            .catch(err => {
                console.log(err);
            })

        props.setFiles([])
    }

    function addContact() {
        const newContact = {
            id: new Date().valueOf(),
            contactType: "PHONE",
            value: "",
            comment: ""
        }
        props.setModel({...props.model, contacts: [...props.model.contacts, newContact]});
    }

    return (
        <MyModal visible={props.visible} setVisible={props.setVisible}>
            <div className={classes.main_modal}>
                <form>
                    <FileUpload setModel={props.setModel} files={props.files} setFiles={props.setFiles}/>
                    <div className={classes.mainForm}>
                        <div className={classes.labelColumn}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Псевдоним"
                                value={props.model.name}
                                onChange={e => props.setModel({...props.model, name: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Полное имя"
                                value={props.model.fio}
                                onChange={e => props.setModel({...props.model, fio: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Ссылка на портфолио"
                                value={props.model.linkToPortpholio}
                                onChange={e => props.setModel({...props.model, linkToPortpholio: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Ссылка на особенности"
                                value={props.model.linkToSingularity}
                                onChange={e => props.setModel({...props.model, linkToSingularity: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Комментарий менеджера"
                                value={props.model.managerComment}
                                multiline
                                rows={4}
                                onChange={e => props.setModel((model) => ({...model, managerComment: e.target.value}))}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.lingerie}
                                        onChange={e => props.setModel({...props.model, lingerie: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='Снимается в нижнем белье'
                                    />
                                } label="Снимается в нижнем белье"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.male}
                                        onChange={e => props.setModel({...props.model, male: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='Мужчина?'
                                    />
                                } label="Мужчина?"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.plusSize}
                                        onChange={e => props.setModel({...props.model, plusSize: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='+ size'
                                    />
                                } label="+ size"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.child}
                                        onChange={e => props.setModel({...props.model, child: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='+ size'
                                    />
                                } label="Ребенок?"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.visible}
                                        onChange={e => props.setModel({...props.model, visible: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='+ size'
                                    />
                                } label="Видимость?"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.good}
                                        onChange={e => props.setModel({...props.model, good: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='+ предметка'
                                    />
                                } label="предметка?"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={props.model.deleted}
                                        onChange={e => props.setModel({...props.model, deleted: e.target.checked})}
                                        inputProps={{'aria-label': 'controlled'}}
                                        label='+ size'
                                    />
                                } label="Удалить?"/>
                            </FormGroup>
                            <Button variant="outlined" onClick={addNewModel}>Добавить/сохранить</Button>

                        </div>
                        <div className={classes.labelColumn}>
                            <TextField
                                id="outlined-required"
                                label="Особенности"
                                value={props.model.singularity}
                                onChange={e => props.setModel({...props.model, singularity: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Размер обуви"
                                value={props.model.shoeSize}
                                onChange={e => props.setModel({...props.model, shoeSize: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Размер одежды"
                                value={props.model.clothingSize}
                                onChange={e => props.setModel({...props.model, clothingSize: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Размер чашки"
                                value={props.model.cupSize}
                                onChange={e => props.setModel({...props.model, cupSize: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                type="number"
                                id="outlined-required"
                                label="Обхват груди"
                                value={props.model.breastSize}
                                onChange={e => props.setModel({...props.model, breastSize: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                type="number"
                                id="outlined-required"
                                label="Обхват талии"
                                value={props.model.waistSize}
                                onChange={e => props.setModel({...props.model, waistSize: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                type="number"
                                id="outlined-required"
                                label="Обхват бедер"
                                value={props.model.hipSize}
                                onChange={e => props.setModel({...props.model, hipSize: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                type="number"
                                label="Рост"
                                value={props.model.height}
                                onChange={e => props.setModel({...props.model, height: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div className={classes.labelColumn}>
                            <TextField
                                required
                                id="outlined-required"
                                type="number"
                                label="Самосборы"
                                value={props.model.selfAssembly}
                                onChange={e => props.setModel({...props.model, selfAssembly: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-required"
                                type="number"
                                label="Доп чек"
                                value={props.model.addCheck}
                                onChange={e => props.setModel({...props.model, addCheck: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-required"
                                type="number"
                                label="Доп чек нижнее белье"
                                value={props.model.addCheckLingerie}
                                onChange={e => props.setModel({...props.model, addCheckLingerie: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                type="number"
                                label="Стоимость съемки одежды 1 час"
                                value={props.model.clothes1Hour}
                                onChange={e => props.setModel({...props.model, clothes1Hour: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-required"
                                type="number"
                                label="Стоимость съемки одежды от 2ух часов"
                                value={props.model.clothes2Hour}
                                onChange={e => props.setModel({...props.model, clothes2Hour: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-required"
                                type="number"
                                label="Стоимость съемки нижнего белья 1 час"
                                value={props.model.lingerie1Hour}
                                onChange={e => props.setModel({...props.model, lingerie1Hour: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-required"
                                type="number"
                                label="Стоимость съемки нижнего белья от 2ух часов"
                                value={props.model.lingerie2Hour}
                                onChange={e => props.setModel({...props.model, lingerie2Hour: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                id="outlined-required"
                                type="number"
                                label="Стоимость съемки улица 1 час"
                                value={props.model.street1Hour}
                                onChange={e => props.setModel({...props.model, street1Hour: e.target.value})}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                    </div>
                    <div>
                        <div>Контакты</div>
                        {props.model.contacts.sort((a, b) => a.id - b.id).map((contact) => (
                            <div key={contact.id} style={{
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                display: "flex"
                            }}>
                                <div><Select
                                    variant='standard'
                                    id="demo-simple-select"
                                    value={contact.contactType}
                                    onChange={(e) => {
                                        props.setModel({
                                            ...props.model,
                                            contacts: [...props.model.contacts.map(item => item.id === contact.id ? {
                                                ...item,
                                                contactType: e.target.value
                                            } : item)]
                                        })
                                    }}
                                >
                                    {contactTypes.map((type) => (
                                        <MenuItem value={type.code}
                                                  key={type.code}>{type.name}</MenuItem>
                                    ))
                                    }
                                </Select>
                                </div>
                                <TextField
                                    id="outlined-required"
                                    label="Контакт"
                                    value={contact.value}
                                    onChange={e => props.setModel({
                                            ...props.model,
                                            contacts: [...props.model.contacts.map(item => item.id === contact.id ? {
                                                ...item,
                                                value: e.target.value
                                            } : item)]
                                        }
                                    )}
                                    margin="dense"
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    id="outlined-required"
                                    label="Коментарий"
                                    value={contact.comment}
                                    onChange={e => props.setModel({
                                            ...props.model,
                                            contacts: [...props.model.contacts.map(item => item.id === contact.id ? {
                                                ...item,
                                                comment: e.target.value
                                            } : item)]
                                        }
                                    )}
                                    margin="dense"
                                    InputLabelProps={{shrink: true}}
                                />
                                <Button onClick={(e) => props.setModel({
                                        ...props.model,
                                        contacts: [...props.model.contacts.filter(item => item.id !== contact.id)]
                                    }
                                )}>Удалить контакт</Button>
                            </div>
                        ))
                        }
                        <Button onClick={addContact}>Добавить контакт</Button>
                    </div>
                </form>

            </div>
        </MyModal>
    );
};

export default ModelForm;