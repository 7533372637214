import React, {useEffect, useState} from 'react';
import cl from "./Tarifs.module.css"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TarifDivForOrder from "../Forms/TarifDivForOrder";
import {useFetching} from "../hooks/useFetching";
import TarifService from "../API/TarifService";
import MyModal90 from "../UI/MyModal/MyModal90";
import MyModal from "../UI/MyModal/MyModal";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import Button from "@mui/material/Button";
import TarifDiv from "../Forms/TarifDiv";
import {Helmet} from "react-helmet-async";

const Tarifs = () => {

    const [photoTypes, setPhotoTypes] = useState([]);
    const [videoTypeTarifs, setVideoTypeTarifs] = useState([]);
    const [allPhotoTypeTarifs, setAllPhotoTypeTarifs] = useState([]);
    const [dopTarifs, setDopTarifs] = useState([]);
    const [postProdTarifs, setPostProdTarifs] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalImageVisible, setModalImageVisible] = useState(false);

    const [pathToImage, setPathToImage] = useState('');


    function setPathToIm(path) {
        setPathToImage(path)
        setModalImageVisible(true)
    }


    const [fetchTarifs, isTarifsLoading, tarifsError] = useFetching(async () => {
        const photoTypes = await TarifService.getAllGoodsTypes();
        const videoTarifs = await TarifService.getAllVideoTarifs();
        const photoTarifs = await TarifService.getAllTarifs();
        const dopTarifs = await TarifService.getAllDopTarifs();
        const postTarifs = await TarifService.getAllPostProdTarifs();
        console.log(photoTypes)
        console.log(photoTarifs)
        console.log(videoTarifs)
        console.log(dopTarifs)

        setPhotoTypes(photoTypes)
        setVideoTypeTarifs(videoTarifs)
        setAllPhotoTypeTarifs(photoTarifs)
        setDopTarifs(dopTarifs)
        setPostProdTarifs(postTarifs)
    })

    useEffect(() => {
        console.log("fetch tarifs")
        fetchTarifs()
    }, [])

    return (
        <div className={cl.main}>
            <Helmet>
                <title>IXOR Production - Съёмки для маркетплейсов - портфолио, тарифы</title>
                <meta name="description" content="Тарифы, портфолио, индивидуальные и сборные съемки"/>

            </Helmet>
            <div>Тарифы и примеры работ</div>
            <div className={cl.sbornie}>
                <div>Сборные съемки для маркетплейсов</div>
                <div className={cl.sbornie_types}>
                    {photoTypes.length !== 0 &&
                        (
                            <div style={{marginBottom: '15px'}}>Фотосъемка c моделью</div>
                        )
                    }
                    {photoTypes.map((but) => (
                            <Accordion style={{width: '90%'}} key={but.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1-content"
                                    id="panel1-header">
                                    {but.typeShootName}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/*<div> Доступные тарифы : {but.typeShootName}</div>*/}
                                    {allPhotoTypeTarifs.filter((e) => but.id === e.photoShootType.id).map((tarifs) => (
                                            <TarifDiv
                                                key={tarifs.id}
                                                name={tarifs.typeShootName}
                                                baseCost={tarifs.baseCost}
                                                stylish={tarifs.descStylish}
                                                posing={tarifs.descPosing}
                                                minPhoto={tarifs.minPhoto}
                                                maxPhoto={tarifs.maxPhoto}
                                                imageCollection={tarifs.imageCollection}
                                            ></TarifDiv>
                                        )
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )
                    )}
                    <hr style={{margin: '10px'}}/>
                    <Accordion style={{width: '90%'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1-content"
                            id="panel1-header">
                            Видеоcъемка
                        </AccordionSummary>
                        <AccordionDetails>
                            {videoTypeTarifs.map((but) => (
                                    <TarifDiv
                                        key={but.id}
                                        name={but.videoTarifName}
                                        baseCost={but.baseCost}
                                        ident={"Видеоcъемка: " + but.videoTarifName}
                                    ></TarifDiv>
                                )
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{width: '90%'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2-content"
                            id="panel2-header">
                            Дополнительные опции
                        </AccordionSummary>
                        <AccordionDetails>
                            {dopTarifs.map((but) => (
                                    <TarifDiv key={but.id}
                                                      name={but.dopTarifName}
                                                      baseCost={but.baseCost}
                                                      ident={"Дополнительные опции: " + but.dopTarifName}
                                    ></TarifDiv>
                                )
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{width: '90%'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3-content"
                            id="panel3-header">
                            Услуги после съемки
                        </AccordionSummary>
                        <AccordionDetails>
                            {postProdTarifs.map((but) => (
                                    <TarifDiv key={but.id}
                                                      name={but.postProdTarifName}
                                                      baseCost={but.baseCost}
                                                      ident={"Услуги после съемки: " + but.postProdTarifName}
                                    ></TarifDiv>
                                )
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>


            </div>


        </div>
    )
        ;
};

export default Tarifs;