import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import cl from './Navbar.module.css';
import {AuthContext} from "../../context";
import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const Navbar = () => {

    const navigate = useNavigate();

    const {isAuth, setAdminPanelOpen} = useContext(AuthContext)

    function nav() {
        navigate(`/`)
    }

    const classs = cl.menuIcon;

    const [check, setCheck] = useState(false)

    function clickAdmin() {
        setCheck(false);
        setAdminPanelOpen(true);
    }

    return (
        <header className={cl.menu}>
            <div className={cl.menuWrap}>
                <img className={cl.logoImg} onClick={() => nav()} src={"/icons/logo.png"}
                     alt="Логотип"/>
                <input checked={check} type="checkbox" readOnly className={cl.checkbox} id="checkbox"/>
                <nav>
                    <ul>
                        {/*<li>*/}
                        {/*    <Link onClick={(e) => setCheck(false)} className={cl.nav__link} to="/tarifs">Тарифы</Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link onClick={(e) => setCheck(false)} className={cl.nav__link}
                                  to="/models">Модели</Link>
                        </li>
                        <li>
                            <Link onClick={(e) => setCheck(false)} className={cl.nav__link} to="/">Расписание</Link>
                        </li>
                        {isAuth &&
                            <li>
                                <div onClick={event => clickAdmin()} className={cl.nav__link}
                                      to="/admin">Админка</div>
                            </li>
                        }
                        {isAuth &&
                            <li><Link onClick={(e) => setCheck(false)} className={cl.nav__link}
                                      to="/login">Логин</Link>
                            </li>
                        }
                    </ul>
                </nav>
                <img src="/icons/nav-icon.png" className={classs} onClick={(e) => setCheck(!check)}></img>

            </div>
        </header>
    );
};

export default Navbar;