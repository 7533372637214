import React from 'react';
import axios from "axios";
import Button from "@mui/material/Button";


const ShootList = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const statuses = {
        PLANNING: "white",
        REQUESTED: "lightblue",
        DISCUSSION_BOOK: "lightblue",
        BOOKED: "lightgreen",
        REQUESTED_CANCEL: "lightyellow",
        DISCUSSION_CANCEL: "lightyellow",
        CANCELED_BY_ME: "lightsalmon",
        CANCELED_BY_MODEL: "lightcoral",
    }

    function delModelFromShooting(shootId, modelId) {
        axios.post(
            apiUrl + 'booking/del',
            {
                shootsId: shootId,
                modelsId: modelId
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function delShooting(shootId) {
        axios.post(
            apiUrl + 'shoots/delete',
            {
                shootsId: shootId
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    if (!props.shoots.length) {
        return (<h3 style={{textAlign: 'center'}}>
                Ближайшие съемки не найдены
            </h3>
        )
    }

    return (
        <div>
            <h3 style={{textAlign: "center"}}>{props.title}</h3>
            <div className="shoot_list">
                {props.shoots.map((shoot) =>
                    <div key={shoot.id} className="shoot_item">
                        <h4>
                            <strong>{shoot.dayOfWeek}</strong> {shoot.date} {shoot.shootingLocation.name}. {shoot.room && shoot.room.roomName}
                        </h4>
                        {shoot.bookings.sort(function (a, b) {
                            return a.planTimeLong - b.planTimeLong || a.modelPerson.name.localeCompare(b.modelPerson.name) || a.modelPerson.id - b.modelPerson.id;
                        }).map((booking, index) =>
                            <div className="model_mini_logo"
                                 style={{backgroundColor: statuses[booking.bookingStatus] ? statuses[booking.bookingStatus] : "white"}}
                                 key={booking.id}>
                                {props.booking &&
                                    <div>
                                        {booking.bookingStatusName}
                                    </div>
                                }
                                <div>
                                    {booking.modelPerson.name}
                                </div>
                                <div className="vertical_conteiner">
                                    <img style={{
                                        borderRadius: '8px',
                                        maxWidth: 'auto',
                                        maxHeight: '60px',
                                        cursor: 'pointer'
                                    }}
                                         src={`/images/medium/${booking.modelPerson.photo}`}
                                         alt={'Фото ' + booking.modelPerson.name}
                                         onClick={(e) => props.onClickImg(booking, shoot)}
                                    />
                                    {props.booking && booking.planTime !== '' &&
                                        <div>{booking.planTime} - {booking.planDuration}ч</div>
                                    }
                                </div>
                                {props.showDelButton &&
                                    <Button
                                        onClick={(e) => (delModelFromShooting(shoot.id, booking.modelPerson.id))}>Удалить</Button>}
                                {props.booking && booking.comment && booking.comment.length>0 &&
                                    <div>
                                        Есть комментарий
                                    </div>
                                }
                            </div>
                        )}
                        {props.showDelShootButton &&
                            <Button onClick={(e) => (delShooting(shoot.id))}>Удалить съемку</Button>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShootList;