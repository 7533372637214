import React, {useRef, useState} from 'react';
import MyModal from "./MyModal";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import MyModal90 from "./MyModal90";
import {Label} from "@mui/icons-material";
import {TextField} from "@mui/material";
import {Link} from "react-router-dom";
import Loader from "../Loader/Loader";
import ShootTzItem from "../list/ShootTzItem";
import Button from "@mui/material/Button";
import ShootList from "../list/ShootList";

const ChangeShootAndModelModal = (props) => {

    const [model, setModel] = useState({})
    const [shoot, setShoot] = useState({})


    function onChangeModel(model,shoot) {
        setShoot(shoot)
        setModel(model)
    }

    return (
        <MyModal90 visible={props.visible} setVisible={props.setVisible}>
            {props.visible &&
                <div style={{overflowY:"auto", maxHeight: "83vh", width: "100%"}}>
                    <button onClick={event => props.setVisible(false)} className="close_modal_button">X</button>
                    <div className="vertical_conteiner">
                        <div className="horizont_left_conteiner">
                            <Button
                                size="small"
                                variant="contained"
                                onClick={(e)=> props.setShootAndModel(model,shoot)}
                            >Сохранить</Button>
                        </div>
                        <div className="horizont_left_conteiner">
                            <TextField
                                required
                                disabled
                                id="outlined-required6"
                                value={model.fio}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                disabled
                                id="outlined-required6"
                                value={shoot.date}
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div>
                            <h3 style={{textAlign: "center"}}>Съемки</h3>
                            <ShootList
                                onClickImg={onChangeModel}
                                shoots={props.shoots}
                                title='Ближайшие съемки'/>
                        </div>
                    </div>
                </div>
            }
        </MyModal90>
    );
};

export default ChangeShootAndModelModal;