import React, {useState} from 'react';
import cl from './ResultForm.module.css'
import axios from "axios";
import Button from "@mui/material/Button";
import {
    Box,
    FormControl, InputLabel, MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";

const ResultForm = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [nick, setNick] = useState('')
    const [manager,setManager] = useState(0)


    function sendToServer() {
        const requestDto = {
            name
            , phone
            , nick
            , typeShootId: props.typeShoot.id
            , structureRequest: props.structureRequest
            , model: props.model.id
            , back :props.backId
            , manager
            , comment : ""
        }

        axios.post(
            apiUrl+ 'open/requests/create',
            requestDto,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }
        )
            .then(res => {
                console.log(res.data);
                setNick('')
                setPhone('')
                setName('')
                props.setModalVisible(false)
                props.setModalThanksVisible(true)

            })
            .catch(err => {
                console.log(err);
            })
    }

    function handleChange(e) {
        setManager(e.target.value)
    }

    return (
        <div className={cl.result_block}>
            {props.day &&
                <div>Дата : {props.day.date}</div>
            }
            {props.model &&
                <div>Модель : {props.model.name}</div>
            }
            {props.day && props.backId!==0 &&
                <div>Фон : {props.day.room.backgrounds.find(item => item.id === props.backId).backName}</div>
            }
            {props.typeShoot.id !== 0 &&
                <TableContainer component={Paper} size='small' sx={{maxHeight: 230, width: '100%'}}>
                    <Table sx={{minWidth: 200}} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{padding: '1px'}}>
                                <TableCell sx={{padding: '3px', textAlign: 'center'}}>Услуга</TableCell>
                                <TableCell sx={{padding: '3px', textAlign: 'center'}}>Количество, арт.</TableCell>
                                <TableCell sx={{padding: '3px', textAlign: 'center'}}>Итог, р</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(props.structureRequest).map(([key, value]) => (
                                value.quantity > 0 ?
                                    <TableRow key={key}>
                                        <TableCell sx={{padding: '3px'}}>{value.name}</TableCell>
                                        <TableCell sx={{padding: '3px'}}>{value.quantity}</TableCell>
                                        <TableCell sx={{padding: '3px'}}>{value.cost}</TableCell>
                                    </TableRow>
                                    : null
                            ))}
                            <TableRow>
                                <TableCell sx={{padding: '3px'}} colSpan={2}>Итого :</TableCell>
                                <TableCell sx={{padding: '3px'}} align="right">{props.resultCost}р.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            <hr style={{margin: '10px'}}/>
            <TextField
                required
                fullWidth
                id="outlined-required"
                label="Имя"
                value={name}
                onChange={e => setName(e.target.value)}
                margin="dense"
                InputLabelProps={{shrink: true}}
            />
            <TextField
                fullWidth
                required
                type="tel"
                id="outlined-required"
                label="Телефон"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                margin="dense"
                InputLabelProps={{shrink: true}}
            />
            <TextField
                fullWidth
                id="outlined-required"
                margin="dense"
                label="Ник в телеграмм"
                value={nick}
                InputLabelProps={{shrink: true}}
                onChange={e => setNick(e.target.value)}
            />
            <Box sx={{minWidth: 120, marginTop: "7px"}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Предпочитаемый менеджер</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={manager}
                        label="Предпочитаемый менеджер"
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>Любой</MenuItem>
                        {props.managers.map((man) => (
                            <MenuItem key={man.id} value={man.id}>{man.realName}</MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <hr style={{margin: '10px'}}/>
            <Button variant="outlined" onClick={() => props.setModalVisible(false)}>Закрыть</Button>
            <Button variant="contained" onClick={() => sendToServer()}>Записаться</Button>
        </div>
    );
};

export default ResultForm;