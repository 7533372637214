import React, {useState} from 'react';
import cl from "./NumberInput.module.css"

const NumberInput = (props) => {
    const [quant, setQuant] = useState(props.structureRequest[props.tarifId] ? props.structureRequest[props.tarifId].quantity : 0);

    const modelTarifId = props.shootId + '-' + props.modelId + '-' + props.tarifId;

    function setQuan(e) {
        setQuant(e.target.value)
        setStructureRequest(e.target.value)
    }

    function setStructureRequest(quan) {
        props.setStructureRequest({
            ...props.structureRequest,
            [modelTarifId]: {
                name: props.ident,
                quantity: quan,
                cost: quan * props.baseCost,
                baseCost: props.baseCost,
                tarifId: props.tarifId,
                modelId: props.modelId,
                shootId: props.shootId,
                backId: props.backId
            }
        })

    }

    function setQuantInc() {
        if (props.oneTime && quant > 0) {

        } else {
            setQuant(quant + 1)
            setStructureRequest(quant + 1)
        }
    }

    function setQuantDec() {
        if (quant != 0) {
            setQuant(quant - 1)
            setStructureRequest(quant - 1)
        }
    }

    return (
        <div className={cl.input_conteiner}>
            <div className={cl.input_conteiner_base_cost}>{props.baseCost}р×</div>
            <input className={cl.input_conteiner_input} type="number" min="0" value={quant}
                   onChange={(e) => setQuan(e)}/>
            <div className={cl.input_conteiner_buttons}>
                <button className={cl.input_conteiner_button} onClick={setQuantInc}>+</button>
                <button className={cl.input_conteiner_button} onClick={setQuantDec}>-</button>
            </div>
            {props.oneTime
                ? <div className={cl.input_conteiner_base_cost}>дост.=</div>
                : <div className={cl.input_conteiner_base_cost}>арт.=</div>
            }
            <div className={cl.input_conteiner_final_cost}>{quant * props.baseCost}р
            </div>
        </div>
    );
};

export default NumberInput;