import React, {useState} from 'react';
import Button from "@mui/material/Button";
import cl from "./TagModel.module.css"
import {Box, Popper} from "@mui/material";

const TagModel = ({model}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popperTitle, setPopperTitle] = useState('');

    const handleClickPopper = (event, title) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setPopperTitle(title)
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <Popper open={open} id={id} anchorEl={anchorEl}>
                <Box sx={{border: 1, p: 1, bgcolor: 'background.paper'}}>
                    {popperTitle}
                </Box>
            </Popper>
            {(model.lingerie || (model.clothes1Hour + model.selfAssembly) > 5999 || model.plusSize) &&
                <div className={cl.model__card__im_text__icons}>
                    {(model.lingerie) &&
                        <div className={cl.model__card__im_text__icon_wrap}>
                            {model.male
                                ? <Button aria-describedby={id} type="button"
                                          onClick={(e) => handleClickPopper(e, 'Снимается в нижнем белье')}>
                                    <img className={cl.model__card__im_text__icon}
                                         src={`/icons/male_ling.png`}
                                         alt='Снимается в нижнем белье'
                                    />
                                </Button>
                                : <Button aria-describedby={id} type="button"
                                          onClick={(e) => handleClickPopper(e, 'Снимается в нижнем белье')}>
                                    <img className={cl.model__card__im_text__icon}
                                         src={`/icons/female_ling.png`}
                                         alt='Снимается в нижнем белье'
                                         title='Снимается в нижнем белье'
                                    />
                                </Button>

                            }
                        </div>
                    }
                    {((model.clothes1Hour + model.selfAssembly) > 5999) &&
                        <div className={cl.model__card__im_text__icon_wrap}>
                            <Button aria-describedby={id} type="button"
                                    onClick={(e) => handleClickPopper(e, 'TOP модель. Повышенная цена')}>
                                <img className={cl.model__card__im_text__icon}
                                     src={`/icons/dollar.png`}
                                     alt='Повышенная цена'
                                     title='TOP модель. Повышенная цена'
                                ></img>
                            </Button>

                        </div>
                    }
                    {(model.plusSize) &&
                        <div className={cl.model__card__im_text__icon_wrap}>
                            <Button aria-describedby={id} type="button"
                                    onClick={(e) => handleClickPopper(e, 'Модель Плюс Сайз')}>
                                <img className={cl.model__card__im_text__icon}
                                     src={`/icons/plus_size.svg`}
                                     alt='Модель Плюс Сайз'
                                     title='Модель Плюс Сайз'
                                ></img>
                            </Button>

                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default TagModel;