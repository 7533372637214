import React, {useState} from 'react';
import axios from "axios";
import {MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";

const ClientForm = (props) => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const contactTypes = [
        {code: "PHONE", name: "Телефон"},
        {code: "TELEGRAM", name: "Телеграм"},
        {code: "COMMON_TELEGRAM", name: "Общий чат"},
        {code: "WHATSAPP", name: "WhatsApp"},
        {code: "VIBER", name: "Viber"}]

    function createNewClient() {
        const client = {
            ...props.client
        }

        console.log()

        axios.post(
            apiUrl + 'clients/add',
                client,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                props.setVisibleClientForm(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    function addContact() {
        const newContact = {
            id: new Date().valueOf(),
            contactType: "PHONE",
            value: "",
            comment: ""
        }
        props.setClient({...props.client, contacts: [...props.client.contacts, newContact]});
    }

    return (
        <div className="horizont_left_conteiner">
            <div className="vertical_left_conteiner">
                <TextField
                    id="outlined-required"
                    label="Имя"
                    value={props.client.name}
                    onChange={e => props.setClient({...props.client, name: e.target.value})}
                    margin="dense"
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    id="outlined-required"
                    label="Коментарий"
                    value={props.client.comment}
                    onChange={e => props.setClient({...props.client, comment: e.target.value})}
                    margin="dense"
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    id="outlined-required"
                    label="Скидка"
                    type="number"
                    value={props.client.sale}
                    onChange={e => props.setClient({...props.client, sale: e.target.value})}
                    margin="dense"
                    InputLabelProps={{shrink: true}}
                />
                <Button onClick = {createNewClient}>Создать/Сохранить</Button>
            </div>
            <div className="vertical_left_conteiner">
            <div>Контакты</div>
            {props.client.contacts.sort((a, b) => a.id - b.id).map((contact) => (
                <div key={contact.id}
                     style={{alignItems: "center", justifyContent: "center", flexDirection: "row", display: "flex"}}>
                    <div><Select
                        variant='standard'
                        id="demo-simple-select"
                        value={contact.contactType}
                        onChange={(e) => {
                            props.setClient({
                                ...props.client,
                                contacts: [...props.client.contacts.map(item => item.id === contact.id ? {
                                    ...item,
                                    contactType: e.target.value
                                } : item)]
                            })
                        }}
                    >
                        {contactTypes.map((type) => (
                            <MenuItem value={type.code}
                                      key={type.code}>{type.name}</MenuItem>
                        ))
                        }
                    </Select>
                    </div>
                    <TextField
                        id="outlined-required"
                        label="Контакт"
                        value={contact.value}
                        onChange={e => props.setClient({
                                ...props.client,
                                contacts: [...props.client.contacts.map(item => item.id === contact.id ? {
                                    ...item,
                                    value: e.target.value
                                } : item)]
                            }
                        )}
                        InputLabelProps={{shrink: true}}
                        margin="dense"
                    />
                    <TextField
                        id="outlined-required"
                        label="Коментарий"
                        value={contact.comment}
                        onChange={e => props.setClient({
                                ...props.client,
                                contacts: [...props.client.contacts.map(item => item.id === contact.id ? {
                                    ...item,
                                    comment: e.target.value
                                } : item)]
                            }
                        )}
                        InputLabelProps={{shrink: true}}
                        margin="dense"
                    />
                    <Button onClick={(e) => props.setClient({
                            ...props.client,
                            contacts: [...props.client.contacts.filter(item => item.id !== contact.id)]
                        }
                    )}>Удалить контакт</Button>
                </div>
            ))
            }
            <Button onClick={addContact}>Добавить контакт</Button>
            </div>
        </div>
    )
        ;
};

export default ClientForm;