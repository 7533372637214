import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import Loader from "../UI/Loader/Loader";
import {
    FormControl,
    InputLabel, MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, ToggleButton, ToggleButtonGroup
} from "@mui/material";
import axios from "axios";
import {Link} from "react-router-dom";

const AdminRequest = () => {

    const [requests, setRequests] = useState([]);
    const [filter, setFilter] = useState({status: "IN_PROGRES"});


    const [fetchRequests, isRequestsLoading, requestsError] = useFetching(async () => {
        const requests = await RequestService.getAllRequests(filter);
        console.log(requests)
        setRequests(requests)
    })

    const statuses = [
        {code: "NEW", name: "Новый"},
        {code: "IN_PROGRESS", name: "В работе"},
        {code: "CANCELED", name: "Отменен"},
        {code: "CREATED_ORDER", name: "Создан заказ"}]

    useEffect(() => {
        console.log("fetch requests")
        fetchRequests()
    }, [filter]);

    const removeRequest = (id) => {
        // setModels(models.filter((model) => id !== model.id))
    }

    const apiUrl = process.env.REACT_APP_API_URL;

    function changeStatus(id, statusEn) {
        axios.post(
            apiUrl + 'requests/set_status',
            {
                requestId: id,
                status: statusEn
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchRequests()
            })
            .catch(err => {
                console.log(err);
            })
    }

    function createOrderFromRequest(id) {
        axios.post(
            apiUrl + 'orders/create_order_from_request',
            {
                requestId: id
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div style={{minHeight: "90vh"}}>
            {requestsError &&
                <h3> Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isRequestsLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                :
                <div>
                    <div>Заявки</div>
                    <div className="horizont_left_conteiner">
                        <ToggleButtonGroup
                            style={{marginRight: "5px"}}
                            size = "small"
                            color="primary"
                            value={filter.status}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton onClick={() => setFilter({...filter, status : "IN_PROGRES"})} value={"IN_PROGRES"}>В работе</ToggleButton>
                            <ToggleButton onClick={() => setFilter({...filter, status : "ALL"})} value={"ALL"}>Все</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <TableContainer component={Paper} size='small' sx={{width: '100%'}}>
                        <Table sx={{minWidth: 200}} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{padding: '1px'}}>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Дата заявки</TableCell>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Клиент</TableCell>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Заказ</TableCell>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Комментарий</TableCell>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Менеджер</TableCell>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Статус</TableCell>
                                    <TableCell size='small' sx={{textAlign: 'center'}}>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {requests.map((request) => (
                                    <TableRow key={request.id}>
                                        <TableCell size='small' sx={{padding: '1px'}}>{request.dateRequest}</TableCell>
                                        <TableCell size='small' sx={{padding: '1px', minWidth: '100px'}}>
                                            {request.clientName + '\n' + request.clientNick + '\n' + request.clientPhone}
                                        </TableCell>
                                        <TableCell size='small' sx={{padding: '1px', minWidth: '200px'}}>
                                            {request.orderr}
                                        </TableCell>
                                        <TableCell size='small' sx={{padding: '1px'}}>{request.comment}</TableCell>
                                        <TableCell size='small' sx={{padding: '1px'}}>{request.managerName}</TableCell>
                                        <TableCell size='small' sx={{padding: '1px'}}>
                                            <FormControl>
                                                <Select
                                                    id="demo-simple-select"
                                                    value={request.requestStatusEn}
                                                    onChange={(e) => {
                                                        changeStatus(request.id, e.target.value)
                                                    }}
                                                >
                                                    {statuses.map((stat) => (
                                                        <MenuItem value={stat.code}
                                                                  key={stat.code}>{stat.name}</MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell size='small' sx={{padding: '1px'}}>
                                            {request.requestStatusEn !== 'CREATED_ORDER' &&
                                                <Button onClick={(e) => {
                                                    createOrderFromRequest(request.id)
                                                }}>Создать заказ</Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    );
};

export default AdminRequest;