import React, {useRef, useState} from 'react';
import MyModal from "./MyModal";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import MyModal90 from "./MyModal90";
import {Label} from "@mui/icons-material";
import {TextField} from "@mui/material";

const ModalImageCollection = (props) => {


    const [modalImageVisible, setModalImageVisible] = useState(false);
    const [pathToImage, setPathToImage] = useState('');

    function setPathToIm(path) {
        setPathToImage(path)
        setModalImageVisible(true)
    }

    return (
        <MyModal90 visible={props.visible} setVisible={props.setVisible}>
            <button onClick={event => props.setVisible(false)} className="close_modal_button">X</button>
            <div className="image_modal_conteiner">
                {props.imageCollection.collection.map((img) => (
                    props.imageCollectionWithSignature
                        ?
                        <div className="vertical_conteiner">
                            <img key={`/images/medium/${img.path}`} className="example_modal_image_in_collection"
                                 src={`/images/medium/${img.path}`}
                                 alt={img.altName}
                                 onClick={event => setPathToIm(img.path)}
                            />
                            <TextField
                                variant="standard"
                                id="outlined-required"
                                value={img.altName}
                                readOnly
                                multiline
                                margin="dense"
                            />
                        </div>
                        : <img key={`/images/medium/${img.path}`} className="example_modal_image_in_collection"
                               src={`/images/medium/${img.path}`}
                               alt={img.altName}
                               onClick={event => setPathToIm(img.path)}
                        />
                ))}
            </div>
            {
                modalImageVisible &&
                <MyModal style={{zIndex: '5'}} visible={modalImageVisible} setVisible={setModalImageVisible}>
                    <button onClick={event => setModalImageVisible(false)} className="close_modal_button">X</button>
                    <TransformWrapper wheel={{step: '1', smoothStep: '0.005'}}>
                        <TransformComponent>
                            <img
                                 src={`/images/${pathToImage}`}
                                 alt="фото"
                                 className="fullscreen_modal_image"/>
                        </TransformComponent>
                    </TransformWrapper>
                </MyModal>
            }
        </MyModal90>
    );
};

export default ModalImageCollection;