import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import OrderItem from "../UI/list/OrderItem";
import MyModal90 from "../UI/MyModal/MyModal90";
import ClientsModalForm from "../Forms/ClientsModalForm";
import {
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MyModal from "../UI/MyModal/MyModal";
import TarifService from "../API/TarifService";
import LocationsService from "../API/LocationService";
import ModelForm from "../Forms/ModelForm";
import FileUpload from "../UI/Loader/FileUpload";
import CostService from "../API/CostService";


const AdminTarif = () => {

    const [costCont, setCostCont] = useState([]);
    const [patterns, setPatterns] = useState([]);
    const [costVar, setCostVar] = useState([]);
    const [filesModal, setFilesModal] = useState(false);
    const [editRow, setEditRow] = useState({name: '', baseCost: 0, cost: 0, costType: "CONTINUOUS", costPatern: 0});

    const [fetchCostCont, isCostContLoading, costContError] = useFetching(async () => {
        const tarifs = await CostService.getAllCostContin();
        console.log(tarifs)
        setCostCont(tarifs)
    })

    const [fetchCostVar, isCostVarLoading, costVarError] = useFetching(async () => {
        const tarifs = await CostService.getAllCostVar();
        console.log(tarifs)
        setCostVar(tarifs)
    })

    const [fetchCostPatterns, isCostPatternsLoading, costPatternsError] = useFetching(async () => {
        const costPatterns = await CostService.getAllCostPatterns();
        console.log(costPatterns)
        setPatterns(costPatterns)
    })


    useEffect(() => {
        fetchCostCont()
        fetchCostVar()
        fetchCostPatterns()
    }, []);


    const COST_TYPE =
        [
            {code: "CONTINUOUS", name: "Постоянные"},
            {code: "VARIABLES", name: "Переменные"},
            {code: "ONE_TIME", name: "Единоразовые"}
        ]

    const PAY_STATUS_ENUM =
        [
            {code: "NOT_PAYED", name: "Не оплачен"},
            {code: "PAYED", name: "Оплачен"},
            {code: "NEED_PAYED", name: "Необходима доплата"}
        ]

    const apiUrl = process.env.REACT_APP_API_URL;

    function saveCollection() {
        console.log(editRow)
        axios.post(
            apiUrl + 'costs/add',
            editRow
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setFilesModal(false)
                fetchCostCont()
                fetchCostVar()
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div style={{minHeight: "90vh"}}>
            <MyModal90 visible={filesModal} setVisible={setFilesModal}>
                <div className="vertical_left_conteiner"
                     style={{overflowY: "auto", height: "auto", maxHeight: "70vh"}}>
                    <div className="vertical_left_conteiner">
                        <Select
                            labelId="demo-simple-select-label11"
                            id="demo-simple-select"
                            label="Типы товара для фотосъемки"
                            size="small"
                            fullWidth
                            onChange={e => setEditRow({...editRow, costType: e.target.value})}
                            value={editRow.costType}
                            sx={{minWidth: "150px", marginTop: "5px"}}
                        >
                            {COST_TYPE.map((option) => (
                                <MenuItem key={option.id} value={option.code}>{option.name}</MenuItem>
                            ))
                            }
                        </Select>
                        <Select
                            labelId="demo-simple-select-label11"
                            id="demo-simple-select"
                            label="Шаблон"
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                setEditRow({
                                        ...editRow,
                                        costPaternId: patterns.find((x) => (x.id === e.target.value)) != null ? patterns.find((x) => (x.id === e.target.value)).id : 0,
                                        costPatern: patterns.find((x) => (x.id === e.target.value)),
                                        baseCost: patterns.find((x) => (x.id === e.target.value)) != null ? patterns.find((x) => (x.id === e.target.value)).base : 0,
                                        name: patterns.find((x) => (x.id === e.target.value)) != null ? patterns.find((x) => (x.id === e.target.value)).name : "",
                                        cost: patterns.find((x) => (x.id === e.target.value)) != null ? patterns.find((x) => (x.id === e.target.value)).base : 0
                                    }
                                )
                            }
                            }
                            value={editRow.costPaternId}
                            sx={{minWidth: "150px", marginTop: "5px"}}
                        >
                            <MenuItem value={0}>Не определено</MenuItem>
                            {patterns.filter((e) => e.costType === editRow.costType).map((option) => (
                                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            ))
                            }
                        </Select>
                        <Select
                            labelId="demo-simple-select-label11"
                            id="demo-simple-select"
                            label="Шаблон"
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                setEditRow({
                                        ...editRow,
                                        payStatusEnum: e.target.value
                                    }
                                )
                            }
                            }
                            value={editRow.payStatusEnum}
                            sx={{minWidth: "150px", marginTop: "5px"}}
                        >
                            {PAY_STATUS_ENUM.map((option) => (
                                <MenuItem key={option.code} value={option.code}>{option.name}</MenuItem>
                            ))
                            }
                        </Select>
                        <TextField
                            required
                            label="Название"
                            size="small"
                            value={editRow.name}
                            onChange={e => setEditRow({...editRow, name: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            type="number"
                            label="базовая цена"
                            disabled
                            size="small"
                            value={editRow.baseCost}
                            onChange={e => setEditRow({...editRow, baseCost: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            type="number"
                            label="Цена"
                            size="small"
                            value={editRow.cost}
                            onChange={e => setEditRow({...editRow, cost: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            label="Период"
                            type="month"
                            size="small"
                            fullWidth
                            value={editRow.period}
                            onChange={e => setEditRow({...editRow, period: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            label="Надо оплатить"
                            type="date"
                            size="small"
                            fullWidth
                            value={editRow.needDate}
                            onChange={e => setEditRow({...editRow, needDate: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            label="Оплачено"
                            type="date"
                            size="small"
                            fullWidth
                            value={editRow.payDate}
                            onChange={e => setEditRow({...editRow, payDate: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>
                    <Button onClick={saveCollection}>Сохранить</Button>
                </div>
            </MyModal90>
            {costVarError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isCostVarLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '70px'}}><Loader/></div>
                :
                <div className={cl.main_conteiner}>
                    <h3>Текущий месяц</h3>
                    <Button onClick={(e) => {
                        setEditRow({
                            name: '',
                            baseCost: 0,
                            cost: 0,
                            costType: "CONTINUOUS",
                            costPaternId: 0,
                            payStatusEnum: "NOT_PAYED"
                        })
                        setFilesModal(true)
                    }}>Добавить расход на основе постоянного шаблона</Button>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{padding: "2px"}}>Название</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Цена по шаблону</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Расход</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Статус</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Оплатить до</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Дата оплаты</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Тип</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Ред</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {costCont.map((costEl) => (
                                    <TableRow key={costEl.id}>
                                        <TableCell sx={{padding: "2px"}}>{costEl.name}</TableCell>
                                        {costEl.costPatern
                                            ? <TableCell sx={{padding: "2px"}}>{costEl.costPatern.base}</TableCell>
                                            : <TableCell sx={{padding: "2px"}}></TableCell>
                                        }
                                        <TableCell sx={{padding: "2px"}}>{costEl.cost}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>{costEl.payStatusEnumName}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>{costEl.needDate}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>{costEl.payDate}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>{costEl.costTypeName}</TableCell>

                                        <TableCell sx={{padding: "2px"}}><Button
                                            sx={{padding : "0px"}}
                                            size="small"
                                            onClick={(e) => {
                                                if (costEl.costPatern != null) {
                                                    setEditRow({
                                                        ...costEl,
                                                        costPaternId: costEl.costPatern.id,
                                                        baseCost: costEl.costPatern.base
                                                    })
                                                } else {
                                                    setEditRow(costEl)
                                                }
                                                setFilesModal(true)
                                            }}>Ред</Button></TableCell>

                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    );
};

export default AdminTarif;