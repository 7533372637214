import {StrictMode} from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';


import App from './App'
import {hydrate} from "react-dom";

const rootElement =
    document.getElementById('root');

if (!rootElement.hasChildNodes()) {
    const root = createRoot(rootElement);
    root.render(<App/>);
} else {
    // const root = hydrateRoot(rootElement);
    hydrate(<App />, document.getElementById('root'));
    // root.render(<App/>, rootElement);
    console.log("Hidrate")
}

// if (rootElement.hasChildNodes()) {
//     root.hydrate(<App/>, rootElement);
// } else {
//     root.render(<App/>, rootElement);
// }

// root.render(
//     // <StrictMode>
//         <App />
//     // </StrictMode>,
// );
