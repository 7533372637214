import React, {useState} from 'react';
import axios from "axios";
import Button from "@mui/material/Button";
import {FormControl, Input, InputLabel, MenuItem, Select} from "@mui/material";
import cl from "./ShootCreateForm.module.css"
import MyModal from "../UI/MyModal/MyModal";


const ShootCreateForm = (props) => {


    const [shootDate, setShootDate] = useState(
        new Date().toISOString().split('T')[0])
    const [loc, setLoc] = useState(0)
    const [room, setRoom] = useState(0)


    // // Get today's date
    // let today = new Date();
    // // Calculate the date for 2 months from today
    // let twoMonthsLater = new Date();
    // twoMonthsLater.setMonth(today.getMonth() + 2);
    //
    // // Format the date in YYYY-MM-DD format for input field
    // let maxDate = twoMonthsLater.toISOString().split('T')[0];

    const addNewShoot = (e) => {
        e.preventDefault();
        const params = {
            shootDate: shootDate,
            shootLocation: loc
        };
        const apiUrl = process.env.REACT_APP_API_URL;

        axios.post(
            apiUrl + 'shoots/add',
            {
                shootDate: shootDate,
                shootLocation: loc,
                room: room,
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                props.create(res.data)

            })
            .catch(err => {
                console.log(err);
            })
        setShootDate(new Date().toISOString().split('T')[0])
    }

    return (
        <MyModal visible={props.visible} setVisible={props.setVisible}>
            <div className={cl.main_conteiner}>
                <FormControl fullWidth sx={{margin: '4px'}}>
                    <InputLabel id="demo-simple-select-label">Локация</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={loc}
                        label="Локация"
                        onChange={(e) => {
                            setLoc(e.target.value)
                            console.log(e.target.value)
                            setRoom(0)
                        }}
                    >
                        <MenuItem value="0">Не задано</MenuItem>
                        {props.locations.map((location, index) => (
                            <MenuItem value={location.id} key={index}>{location.name}</MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>
                {loc !== 0 &&
                    <FormControl fullWidth sx={{margin: '4px'}}>
                        <InputLabel id="demo-simple-select">Зал</InputLabel>
                        <Select
                            style={{margin: '2px'}}
                            labelId="demo-simple-select"
                            id="demo-simple-select1"
                            value={room}
                            label="Зал"
                            onChange={(e) => setRoom(e.target.value)}
                        >
                            <MenuItem value="0">Не задано</MenuItem>
                            {props.locations.find((e) => (e.id === loc)) &&
                                props.locations.find((e) => (e.id === loc)).rooms.map((room, index) => (
                                    <MenuItem value={room.id} key={index}>{room.roomName}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
                <Input
                    style={{margin: '5px'}}
                    type='date'
                    value={shootDate}
                    onChange={(e) => {
                        setShootDate(e.target.value)
                    }}
                    InputLabelProps={{shrink: true}}
                />
                <Button style={{margin: '5px'}} variant="outlined" onClick={addNewShoot}>Запланировать съемку</Button>

            </div>
        </MyModal>
    )
        ;
};

export default ShootCreateForm;