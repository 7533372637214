import axios from "axios";

export default class TarifService {
    static getAllGoodsTypes() {
        const goods_types = [
            {code: "CLOTHES_SHOES", name: "Одежда/Обувь"},
            {code: "LINGERIE", name: "Бельё"},
            {code: "ACCESSORIES", name: "Аксесуары/косметика"}]
        return goods_types
    }


    static async getAllTarifs() {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'tarifs/list')
        return response.data
    }

    static async getAllTarifsByModel(modelId,shootId) {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'tarifs/photo_tarifs/'+modelId+'/'+shootId)
        return response.data
    }

    static async getAllManagers() {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'managers/managers')
        return response.data
    }
}