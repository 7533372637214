import React, { useState } from 'react';
import Button from "@mui/material/Button";
import cl from "./FileUpload.module.css"
import axios from "axios";
import Loader from "./Loader";
import RelativeFileLoader from "./RelativeFileLoader";
import {TextField} from "@mui/material";

const FileUpload = (props) => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [message, setMessage] = useState("Перетащите файлы сюда или кликните, чтобы выбрать");


    const handleDrop = (e) => {
        e.preventDefault();

        const newFiles = Array.from(e.dataTransfer.files).filter((file) => {
            if (file.type === "image/jpg" || file.type === "image/jpeg" ||file.type === "image/webp" || file.type ===  "image/png" ) {
                return true
            }
            console.log(file.type)
            return false
        }).map(file=> {
            return {
                id: Math.random() ,
                load: true,
                altName: '',
                file
            }
        });
        console.log(newFiles)
        newFiles.forEach((file) => {
            const filename = sendPhoto(file)
        })

        props.setFiles((prevFiles) => [...prevFiles, ...newFiles]);


    };

    function sendPhoto(file) {
        if (file !== null) {

            let formData = new FormData();
            formData.append('file', file.file);


            axios.post(
                apiUrl + 'admin/upload',
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                    }, withCredentials: true
                }
            )
                .then(res => {
                    console.log(`Success` + res.data);
                    props.setFiles((prevFiles) => [...prevFiles.map((prevFile)=> {
                       if (prevFile.id === file.id) {
                           return {
                               ...prevFile, load : false, path: res.data, file : null
                           }
                       } else {
                           return prevFile
                       }
                    })]);

                    // return res.data;
                })
                .catch(err => {
                    console.log(err);
                })

        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleFileChange = (e) => {
        e.preventDefault()
        const newFiles = Array.from(e.target.files).filter((file) => {
            if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/webp" || file.type ===  "image/png") {
                return true
            }
            return false
        }).map(file=> {
            return {
                id: Math.random() ,
                load: true,
                altName: '',
                file
            }
        });
        console.log(newFiles)
        newFiles.forEach((file) => {
            const filename = sendPhoto(file)
        })

        props.setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleRemoveFile = (file) => {
        props.setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file));
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className={cl.drag_and_drop_place}
        >
            <p>{message}</p>
            <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} id="fileInput1" />
            <label htmlFor="fileInput1" style={{ cursor: 'pointer' }}>
                <Button onClick={(e)=> document.getElementById('fileInput1').click()}>Выбрать файлы</Button>
            </label>
            <div className={cl.files_conteiner}>
                {props.files.map((file, index) => (
                    <div key={index} className={cl.file_conteiner}>
                        {file.file &&
                            <img src={URL.createObjectURL(file.file)} className={cl.img}/>
                        }
                        {file.path &&
                            <img src={`/images/small/${file.path}`} className={cl.img}/>
                        }
                        {file.load &&
                            <RelativeFileLoader></RelativeFileLoader>
                        }
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label="подпись"
                            multiline
                            value={file.altName}
                            onChange={e => props.setFiles([...props.files].map(x=> x.id!==file.id?x: {...x, altName: e.target.value}))}
                            margin="dense"
                        />
                        <Button onClick={() => handleRemoveFile(file.id)}>
                            Удалить
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileUpload;