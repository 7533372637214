import axios from "axios";

export default class CostService {


    static async getAllCostPatterns() {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'cost_patterns/list',
            {
                withCredentials: true
            })
        return response.data
    }

    static async getAllCostContin() {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'costs/list_cont',
            {
                withCredentials: true
            })
        return response.data
    }

    static async getAllCostVar() {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'costs/list_var',
            {
                withCredentials: true
            })
        return response.data
    }
}