import React from 'react';
import ModelItemMain from "./ModelItemMain";
import cl from './ModelItemMain.module.css'


const ModelListMain = ({models, title}) => {

    if (!models.length) {
        return (<h2 className={cl.container_title}>
                Модели на ближайшие съемки не найдены
            </h2>
        )
    }

    return (
        <div className={cl.all_dash_conteiner}>
            <h1 className={cl.container_title}>{title}</h1>
            <div className={cl.conteiner_model__cards}>
                {models.map((model, index) =>
                    <ModelItemMain key={index} model={model}/>
                )}
            </div>
        </div>
    );
};

export default ModelListMain;