import React from 'react';
import cl from "./Footer.module.css";

const About = () => {
    return (
        <div className={cl.footer_cont}>
        <footer>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"/>
            <a href="https://www.instagram.com/ixor_production" className="social-link"><i className="fab fa-instagram"></i> Канал</a>
            <a href="https://t.me/ixor_product" className="social-link"><i className="fab fa-telegram"></i> Канал</a>
            <a href="https://t.me/ixor_manager" className="social-link"><i className="fab fa-telegram"></i> Менеджер</a>
            <a href="https://wa.me/+79951139012" className="social-link"><i className="fab fa-whatsapp"></i> Менеджер</a>
        </footer>
        </div>
    );
};

export default About;