import React, {useContext, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import axios from "axios";
import MyModal90 from "../UI/MyModal/MyModal90";
import ClientForm from "./ClientForm";
import UniversalFilter from "./UniversalFilter";
import {AuthContext} from "../context";

const ClientsModalForm = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [clients, setClients] = useState([]);
    const [client, setClient] = useState({});
    const [visibleClientForm, setVisibleClientForm] = useState(false);
    const [filterModal, setFilterModal] = useState(false);


    const {
        startClientFilter, clientFilter, setClientFilter
    } = useContext(AuthContext)

    const [fetchClients, isClientsLoading, clientsError] = useFetching(async () => {
        const orders = await RequestService.getAllFilteredClients(clientFilter);
        console.log(orders)
        setClients(orders)
    })

    useEffect(() => {
        console.log("fetch requests")
        fetchClients()
        clearClient()
    }, [clientFilter]);


    function clearClient() {
        setClient({
            name: ''
            , comment: ''
            , sale: 0
            , contacts: []
        })
    }

    function clearFilter() {
        setClientFilter(startClientFilter)
    }

    function onApplyFilterModal() {
        fetchClients()
        setFilterModal(false)
    }


    return (
        <MyModal90 visible={props.visible} setVisible={props.setVisible}>
            <div className="vertical_left_conteiner" style={{overflowY: "auto", height: "auto", maxHeight: "70vh"}}>
                <MyModal90 visible={visibleClientForm} setVisible={setVisibleClientForm}>
                    <ClientForm clearClient={clearClient} client={client} setClient={setClient}
                                setVisibleClientForm={setVisibleClientForm}/>
                </MyModal90>
                <UniversalFilter
                    onApply={onApplyFilterModal}
                    clearFilter={clearFilter}
                    mainFilter={clientFilter}
                    setMainFilter={setClientFilter}
                    setVisibleModal={setFilterModal}
                    visibleModal={filterModal}
                />
                {clients.map((client) => (
                    <div style={{border: "1px solid teal", width: "99%"}} className="horizont_left_conteiner"
                         key={client.id}>
                        <Button onClick={(e) => {
                            props.changeClient(client.id)
                        }}>Выбрать</Button>
                        <div style={{margin: "2px"}}>{client.name}</div>
                        <div style={{margin: "2px"}}>{client.comment}</div>
                        {/*<div style={{margin: "2px"}}>{client.sale}</div>*/}
                        {
                            client.contacts.map((cont) => (
                                <div className="vertical_left_conteiner"
                                     style={{border: "1px solid teal", borderRadius: "5px"}}
                                     key={cont.id}>
                                    {cont.contactType === "PHONE" &&
                                        <div style={{margin: "2px"}}>
                                            <a href={"tel:" + cont.value}>Позвонить
                                                {cont.value}</a>
                                        </div>
                                    }
                                    {cont.contactType === "TELEGRAM" &&
                                        <div style={{margin: "2px"}}>
                                            <a href={"tg://resolve?domain=" + (cont.value.startsWith("@") ? cont.value.substring(1) : cont.value)}>Телеграмм {cont.value}</a>
                                        </div>
                                    }
                                    {cont.contactType === "WHATSAPP" &&
                                        <div style={{margin: "2px"}}>
                                            <a href={"whatsapp://send?phone=" + cont.value}>WhatsApp {cont.value}</a>
                                        </div>
                                    }
                                    {cont.contactType === "COMMON_TELEGRAM" &&
                                        <div style={{margin: "2px"}}>
                                            <a href={"tg://resolve?domain=" + (cont.value.startsWith("@") ? cont.value.substring(1) : cont.value)}>Общий
                                                чат {cont.value}</a>
                                        </div>
                                    }
                                    <div>{cont.comment}</div>
                                </div>
                            ))
                        }
                    </div>
                ))}
            </div>
        </MyModal90>
    );
};

export default ClientsModalForm;