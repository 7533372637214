import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import OrderItem from "../UI/list/OrderItem";
import MyModal90 from "../UI/MyModal/MyModal90";
import ClientsModalForm from "../Forms/ClientsModalForm";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import ShootService from "../API/ShootService";
import UniversalFilter from "../Forms/UniversalFilter";
import {AuthContext} from "../context";


const AdminOrder = () => {

    const {startOrderFilter, orderFilter, setOrderFilter} = useContext(AuthContext)

    const [orders, setOrders] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [shoots, setShoots] = useState([])

    const [editOrderId, setEditOrderId] = useState(0);

    function clearFilter() {
        setOrderFilter(startOrderFilter)
    }

    function onApplyFilterModal() {
        fetchOrders()
        setFilterModal(false)
    }

    const payStatuses = [
        {code: "NOT_PAYED", name: "Не оплачен"},
        {code: "PAYED", name: "Оплачен"},
        {code: "NEED_PAYED", name: "Необходима доплата"}]

    const workStatuses = [
        {code: "IN_PROGRES", name: "В работе"},
        {code: "CANCELED", name: "Отменен"},
        {code: "RECIVED_GOODS", name: "Товар получен"},
        {code: "SHOOTED", name: "Отснят"},
        {code: "COMPLETED", name: "Выполнен"}]

    const [fetchOrders, isOrdersLoading, ordersError] = useFetching(async () => {
        console.log("fetch orders")
        let orders = await RequestService.getAllOrders(orderFilter);
        console.log(orders)
        setOrders(orders)
    })

    const [fetchShoots, isShootLoading, shootsError] = useFetching(async () => {
        const shoots = await ShootService.getAll();
        console.log(shoots)
        setShoots(shoots)
    })

    useEffect(() => {
        console.log("use effect shoots")
        fetchShoots()
    }, []);


    const statuses = [
        {code: "NEW", name: "Новый"},
        {code: "IN_PROGRESS", name: "В работе"},
        {code: "CANCELED", name: "Отменен"},
        {code: "CREATED_ORDER", name: "Создан заказ"}]

    useEffect(() => {
        if (!filterModal) {
            console.log("use effect fetch orders")
            fetchOrders()
        }
    }, [orderFilter]);

    const apiUrl = process.env.REACT_APP_API_URL;


    function changeOrderStatus(id, statusEn) {
        axios.post(
            apiUrl + 'orders/set_work_status',
            {
                orderId: id,
                workOrderStatusEnum: statusEn
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchOrders()
            })
            .catch(err => {
                console.log(err);
            })
    }


    function reciveGoods(orderId) {
        axios.post(
            apiUrl + 'orders/recieve_goods',
            {
                orderId: orderId
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchOrders()
            })
            .catch(err => {
                console.log(err);
            })
    }

    function createOrder() {
        axios.post(
            apiUrl + 'orders/create',
            {},
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                fetchOrders();
            })
            .catch(err => {
                console.log(err);
            })
    }

    function changeClient(clientId) {
        axios.post(
            apiUrl + 'orders/set_client',
            {
                orderId: editOrderId,
                clientId: clientId
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                // Поменять клиента
                setModalVisible(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div style={{minHeight: "90vh"}}>
            <ClientsModalForm visible={modalVisible} setVisible={setModalVisible} changeClient={changeClient}/>
            <UniversalFilter
                onApply={onApplyFilterModal}
                clearFilter={clearFilter}
                mainFilter={orderFilter}
                setMainFilter={setOrderFilter}
                setVisibleModal={setFilterModal}
                visibleModal={filterModal}
            />
            {ordersError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isOrdersLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '70px'}}><Loader/></div>
                :
                <div className={cl.main_conteiner}>
                    <div>Заказы</div>
                    <div className="horizont_left_conteiner">
                        <Button size="small" variant="outlined" onClick={createOrder}>Создать заказ</Button>
                    </div>
                    <div className={cl.orders_conteiner}>
                        {orders.map((order) => (
                            <OrderItem
                                fetchOrders={fetchOrders}
                                setEditOrderId={setEditOrderId}
                                setModalVisible={setModalVisible}
                                order={order}
                                key={order.id}
                                shoots={shoots}
                                workStatuses={workStatuses}
                                changeOrderStatus={changeOrderStatus}
                                reciveGoods={reciveGoods}
                            />))}
                    </div>
                </div>
            }
        </div>
    );
};

export default AdminOrder;