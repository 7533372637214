import React from 'react';
import classes from './MyModal90.module.css'

const MyModal90 = ({children, visible, setVisible}) => {

    const rootClasses = [classes.myModal]

    if (visible) {
        rootClasses.push(classes.active)
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            {visible &&
                <div className={classes.myModalContent} onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            }
        </div>
    );
};

export default MyModal90;