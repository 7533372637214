import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import RequestService from "../API/RequestService";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import OrderItem from "../UI/list/OrderItem";
import MyModal90 from "../UI/MyModal/MyModal90";
import ClientsModalForm from "../Forms/ClientsModalForm";
import {Input, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MyModal from "../UI/MyModal/MyModal";
import TarifService from "../API/TarifService";
import LocationsService from "../API/LocationService";
import ModelForm from "../Forms/ModelForm";
import FileUpload from "../UI/Loader/FileUpload";


const AdminLocation = () => {


        const locationType =
            [
                {code: "STANDART", name: "Студийная"},
                {code: "STREET", name: "Street"},
                {code: "PINTEREST", name: "PINTEREST"},
                {code: "CHILDREN", name: "Детская"},
                {code: "OBJECT", name: "Предметная"}
            ]


        const [locations, setLocations] = useState([]);
        const [files, setFiles] = useState([]);
        const [backModal, setBackModal] = useState(false);
        const [locationModal, setLocationModal] = useState(false);
        const [roomModal, setRoomModal] = useState(false);


        const [editBack, setEditBack] = useState({backName: ''});
        const [editLoc, setEditLoc] = useState({name: '', shootType: 'STANDART'});
        const [editRoom, setEditRoom] = useState({roomName: ''});

        function clearEditLoc() {
            setEditLoc({name: '', shootType: 'STANDART'})
        }

        const [fetchLocations, isLocationsLoading, locationsError] = useFetching(async () => {
            const locations = await LocationsService.getAllLocations();
            console.log(locations)
            setLocations(locations)
        })

        useEffect(() => {
            fetchLocations()
        }, []);


        const apiUrl = process.env.REACT_APP_API_URL;

        function saveBack() {
            axios.post(
                apiUrl + 'backgrounds/add',
                {
                    ...editBack, imageCollection: {
                        ...editBack.imageCollection,
                        collection: [...files],
                        id: editBack.imageCollection ? editBack.imageCollection.id : null
                    }
                }
                ,
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            )
                .then(res => {
                    console.log(res.data);
                    setBackModal(false)
                    setFiles([])
                    fetchLocations()
                })
                .catch(err => {
                    console.log(err);
                })
        }

        function saveLoc() {
            axios.post(
                apiUrl + 'locations/add',
                editLoc
                ,
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            )
                .then(res => {
                    console.log(res.data);
                    setLocationModal(false)
                    fetchLocations()
                })
                .catch(err => {
                    console.log(err);
                })
        }

        function saveRoom() {
            axios.post(
                apiUrl + 'rooms/add',
                editRoom
                ,
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            )
                .then(res => {
                    console.log(res.data);
                    setRoomModal(false)
                    fetchLocations()
                })
                .catch(err => {
                    console.log(err);
                })
        }

        return (
            <div style={{minHeight: "90vh"}}>
                <MyModal90 visible={backModal} setVisible={setBackModal}>
                    <TextField
                        required
                        id="outlined-required1"
                        label="Название фона"
                        multiline
                        value={editBack.backName}
                        onChange={e => setEditBack({...editBack, backName: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <FileUpload files={files} setFiles={setFiles}/>
                    <Button onClick={saveBack}>Сохранить</Button>
                </MyModal90>
                <MyModal90 visible={roomModal} setVisible={setRoomModal}>
                    <TextField
                        required
                        id="outlined-required1"
                        label="Название зала"
                        multiline
                        value={editRoom.roomName}
                        onChange={e => setEditRoom({...editRoom, roomName: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <Button onClick={saveRoom}>Сохранить</Button>
                </MyModal90>
                <MyModal90 visible={locationModal} setVisible={setLocationModal}>
                    <TextField
                        required
                        id="outlined-required1"
                        label="Название локации"
                        multiline
                        value={editLoc.name}
                        onChange={e => setEditLoc({...editLoc, name: e.target.value})}
                        margin="dense"
                        InputLabelProps={{shrink: true}}
                    />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Тип локации"
                        onChange={(e) => {
                            setEditLoc({...editLoc, shootType: e.target.value})
                        }}
                        value={editLoc.shootType}
                    >
                        {locationType.map((type) => (
                            <MenuItem key={type.id} value={type.code}>{type.name}</MenuItem>
                        ))
                        }
                    </Select>
                    <Button onClick={saveLoc}>Сохранить</Button>
                </MyModal90>

                {locationsError &&
                    <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
                {isLocationsLoading
                    ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '70px'}}><Loader/></div>
                    :
                    <div className={cl.main_conteiner}>
                        <h3>Локации</h3>
                        <div className="horizont_left_conteiner"><Button onClick={(e) => {
                            clearEditLoc();
                            setLocationModal(true);
                        }}>Добавить локацию</Button></div>
                        {locations.map((loc) => (
                            <div key={loc.id} className="vertical_left_conteiner_bordered"
                                 style={{borderColor: "black", cursor: "pointer"}}>
                                <div onClick={(e) => {
                                    setEditLoc(loc)
                                    setLocationModal(true);
                                }}>{loc.name}</div>
                                <div className="horizont_left_conteiner"><Button onClick={(e) => {
                                    setEditRoom({roomName: "", locationId: loc.id})
                                    setRoomModal(true);
                                }}>Добавить зал</Button></div>
                                {
                                    loc.rooms.map((room) => (
                                        <div key={room.id} className="vertical_left_conteiner_bordered"
                                             style={{margin: "2px", width: "auto"}}>
                                            <div onClick={(e) => {
                                                setEditRoom(room)
                                                setRoomModal(true);
                                            }}>{room.roomName}</div>
                                            <div className="horizont_left_conteiner">
                                                <Button onClick={(e) => {
                                                    setFiles([])
                                                    setEditBack({backName: "", roomId: room.id})
                                                    setBackModal(true)
                                                }}>Добавить фон</Button>
                                            </div>
                                            {room.backgrounds.map((background) => (
                                                <div key={background.id} className="vertical_left_conteiner_bordered"
                                                     style={{
                                                         cursor: "pointer",
                                                         width: "auto",
                                                         borderColor: "red",
                                                         margin: "2px"
                                                     }}
                                                     onClick={(e) => {
                                                         setFiles(background.imageCollection ? background.imageCollection.collection : [])
                                                         setEditBack(background)
                                                         setBackModal(true)
                                                     }}>

                                                    <div>Фон {background.backName}</div>
                                                    <div className="horizont_left_conteiner">
                                                        {background.imageCollection.collection.map((image) => (
                                                            <div key={image.path} className="vertical_conteiner">
                                                                <img className="example_img"
                                                                     src={`/images/small/${image.path}`}
                                                                     alt={image.alt}/>
                                                                <div>{image.alt}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        }
                    </div>
                }
            </div>
        );
    }
;

export default AdminLocation;