import axios from "axios";

export default class ShootService {
    static async getAll() {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'open/shoots/list')
        return responseModel.data
    }

    static async getFilteredShoots(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'shoots/list',
            filter,
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getAllShootTz() {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'admin/actual_grouped_tz',
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getFilteredShootTz(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'admin/actual_grouped_tz',
            filter,
            {
                withCredentials: true
            })
        return responseModel.data
    }


    static async getAllShootTzByDay(day) {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'admin/actual_shoot_tz/'+day,
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getDay(id) {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + `open/shoots/${id}`)
        return responseModel.data
    }

    static async getDays(id) {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + `open/models/${id}/shoots`)
        return responseModel.data
    }
}