import React, {useContext, useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import Loader from "../UI/Loader/Loader";
import axios from "axios";
import cl from "./AdminOrder.module.css";
import MyModal90 from "../UI/MyModal/MyModal90";
import {
    MenuItem, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import CostService from "../API/CostService";


const AdminTarif = () => {

    const [patterns, setPatterns] = useState([]);
    const [editRow, setEditRow] = useState({name: '', costType: "CONTINUOUS", base: 0});
    const [editModal, setEditModal] = useState(false);


    const [fetchCostPatterns, isCostPatternsLoading, costPatternsError] = useFetching(async () => {
        const costPatterns = await CostService.getAllCostPatterns();
        console.log(costPatterns)
        setPatterns(costPatterns)
    })

    useEffect(() => {
        fetchCostPatterns()
    }, []);

    const COST_TYPE =
        [
            {code: "CONTINUOUS", name: "Постоянные"},
            {code: "VARIABLES", name: "Переменные"},
            {code: "ONE_TIME", name: "Единоразовые"}
        ]

    const apiUrl = process.env.REACT_APP_API_URL;

    function savePattern() {
        axios.post(
            apiUrl + 'cost_patterns/add',
            editRow
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setEditModal(false)
                fetchCostPatterns()
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div style={{minHeight: "90vh"}}>
            <MyModal90 visible={editModal} setVisible={setEditModal}>
                <div className="vertical_left_conteiner_bordered"
                     style={{overflowY: "auto", height: "auto", maxHeight: "70vh"}}>
                    <div className="vertical_left_conteiner">
                        <TextField
                            required
                            label="Название"
                            size="small"
                            value={editRow.name}
                            onChange={e => setEditRow({...editRow, name: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            required
                            type="number"
                            label="базовая цена"
                            size="small"
                            value={editRow.base}
                            onChange={e => setEditRow({...editRow, base: e.target.value})}
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                        />
                        <Select
                            labelId="demo-simple-select-label11"
                            id="demo-simple-select"
                            label="Типы товара для фотосъемки"
                            size="small"
                            onChange={e => setEditRow({...editRow, costType: e.target.value})}
                            value={editRow.costType}
                            sx={{minWidth: "150px", marginTop: "5px"}}
                        >
                            {COST_TYPE.map((option) => (
                                <MenuItem key={option.id} value={option.code}>{option.name}</MenuItem>
                            ))
                            }
                        </Select>
                    </div>
                    <Button onClick={savePattern}>Сохранить</Button>
                </div>
            </MyModal90>
            {costPatternsError &&
                <h3>Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isCostPatternsLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '70px'}}><Loader/></div>
                :
                <div className={cl.main_conteiner}>
                    <h3>Шаблон расходов</h3>
                    <Button onClick={(e) => {
                        setEditRow({name: "", base: 0, costType: "CONTINUOUS"})
                        setEditModal(true)
                    }}>Добавить шаблон</Button>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{padding: "2px"}}>Название</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Базовая стоимость</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Тип</TableCell>
                                    <TableCell sx={{padding: "2px"}}>Ред</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {patterns.map((pattern) => (
                                    <TableRow key={pattern.id}>
                                        <TableCell sx={{padding: "2px"}}>{pattern.name}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>{pattern.base}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>{pattern.costTypeName}</TableCell>
                                        <TableCell sx={{padding: "2px"}}>
                                            <Button
                                                sx={{padding : "0px"}}
                                                size = "small"
                                                onClick={(e) => {
                                                setEditRow(pattern)
                                                setEditModal(true)
                                            }}>Ред</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    );
};

export default AdminTarif;