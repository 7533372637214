import React from 'react';
import cl from './RelativeFileLoader.module.css'

const RelativeFileLoader = () => {
    return (
        <div className={cl.loader}>
            
        </div>
    );
};

export default RelativeFileLoader;