import "../../../styles/App.css"
import Button from "@mui/material/Button";
import axios from "axios";

const ShootTzItem = (props) => {
        const apiUrl = process.env.REACT_APP_API_URL;

    const statuses = {
        PLANNING: "white",
        REQUESTED: "lightblue",
        DISCUSSION_BOOK: "lightblue",
        BOOKED: "lightgreen",
        REQUESTED_CANCEL: "lightyellow",
        DISCUSSION_CANCEL: "lightyellow",
        CANCELED_BY_ME: "lightsalmon",
        CANCELED_BY_MODEL: "lightcoral",
    }

        return (
            <div className="shoot_item" onClick={(e) => props.onClickDiv(props.shoot.id)}>
                <h4><strong>{props.shoot.dayOfWeek} </strong> {props.shoot.date} <strong>{props.shoot.sum}р</strong></h4>
                {props.shoot.bookings.sort(function (a, b) {
                    return a.planTimeLong - b.planTimeLong || a.modelPerson.name.localeCompare(b.modelPerson.name) || a.modelPerson.id - b.modelPerson.id;
                }).map((booking) =>
                    <div style={{
                        alignItems: "start",
                        backgroundColor: statuses[booking.bookingStatus] ? statuses[booking.bookingStatus] : "white"
                    }}
                         className="model_mini_logo"
                         key={booking.id}>
                        <div>
                            {booking.modelPerson.name}
                        </div>
                        <div className="horizont_left_conteiner">
                            <img style={{borderRadius: '8px', maxWidth: 'auto', maxHeight: '60px', cursor: 'pointer'}}
                                 src={`/images/medium/${booking.modelPerson.photo}`}
                                 alt={'Фото ' + booking.modelPerson.name}
                                // onClick={(e)=>props.onClickImg()}
                            />
                            <div>
                                {booking.sumCost == 0 && booking.quantSum == 0
                                    ? <div>
                                        Нет заказов
                                    </div>
                                    : <div>
                                        <div>сумма {booking.sumCost}</div>
                                        <div>кол-во {booking.quantSum}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        )
            ;
    }
;

export default ShootTzItem;