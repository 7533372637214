import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import cl from './ShowModel.module.css'
import Loader from "../UI/Loader/Loader";
import {useFetching} from "../hooks/useFetching";
import ModelService from "../API/ModelService";
import TarifService from "../API/TarifService";
import TarifDivForOrder from "../Forms/TarifDivForOrder";
import ShootService from "../API/ShootService";
import MyModal from "../UI/MyModal/MyModal";
import ResultForm from "../Forms/ResultForm";


import {
    Accordion,
    AccordionDetails,
    AccordionSummary, ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";
import {Helmet} from "react-helmet-async";
import {AuthContext} from "../context";
import TagModel from "../UI/block/TagModel";
import BlockOfDaysModel from "../UI/block/BlockOfDaysModel";
import ChangeBackFromDay from "../UI/block/ChangeBackFromDay";


const ShowModel = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const params = useParams();

    const {
        theme,
        setModalCollectionVisible,
        setImageCollection,
        setImageCollectionWithSignature
    } = useContext(AuthContext)

    const [model, setModel] = useState({
        id: 1,
        name: 'Модель'
    });
    const [day, setDay] = useState({id: 0});
    const [days, setDays] = useState([]);
    const [photoTypes, setPhotoTypes] = useState([]);
    const [allPhotoTypeTarifs, setAllPhotoTypeTarifs] = useState([]);
    const [backId, setBackId] = useState(0)

    const [managers, setManagers] = useState([]);


    const [structureRequest, setStructureRequest] = useState({})
    const [resultCost, setResultCost] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalThanksVisible, setModalThanksVisible] = useState(false);


    const [fetchModel, isModelLoading, modelError] = useFetching(async () => {
        const model = await ModelService.getModel(params.id);
        console.log(model)
        setModel(model)
        setImageCollection(model.imageCollection)
    })

    const [fetchDay, isDayLoading, dayError] = useFetching(async () => {
        const days = await ShootService.getDays(params.id);
        console.log(days)
        setDays(days)
        if (params.dayid) {
            if (days.find(item => item.id == params.dayid).id) {
                setDay(days.find(item => item.id == params.dayid))
            }
        }
    })

    const [fetchTarifs, isTarifsLoading, tarifsError] = useFetching(async () => {

            const photoTypes = await TarifService.getAllGoodsTypes();
            const photoTarifs = await TarifService.getAllTarifsByModel(params.id, params.dayid);
            const managers = await TarifService.getAllManagers();
            console.log(photoTypes)
            console.log(photoTarifs)
            console.log(managers)

            setPhotoTypes(photoTypes)
            setAllPhotoTypeTarifs(photoTarifs)
            setManagers(managers)

    })

    useEffect(() => {
        var sum = 0;
        console.log(structureRequest);
        for (var key in structureRequest) {
            sum += structureRequest[key].cost;
        }
        setResultCost(sum)
    }, [structureRequest])

    useEffect(() => {
        console.log("fetch tarifs")
        fetchTarifs()
    }, [])

    useEffect(() => {
        console.log("fetch model")
        fetchModel()
    }, [])

    useEffect(() => {
        console.log("get days")
        fetchDay()
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <div className={cl.main__container}>
                <Helmet>
                    <title>Модель {model.name} - портфолио, записаться</title>
                    <meta name="description"
                          content={'Модель ' + model.name + ' ближайшие съемки, индивидуальная съемка, портфолио, записаться'}/>
                </Helmet>
                {modelError &&
                    <h3> Произошла ошибка ${modelError}</h3>}
                {isModelLoading
                    ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                    :
                    <div className={cl.main__container_const}>
                        <div className={cl.column_photo_parameters}>
                            <div className={cl.model__card__im_text}>
                                <img className={cl.show_model_image}
                                     src={`/images/medium/${model.photo}`}
                                     alt={model.name}
                                />
                            </div>
                            {model.imageCollection &&
                                <div><Button variant="outlined"
                                             onClick={(e) => {
                                                 setImageCollectionWithSignature(false)
                                                 setModalCollectionVisible(true)
                                                 setImageCollection(model.imageCollection)
                                             }}>Портфолио</Button></div>
                            }
                        </div>
                        <div className={cl.column_constructor}>
                            <div style={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <h1 className={cl.main__name}>{model.name}</h1>
                                <div className={cl.model__parameters}>
                                    <div className={cl.model__parameter}>
                                        Рост {model.height}
                                    </div>
                                    <div className={cl.model__parameter}>
                                        Грудь {model.breastSize}
                                    </div>
                                    <div className={cl.model__parameter}>
                                        Талия {model.waistSize}
                                    </div>
                                    <div className={cl.model__parameter}>
                                        Бедра {model.hipSize}
                                    </div>
                                    {model.shoeSize &&
                                        <div className={cl.model__parameter}>
                                            Обувь {model.shoeSize}
                                        </div>
                                    }
                                    {model.clothingSize &&
                                        <div className={cl.model__parameter}>
                                            Размер одежды {model.clothingSize}
                                        </div>
                                    }
                                </div>
                                <TagModel model={model}/>
                                <BlockOfDaysModel
                                    isDayLoading={isDayLoading}
                                    day={day}
                                    setDay={setDay}
                                    days={days}
                                />
                            </div>
                            {day.id !== 0 &&
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <hr style={{margin: '10px'}}/>
                                    <ChangeBackFromDay
                                        day={day}
                                        backId={backId}
                                        setBackId={setBackId}
                                        setImageCollection={setImageCollection}
                                        setImageCollectionWithSignature={setImageCollectionWithSignature}
                                        setModalCollectionVisible={setModalCollectionVisible}
                                        showExample={true}
                                    />
                                    <hr style={{margin: '10px'}}/>
                                    {photoTypes.length !== 0 &&
                                        (
                                            <div style={{marginBottom: '15px'}}>Типы фотосъемки </div>
                                        )
                                    }
                                    {day.shootingLocation.shootType !== 'STREET'
                                        && photoTypes.filter((code) =>
                                        code.code !== 'LINGERIE' || (model.lingerie)
                                    ).map((but) => (
                                            <Accordion style={{width: '90%'}} key={but.code}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header">
                                                    {but.name}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {allPhotoTypeTarifs.filter((e) => e.tarifType === "PHOTO").filter((e) => but.code === e.goodTypeEnum).map((tar) => (
                                                            <TarifDivForOrder
                                                                tarif={tar}
                                                                key={tar.id}
                                                                modelId={model.id}
                                                                shootId={day.id}
                                                                backId={backId}
                                                                stylish={tar.descStylish}
                                                                posing={tar.descPosing}
                                                                setStructureRequest={setStructureRequest}
                                                                structureRequest={structureRequest}
                                                                ident={but.name + ": " + tar.tarifName}
                                                            ></TarifDivForOrder>

                                                        )
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    )}
                                    {day.shootingLocation.shootType === 'STREET' &&
                                        <Accordion style={{width: '90%'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1-content"
                                                id="panel1-header">
                                                Фотосъемка Street
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {allPhotoTypeTarifs.filter((e) => e.tarifType === "STREET_PHOTO").map((tar) => (
                                                        <TarifDivForOrder
                                                            tarif={tar}
                                                            key={tar.id}
                                                            modelId={model.id}
                                                            shootId={day.id}
                                                            backId={backId}
                                                            stylish={tar.descStylish}
                                                            posing={tar.descPosing}
                                                            setStructureRequest={setStructureRequest}
                                                            structureRequest={structureRequest}
                                                            ident={"Фотосъемка Street: " + tar.tarifName}
                                                        ></TarifDivForOrder>

                                                    )
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    <hr style={{margin: '10px'}}/>
                                    <Accordion style={{width: '90%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1-content"
                                            id="panel1-header">
                                            Видеоcъемка
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {allPhotoTypeTarifs.filter((e) => e.tarifType === "STREET_VIDEO").map((tar) => (
                                                    <TarifDivForOrder
                                                        key={tar.id}
                                                        tarif={tar}
                                                        modelId={model.id}
                                                        shootId={day.id}
                                                        backId={backId}
                                                        setStructureRequest={setStructureRequest}
                                                        structureRequest={structureRequest}
                                                        ident={"Видеоcъемка: " + tar.tarifName}
                                                    ></TarifDivForOrder>
                                                )
                                            )}
                                            {allPhotoTypeTarifs.filter((e) => e.tarifType === "VIDEO").map((tar) => (
                                                    <TarifDivForOrder
                                                        key={tar.id}
                                                        tarif={tar}
                                                        modelId={model.id}
                                                        shootId={day.id}
                                                        backId={backId}
                                                        setStructureRequest={setStructureRequest}
                                                        structureRequest={structureRequest}
                                                        ident={"Видеоcъемка: " + tar.tarifName}
                                                    ></TarifDivForOrder>
                                                )
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion style={{width: '90%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2-content"
                                            id="panel2-header">
                                            Дополнительные опции
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {allPhotoTypeTarifs.filter((e) => e.tarifType === "DOP_OPTION").map((tar) => (
                                                    <TarifDivForOrder key={tar.id}
                                                                      tarif={tar}
                                                                      modelId={model.id}
                                                                      shootId={day.id}
                                                                      backId={backId}
                                                                      setStructureRequest={setStructureRequest}
                                                                      structureRequest={structureRequest}
                                                                      ident={"Дополнительные опции: " + tar.tarifName}
                                                    ></TarifDivForOrder>
                                                )
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion style={{width: '90%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel3-content"
                                            id="panel3-header">
                                            Услуги после съемки
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {allPhotoTypeTarifs.filter((e) => e.tarifType === "AFTER_SHOOT").map((tar) => (
                                                    <TarifDivForOrder key={tar.id}
                                                                      tarif={tar}
                                                                      modelId={model.id}
                                                                      shootId={day.id}
                                                                      backId={backId}
                                                                      setStructureRequest={setStructureRequest}
                                                                      structureRequest={structureRequest}
                                                                      ident={"Услуги после съемки: " + tar.tarifName}
                                                    ></TarifDivForOrder>
                                                )
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }

                            <div className={cl.result__block}>
                                {day.id !== 0 &&
                                    <div style={{fontSize: '20px', marginBottom: '15px'}}>Итого : {resultCost} р.</div>
                                }
                                <Button color="primary" variant="contained" className={cl.buck__button}
                                        onClick={() => setModalVisible(true)}>Записаться</Button>
                                <MyModal visible={modalVisible} setVisible={setModalVisible}>
                                    <ResultForm
                                        typeShoot={day}
                                        resultCost={resultCost}
                                        structureRequest={structureRequest}
                                        day={day}
                                        backId={backId}
                                        model={model}
                                        managers={managers}
                                        setModalVisible={setModalVisible}
                                        setModalThanksVisible={setModalThanksVisible}
                                    />

                                </MyModal>

                                <MyModal visible={modalThanksVisible} setVisible={setModalThanksVisible}>
                                    <div className={cl.thanks_modal_conteiner}>
                                        <div>Спасибо за задание</div>
                                        <div>с Вами скоро свяжется менеджер</div>
                                        <Button variant="outlined"
                                                onClick={(e) => setModalThanksVisible(false)}>OK</Button>
                                    </div>
                                </MyModal>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </ThemeProvider>
    );
};

export default ShowModel;