import React from 'react';
import {MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import MyModal from "../UI/MyModal/MyModal";

const BookingForm = (props) => {

    const bookStatus =
        [
            {code: "PLANNING", name: "Планируется"},
            {code: "REQUESTED", name: "Запрошено подтверждение"},
            {code: "DISCUSSION_BOOK", name: "Переговоры о подтверждение"},
            {code: "BOOKED", name: "Подтверждена"},
            {code: "REQUESTED_CANCEL", name: "Запрошена отмена"},
            {code: "DISCUSSION_CANCEL", name: "Переговоры об отмене"},
            {code: "CANCELED_BY_MODEL", name: "Отменена моделью"},
            {code: "CANCELED_BY_ME", name: "Отменена"}
        ]

    return (
        <MyModal visible={props.visible} setVisible={props.setVisible}>
            {props.editBooking &&
                <div className="vertical_conteiner" style={{margin: "10px"}}>
                    <TextField
                        type='time'
                        variant='standard'
                        size='small'
                        id="outlined-textareaa"
                        label="План. Время на какое бронирование."
                        onChange={(e) => props.setEditBooking({...props.editBooking, planTime: e.target.value})}
                        value={props.editBooking.planTime}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        type='number'
                        variant='standard'
                        size='small'
                        id="outlined-textareaa"
                        label="План. Длительность. ч"
                        onChange={(e) => props.setEditBooking({...props.editBooking, planDuration: e.target.value})}
                        value={props.editBooking.planDuration}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        type='time'
                        variant='standard'
                        size='small'
                        id="outlined-textareaa"
                        label="Факт. Время во сколько приехала."
                        onChange={(e) => props.setEditBooking({...props.editBooking, factTime: e.target.value})}
                        value={props.editBooking.factTime}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        type='number'
                        variant='standard'
                        size='small'
                        id="outlined-textareaa"
                        label="Факт. Длительность. ч"
                        onChange={(e) => props.setEditBooking({...props.editBooking, factDuration: e.target.value})}
                        value={props.editBooking.factDuration}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        type='text'
                        variant='standard'
                        size='small'
                        id="outlined-textareaa"
                        label="Комментарий"
                        onChange={(e) => props.setEditBooking({...props.editBooking, comment: e.target.value})}
                        value={props.editBooking.comment}
                        fullWidth
                        multiline
                        maxRows={5}
                        InputLabelProps={{shrink: true}}
                    />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Тип локации"
                        fullWidth
                        size="small"
                        onChange={(e) => {
                            props.setEditBooking({...props.editBooking, bookingStatus: e.target.value})
                        }}
                        value={props.editBooking.bookingStatus}
                    >
                        {bookStatus.map((type) => (
                            <MenuItem key={type.code} value={type.code}>{type.name}</MenuItem>
                        ))
                        }
                    </Select>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={(e) => {
                            props.saveTiming()
                        }}>Сохранить</Button>

                </div>
            }
        </MyModal>
    )
        ;
};

export default BookingForm;