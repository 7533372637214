import axios from "axios";

export default class ModelService {

    static async getAllOptimized(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'open/optimized_models/list',
            {filterCollection: filter}
        )
        return responseModel.data
    }

    static async getModelCollection(id) {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'open/model_collection/' + id)
        return responseModel.data
    }

    static async getAll() {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'open/models/list')
        return responseModel.data
    }

    static async getAllFiltered(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'models/list',
            {filterCollection: filter},
            {withCredentials: true}
        )
        return responseModel.data
    }

    static async getAllModelCollections() {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'model_collections/list', {withCredentials: true})
        return responseModel.data
    }

    static async getAllActual() {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'open/actual_models')
        return responseModel.data
    }

    static async getModel(id) {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + `open/models/${id}`)
        return responseModel.data
    }
}