import React, {useState} from 'react';
import MyButtonOrder from "../UI/button/MyButtonOrder";
import axios from "axios";
import {MenuItem, Select} from "@mui/material";

const ShootModelForm = (props) => {

    const [selectedStoot, setSelectedShoot] = useState(0);
    const apiUrl = process.env.REACT_APP_API_URL;

    function shootModel() {

        const params = {
            modelsId: props.model.id,
            shootsId: selectedStoot
        };

        console.log(params)

        axios.post(
            apiUrl + 'booking/add',
            params
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(`Success` + res.data);
            })
            .catch(err => {
                console.log(err);
            })

        props.setShootModal(false)
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <strong>{props.model.name}</strong>
            <div>
                {props.model.fio}
            </div>
            <img style={{borderRadius: '8px', maxWidth: 'auto', maxHeight: '300px'}}
                 src={`/images/medium/${props.model.photo}`}/>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Съемка"
                onChange={(e) => {
                    setSelectedShoot(e.target.value)
                }}
                value={selectedStoot}
            >
                <MenuItem value={0}>Выберите дату</MenuItem>
                {props.shoots.map((option) => (
                    <MenuItem key={option.id} value={option.id}>{option.date}</MenuItem>
                ))
                }
            </Select>
            <MyButtonOrder onClick={shootModel}>Забронировать</MyButtonOrder>
        </div>
    );
};

export default ShootModelForm;