import React, {useContext, useEffect, useState} from 'react';
import cl from './ModelItemMain.module.css'
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import Button from "@mui/material/Button";
import {ThemeProvider} from "@mui/material";
import ImageService from "../../API/ImageService";
import {AuthContext} from "../../context";


const ModelItemMain = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const {
        theme,
        setModalCollectionVisible,
        setImageCollection,
        setImageCollectionWithSignature
    } = useContext(AuthContext)

    const navigate = useNavigate();

    function nav() {
        if (props.model.modelId != null) {
            navigate(`/model/${props.model.modelId}/day/${props.model.dateId}`)
        } else {
            navigate(`/model/${props.model.id}`)
        }
    }


    function deleteNullShoes(shoes) {
        if (shoes != null && shoes !== '') {
            return 'Обувь : ' + shoes
        } else {
            return ''
        }
    }

    function deleteNullClothingSize(shoes) {
        if (shoes != null && shoes !== '') {
            return 'Одежда : ' + shoes
        } else {
            return ''
        }
    }

    function preventDragHandler(e) {
        e.preventDefault();

    }

    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: window.innerWidth > 768
    });

    function handleWindowSizeChange() {
        setSettings({
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: window.innerWidth > 768
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    async function showModal(id) {
        const imageCol = await ImageService.getAllImagesByImageCollection(id);
        console.log(imageCol)
        setImageCollectionWithSignature(false)
        setImageCollection(imageCol)
        setModalCollectionVisible(true)
    }

    return (
        <div className={cl.model__card}>
            <div className={cl.model__card__im_text}>
                {props.model.date &&
                    <strong className={cl.model__card__im_text__text}>{props.model.date}</strong>
                }
                {props.model.imageCollection || (props.model.imageCollectionString && props.model.imageCollectionString.length > 0) ?
                    <Slider className={cl.slider_conteiner} {...settings}>
                        {/*<img className={cl.shoot__list_image}*/}
                        {/*     src={`/images/medium/${props.model.photo}`}*/}
                        {/*     alt={props.model.name}*/}
                        {/*    // onClick={() => nav()}*/}
                        {/*/>*/}
                        {props.model.imageCollectionString && props.model.imageCollectionString.length > 0 &&
                            props.model.imageCollectionString.map((img) => (
                                <div key={img}>
                                    <div style={{
                                        height: "500px",
                                        background: `url('/images/medium/${img}') center no-repeat`,
                                        backgroundSize: 'contain'
                                    }}>
                                        {/*<img key={img}*/}
                                        {/*     className={cl.shoot__list_image}*/}
                                        {/*     src={`/images/medium/${img}`}*/}
                                        {/*     alt={props.model.name}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            ))
                        }
                        {props.model.imageCollection &&
                            props.model.imageCollection.collection.map((img) => (
                                <div key={img.id}
                                     style={{
                                         height: "100%",
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center",
                                         flexDirection: "column"
                                     }}>
                                    <img key={img.id}
                                         className={cl.shoot__list_image}
                                         src={`/images/medium/${img.path}`}
                                         alt={props.model.name}
                                    />
                                </div>
                            ))
                        }
                    </Slider>
                    :
                    <img style={{maxHeight: "525px", maxWidth: "100%"}}
                         src={`/images/medium/${props.model.photo}`}
                         alt={props.model.name}
                         onClick={() => nav()}
                         onDragStart={preventDragHandler}
                    />
                }
                {(props.model.lingerie || (props.model.clothes1Hour + props.model.selfAssembly) > 5999 || props.model.plusSize || (props.model.imageCollection && props.model.imageCollection.collection.length > 0)) &&
                    <div className={cl.model__card__im_text__icons}>
                        {props.model.lingerie ?
                            props.model.male ?
                                <div className={cl.model__card__im_text__icon_wrap}>
                                    <img className={cl.model__card__im_text__icon}
                                         src={`/icons/male_ling.png`}
                                         alt='Снимается в нижнем белье'
                                         title='Снимается в нижнем белье'
                                    ></img>
                                </div>
                                :
                                <div className={cl.model__card__im_text__icon_wrap}>
                                    <img className={cl.model__card__im_text__icon}
                                         src={`/icons/female_ling.png`}
                                         alt='Снимается в нижнем белье'
                                         title='Снимается в нижнем белье'
                                    ></img>
                                </div>
                            : null
                        }
                        {(props.model.clothes1Hour + props.model.selfAssembly) > 5999 &&
                            <div className={cl.model__card__im_text__icon_wrap}>
                                <img className={cl.model__card__im_text__icon}
                                     src={`/icons/dollar.png`}
                                     alt='Повышенная цена'
                                     title='TOP модель. Повышенная цена'
                                ></img>
                            </div>
                        }
                        {(props.model.plusSize) &&
                            <div className={cl.model__card__im_text__icon_wrap}>
                                <img className={cl.model__card__im_text__icon}
                                     src={`/icons/plus_size.svg`}
                                     alt='Модель +Size'
                                     title='Модель +Size'
                                ></img>
                            </div>
                        }
                        {(props.model.imageCollection) && (props.model.imageCollection.collection.length > 0) &&
                            <div className={cl.model__card__im_text__icon_wrap}>
                                <img className={cl.model__card__im_text__icon_swipe}
                                     src={`/icons/swipe.png`}
                                     alt='Коллекция фото'
                                     title='Можете просмотреть фото с помощью свайпа'
                                ></img>
                            </div>
                        }
                    </div>
                }
            </div>
            <div className={cl.model_content_order}>
                <div className={cl.model_content}>
                    <div
                        className={cl.model__content__name}
                    >  {props.model.modelId
                        ? <Link className={cl.link_to_model}
                                to={/model/ + props.model.modelId + /day/ + props.model.dateId}>{props.model.name + ' ' + props.model.breastSize + '/' + props.model.waistSize + '/' + props.model.hipSize}</Link>
                        : <Link className={cl.link_to_model}
                                to={"/model/" + props.model.id}>{props.model.name + ' ' + props.model.breastSize + '/' + props.model.waistSize + '/' + props.model.hipSize}</Link>
                    }
                    </div>
                    <div className={cl.model__content__parameters}>
                        <div>
                            {'Рост : ' + props.model.height}
                        </div>
                        <div>
                            {deleteNullShoes(props.model.shoeSize)}
                        </div>
                        <div>
                            {deleteNullClothingSize(props.model.clothingSize)}
                        </div>
                    </div>
                </div>
                <ThemeProvider theme={theme}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
                        {props.model.imageCollectionId &&
                            <div style={{padding: "2px"}}><Button variant="outlined"
                                                                  onClick={(e) => {
                                                                      showModal(props.model.imageCollectionId)
                                                                  }}>Портфолио</Button>
                            </div>
                        }
                        {props.model.linkToPortpholio &&
                            <div style={{padding: "2px"}}><a target="_blank" rel="noopener noreferrer"
                                                             href={props.model.linkToPortpholio}
                                                             target="_blank">Полное портфолио</a>
                            </div>
                        }
                        <div style={{padding: "2px"}}><Button variant="outlined"
                                                              onClick={() => nav()}>Забронировать</Button></div>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    );
};

export default ModelItemMain;