import React from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {Link, useNavigate} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

const DrawerList = (props) => {

    const navigate = useNavigate();


    return (
        <Box sx={{width: 250}} role="presentation" onClick={(e) => props.setOpen(false)}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => (navigate(`/admin/shootings`))}>
                        Планирование
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => (navigate(`/admin/bookings`))}>
                        Букинг
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => (navigate(`/admin/model_collection`))}>
                        Подборка моделей
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => (navigate(`/admin/shoot`))}>
                        Съемка
                    </ListItemButton>
                </ListItem>
                {(props.role === "MANAGER" || props.role === "ADMIN") &&
                    [
                        <hr/>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/requests`))}>
                                Заявки
                            </ListItemButton>
                        </ListItem>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/orders`))}>
                                Заказы
                            </ListItemButton>
                        </ListItem>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/clients`))}>
                                Клиенты
                            </ListItemButton>
                        </ListItem>,
                        <hr/>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/tarifs`))}>
                                Тарифы
                            </ListItemButton>
                        </ListItem>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/locations`))}>
                                Локации
                            </ListItemButton>
                        </ListItem>
                    ]}
                {(props.role === "ADMIN") &&
                    [
                        <hr/>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/cost_pattern`))}>
                                Шаблон расходов
                            </ListItemButton>
                        </ListItem>,
                        <ListItem disablePadding>
                            <ListItemButton onClick={(e) => (navigate(`/admin/finances`))}>
                                Финансы
                            </ListItemButton>
                        </ListItem>
                    ]
                }
            </List>
        </Box>
    );
};

export default DrawerList;