import React from 'react';
import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import MyModal from "../UI/MyModal/MyModal";
import MyModal90 from "../UI/MyModal/MyModal90";

const UniversalFilter = (props) => {

    const mobile = window.innerWidth < 480

    return (
        <div>
            <MyModal90 visible={props.visibleModal} setVisible={props.setVisibleModal}>
                {props.mainFilter.map((filter, index) => (
                    <div key={index} className="horizont_left_conteiner" style={{margin: "3px"}}>
                        <Switch
                            checked={filter.checked}
                            onChange={(e) => props.setMainFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== filter.code ? el : {
                                ...el,
                                checked: e.target.checked
                            }))}
                        />
                        {filter.type === "checkbox" &&
                            <FormControlLabel sx={{minWidth: "100px"}} control={
                                <Checkbox
                                    sx={{minWidth: "100px"}}
                                    size='small'
                                    checked={filter.value}
                                    onChange={(e) => props.setMainFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== filter.code ? el : {
                                        ...el,
                                        value: e.target.checked,
                                        valueForChip: e.target.checked ? el.valueForChipTrue : el.valueForChipFalse,
                                        checked: true
                                    }))}
                                    inputProps={{'aria-label': 'controlled'}}
                                    label={filter.label}
                                />
                            } label={filter.label}/>
                        }
                        {filter.type === "select" &&
                            <FormControl sx={{margin: '4px'}}>
                                <InputLabel id="demo-simple-select-label">{filter.label}</InputLabel>
                                <Select
                                    sx={{minWidth: "200px"}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size='small'
                                    value={filter.value}
                                    label={filter.label}
                                    onChange={(e) => props.setMainFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== filter.code ? el : {
                                        ...el,
                                        value: e.target.value,
                                        valueForChip: filter.values.find((sel) => sel.value === e.target.value).name,
                                        checked: true
                                    }))}
                                >
                                    {filter.values.map((val, index) => (
                                        <MenuItem value={val.value} key={val.value}>{val.name}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                        }
                        {(filter.type === "text" || filter.type === "number" || filter.type === "date") &&
                            <TextField
                                sx={{minWidth: "100px"}}
                                // variant='standart'
                                type={filter.type}
                                size='small'
                                id="outlined-textareaa"
                                label={filter.label}
                                onChange={(e) => props.setMainFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== filter.code ? el : {
                                    ...el,
                                    value: e.target.value,
                                    valueForChip: e.target.value,
                                    checked: true
                                }))
                                }
                                value={filter.value}
                                InputLabelProps={{shrink: true}}
                            />
                        }
                        {(filter.type === "sort") &&
                            <FormControl sx={{margin: '4px'}}>
                                <InputLabel id="demo-simple-select-label">{filter.label}</InputLabel>
                                <Select
                                    sx={{minWidth: "200px"}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size='small'
                                    value={filter.value}
                                    label={filter.label}
                                    onChange={(e) => props.setMainFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== filter.code ? el : {
                                        ...el,
                                        value: e.target.value,
                                        valueForChip: filter.values.find((sel) => sel.value === e.target.value).name,
                                        checked: true
                                    }))}
                                >
                                    {filter.values.map((val, index) => (
                                        <MenuItem value={val.value} key={val.value}>{val.name}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                        }
                    </div>
                ))}
                <Button variant="contained" onClick={props.onApply}>Применить</Button>
            </MyModal90>
            <div className={mobile ? "vertical_left_conteiner" : "horizont_left_conteiner"}>
                <div className="horizont_left_conteiner">
                    <Button sx={{marginLeft: "5px"}} size="small" variant="outlined"
                            onClick={(e) => props.setVisibleModal(true)}>
                        Фильтр
                    </Button>
                    {props.mainFilter.filter((e) => e.checked).length > 0 &&
                        <Button sx={{marginLeft: "5px"}} size="small" variant="outlined" onClick={props.clearFilter}>
                            Сбросить всё
                        </Button>
                    }
                </div>
                <Stack sx={{marginLeft: "5px", marginTop: mobile ? "5px" : "0"}} direction={mobile ? "column" : "row"}
                       spacing={1}>
                    {props.mainFilter.filter((e) => e.checked).map((filter) => (
                        <Chip
                            key={filter.code}
                            label={filter.type === "checkbox" ? filter.valueForChip : filter.label + " : " + filter.valueForChip}
                            variant="outlined"
                            onDelete={(e) => {
                                props.setMainFilter((oldModelFilter) => oldModelFilter.map((el) => el.code !== filter.code ? el : {
                                    ...el,
                                    checked: false
                                }))
                                props.onApply()
                            }}
                        />
                    ))}
                </Stack>
            </div>
        </div>
    );
};

export default UniversalFilter;