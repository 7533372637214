import React, {useContext, useState} from 'react';
import cl from "../../Pages/AdminOrder.module.css";
import Button from "@mui/material/Button";
import {
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import OrderAccordion from "../CollapsiveDiv/OrderAccordion";
import ChangePayedStatusForm from "../../Forms/ChangePayedStatusForm";
import MyModal from "../MyModal/MyModal";
import axios from "axios";
import {AuthContext} from "../../context";

const OrderItem = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const {
        startClientFilter, clientFilter, setClientFilter
    } = useContext(AuthContext)

    const [order, setOrder] = useState(props.order)
    const [payStatusModal, setPayStatusModal] = useState(false);
    const [needToPay, setNeedToPay] = useState(0);
    const [payed, setPayed] = useState(0)

    function changePayStatus(payed) {
        axios.post(
            apiUrl + 'orders/change_pay_status',
            {
                orderId: order.id,
                payed: payed
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setOrder({
                    ...order,
                    needToPay: res.data.needToPay,
                    payStatusName: res.data.payStatusName,
                    payStatusEnum: res.data.payStatusEnum,
                    payd: res.data.payd,
                    datePayed: res.data.datePayed
                });
                setPayStatusModal(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    function saveOrderComments() {
        axios.post(
            apiUrl + 'orders/set_comments',
            {
                orderId: order.id,
                clientComment: order.clientComment,
                managerComment: order.managerComment
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function sendFile(file) {
        if (file !== null) {
            let formData = new FormData();
            formData.append('file', file.file);
            axios.post(
                apiUrl + 'admin/upload_file',
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                    }, withCredentials: true
                }
            )
                .then(res => {
                    console.log(`Success` + res.data);
                    changeCheck(res.data)
                })
                .catch(err => {
                    console.log(err);
                })

        }
    }

    function changeCheck(linkToCheck) {

        axios.post(
            apiUrl + 'orders/set_check',
            {
                orderId: order.id,
                linkToCheck: linkToCheck
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setOrder({
                    ...order,
                    linkToCheck: res.data.linkToCheck
                });
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleChangeFile = (e) => {
        e.preventDefault()
        console.log("UPLOAD FILE")

        const newFile = {
            id: Math.random(),
            file: e.target.files[0]
        }
        sendFile(newFile)
    };

    const downloadFile = async () => {
        try {
            const response = await axios.get(apiUrl + 'admin/download/' + order.linkToCheck, {
                responseType: 'blob',
                withCredentials: true
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', order.linkToCheck);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            alert("Error downloading file");
        }
    }

    return (
        <div className={cl.order}>
            <MyModal visible={payStatusModal} setVisible={setPayStatusModal}>
                <ChangePayedStatusForm payed={payed} setPayed={setPayed} needToPay={needToPay}
                                       changePayStatus={changePayStatus}/>
            </MyModal>
            <div className={cl.main_order}>
                <div className={cl.order_param} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="vertical_left_conteiner">
                        <div className="div_strong">№ {order.id}</div>
                        <div style={{textWrap: "nowrap"}}>От {order.created}</div>
                        <Button size="small"
                                onClick={(e) => {
                                    if (order.clientId) {
                                        setClientFilter((oldFilter) => oldFilter.map(el => el.code !== "id"
                                            ? {...el, checked: false}
                                            : {
                                                ...el,
                                                checked: true,
                                                value: order.clientId,
                                                valueForChip: order.clientId
                                            }
                                        ).map(el => el.code !== "sortname"
                                            ? {...el}
                                            : {...el, checked: true, value: "desc", valueForChip: "По убыванию"}))
                                    } else {
                                        setClientFilter((oldFilter) => oldFilter.map(el => el.code !== "sortname"
                                            ? {...el, checked: false}
                                            : {...el, checked: true, value: "desc", valueForChip: "По убыванию"}))
                                    }
                                    props.setEditOrderId(order.id)
                                    props.setModalVisible(true)
                                }
                                }>{order.clientName ? order.clientName : "выбрать клиента"}</Button>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', cursor: "pointer"}}
                         onClick={(e) => {
                             setNeedToPay(order.needToPay)
                             setPayed(order.needToPay)
                             setPayStatusModal(true)
                         }}
                         className={order.payStatusName === 'Оплачен' ? cl.class_blue : cl.class_green}>
                        <div>
                            <div>Сумма:{order.needToPay}</div>
                            <div>Оплачено:{order.payd}</div>
                            <div>{order.datePayed}</div>
                            <div>{order.payStatusName}</div>
                        </div>
                    </div>
                    <div className="vertical_left_conteiner"
                         style={{alignItems: "center", padding: "2px"}}>
                        Чек
                        <Button
                            size="small"
                            component="label"
                        >
                            Загрузить
                            <input
                                type="file"
                                hidden
                                onChange={handleChangeFile}
                            />
                        </Button>
                        {order.linkToCheck &&
                            <Button
                                variant="outlined"
                                size="small"
                                component="label"
                                onClick={downloadFile}
                            >
                                Посмотреть
                            </Button>
                        }
                    </div>
                </div>
                <div className={cl.order_param}>
                    <TextField
                        // sx = {{padding: '1px 1px'}}
                        variant='standard'
                        size='small'
                        id="outlined-textareaa"
                        label="Коментарий покупателя"
                        // placeholder="Коментарий покупателя"
                        multiline
                        rows={4}
                        onChange={(e) => setOrder({...order, clientComment: e.target.value})}
                        value={order.clientComment}
                        fullWidth
                    />
                </div>
                <div className={cl.order_param}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="outlined-textarea-manager">Коментарий менеджера</InputLabel>
                        <FilledInput
                            // sx = {{padding: '1px 1px'}}
                            variant='standard'
                            size='small'
                            id="outlined-textarea-manager"
                            label="Коментарий менеджера"
                            multiline
                            rows={4}
                            onChange={(e) => setOrder({...order, managerComment: e.target.value})}
                            value={order.managerComment}
                            fullWidth
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={saveOrderComments}
                                        edge="end"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                             width="24px" fill="#5f6368">
                                            <path d="M0 0h24v24H0V0z" fill="none"/>
                                            <path
                                                d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"/>
                                        </svg>
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div className={cl.order_param}>
                    <div className="horizont_left_conteiner">
                    <Select
                        variant='standard'
                        id="demo-simple-select"
                        value={order.workOrderStatusEnum}
                        onChange={(e) => {
                            props.changeOrderStatus(order.id, e.target.value)
                        }}
                    >
                        {props.workStatuses.map((stat) => (
                            <MenuItem value={stat.code}
                                      key={stat.code}>{stat.name}</MenuItem>
                        ))
                        }
                    </Select>
                    {order.dateRecivedGoods
                        ? <div>Товар получен {order.dateRecivedGoods}</div>
                        : <Button size='small'
                                  sx={{backgroundColor: "lightgreen"}}
                                  onClick={(e) => {
                                      props.reciveGoods(order.id)
                                  }}>Получить товар</Button>
                    }
                    </div>
                </div>
                <div className={cl.order_param}>{order.managerName} </div>
            </div>
            <OrderAccordion orderId={order.id} setOrder={setOrder} shoots={props.shoots} fetchOrders={props.fetchOrders}/>
        </div>
    );
};

export default OrderItem;