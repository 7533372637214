import axios from "axios";

export default class RequestService {

    static async getAllRequests(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'requests/list',
            filter,
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getAllOrders(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'orders/list',
            {filterCollection: filter},
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getAllClients() {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'clients/list',
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getAllFilteredClients(filter) {
        const responseModel = await axios.post(process.env.REACT_APP_API_URL + 'clients/list',
            {filterCollection: filter},
            {
                withCredentials: true
            })
        return responseModel.data
    }

    static async getOrderRows(id) {
        const responseModel = await axios.get(process.env.REACT_APP_API_URL + 'orders/'+id+'/rows',
            {
                withCredentials: true
            })
        return responseModel.data
    }



}