import React, {useContext, useEffect, useState} from 'react';
import MyModal from "../UI/MyModal/MyModal";
import ModelForm from "../Forms/ModelForm";
import ShootCreateForm from "../Forms/ShootCreateForm";
import Loader from "../UI/Loader/Loader";
import ShootList from "../UI/list/ShootList";
import PostFilter from "../PostFilter";
import ModelList from "../UI/list/ModelList";

import {usePosts} from "../hooks/MyHooks";
import {useFetching} from "../hooks/useFetching";
import ModelService from "../API/ModelService";
import ShootService from "../API/ShootService";
import "../../styles/App.css"
import Button from "@mui/material/Button";
import {Checkbox, FormControlLabel, FormGroup, MenuItem, Select, Switch, TextField} from "@mui/material";
import ShootTzItem from "../UI/list/ShootTzItem";
import {AuthContext} from "../context";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import cl from "./AdminShoot.module.css"
import classes from "../UI/MyModal/MyModal.module.css";
import BookingForm from "../Forms/BookingForm";
import ShowModel from "./ShowModel";
import ModelCard from "../Forms/ModelCard";

const AdminShoot = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const {
        theme,
        setModalCollectionVisible,
        setImageCollection,
        setImageCollectionWithSignature,
        setOrderFilter
    } = useContext(AuthContext)

    const navigate = useNavigate();


    const [modalResultVisible, setModalResultVisible] = useState(false)
    const [modalCommentVisible, setModalCommentVisible] = useState(false)
    const [modalBookingVisible, setModalBookingVisible] = useState(false)
    const [showModelModal, setShowModelModal] = useState(false)


    const [editResult, setEditResult] = useState("")
    const [editComment, setEditComment] = useState("")

    const [editOrderId, setEditOrderId] = useState(0)
    const [editModelId, setEditModelId] = useState(0)
    const [showModel, setShowModel] = useState({})
    const [editOrderRowId, setEditOrderRowId] = useState(0)
    const [editBooking, setEditBooking] = useState({})

    const [shoots, setShoots] = useState([])
    const [shootTz, setShootTz] = useState()
    const [selectedDay, setSelectedDay] = useState(0)

    const [filter, setFilter] = useState({date: ''})
    const [filterModal, setFilterModal] = useState(false);


    const [fetchShoots, isShootLoading, shootsError] = useFetching(async () => {
        const shoots = await ShootService.getFilteredShootTz(filter);
        console.log(shoots)
        setShoots(shoots)
    })

    const [fetchShootTz, isShootTzLoading, shootTzError] = useFetching(async () => {
        const shoot = await ShootService.getAllShootTzByDay(selectedDay);
        console.log(shoot)
        setShootTz(shoot)
    })


    useEffect(() => {
        console.log("fetch shoots tz")
        if (selectedDay !== 0) {
            fetchShootTz()
        }
    }, [selectedDay]);

    useEffect(() => {
        console.log("fetch shoots")
        if (filterModal === false) {
            fetchShoots()
        }
    }, [filter]);


    function onClickDiv(day) {
        console.log(day)
        setSelectedDay(day)
    }

    function formatWithHiperlink(inputString) {
        if (inputString == null) return <label></label>
        let rx = /(https:\/\/|http:\/\/|www\.)\S+/g
        let arr = inputString.match(rx) || [];
        if (arr.length == 0) return <label>{inputString}</label>
        let arrPosition = inputString.search(rx)
        let rx2 = arr[0]
        let arr2 = inputString.split(rx2)
        return <label className={cl.lab}>
            {arrPosition !== 0 && <label>{arr2[0]}</label>}
            <a target="_blank" rel="noopener noreferrer" href={rx2}>Ссылка</a>
            {arr2.length > 1 && <label>{formatWithHiperlink(arr2[1])}</label>}
        </label>;
    }

    function handleCheck(orderRowId, modelId, orderId, e) {
        axios.post(
            apiUrl + 'order_rows/set_shooted',
            {
                orderRowId: orderRowId,
                shooted: e.target.checked
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setShootTz({
                    ...shootTz,
                    bookings: [...shootTz.bookings.map((booking) => booking.modelPerson.id !== modelId
                        ? booking
                        : {
                            ...booking, orders: [...booking.orders.map((order) => order.id !== orderId
                                ? order
                                : {
                                    ...order,
                                    orderRows: [...order.orderRows.map((orderRow) => orderRow.id !== orderRowId
                                        ? orderRow
                                        : {...orderRow, shooted: res.data.shooted})
                                    ]
                                }
                            )]
                        })]
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    function saveResult(orderId, modelId) {
        axios.post(
            apiUrl + 'orders/set_result',
            {
                orderId: orderId,
                result: editResult
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setShootTz({
                    ...shootTz,
                    bookings: [...shootTz.bookings.map((booking) => booking.modelPerson.id !== modelId
                        ? booking
                        : {
                            ...booking, orders: [...booking.orders.map((order) => order.id !== orderId
                                ? order
                                : {
                                    ...order,
                                    result: res.data.result
                                }
                            )]
                        })]
                })
                setModalResultVisible(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    function saveComment(orderId, modelId, orderRowId) {
        axios.post(
            apiUrl + 'order_rows/set_comment',
            {
                orderRowId: orderRowId,
                shootComment: editComment
            },
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setShootTz({
                    ...shootTz,
                    bookings: [...shootTz.bookings.map((booking) => booking.modelPerson.id !== modelId
                        ? booking
                        : {
                            ...booking, orders: [...booking.orders.map((order) => order.id !== orderId
                                ? order
                                : {
                                    ...order,
                                    orderRows: [...order.orderRows.map((orderRow) => orderRow.id !== orderRowId
                                        ? orderRow
                                        : {...orderRow, shootComment: res.data.shootComment})
                                    ]
                                }
                            )]
                        })]
                })
                setModalCommentVisible(false)

            })
            .catch(err => {
                console.log(err);
            })
    }

    function saveTiming() {
        console.log(editBooking)
        axios.post(
            apiUrl + 'booking/set_timing',
            editBooking
            ,
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
        )
            .then(res => {
                console.log(res.data);
                setShootTz({
                    ...shootTz,
                    bookings: [...shootTz.bookings.map((booking) => booking.id !== editBooking.id
                        ? booking
                        : {
                            ...booking,
                            planTime: res.data.planTime,
                            factTime: res.data.factTime,
                            planDuration: res.data.planDuration,
                            factDuration: res.data.factDuration,
                            bookingStatus: res.data.bookingStatus,
                            bookingStatusName: res.data.bookingStatusName
                        })]
                })
                setModalBookingVisible(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className="App">
            <Button size="small" variant="outlined" onClick={(e) => setFilterModal(true)}>
                Фильтр
            </Button>
            <Button size="small" variant="outlined" onClick={(e) => {
                setFilter({date: ''})
            }}>
                Сбросить
            </Button>
            <ModelCard model={showModel} setVisible={setShowModelModal} visible ={showModelModal}/>
            <BookingForm visible={modalBookingVisible} setVisible={setModalBookingVisible}
                         setEditBooking={setEditBooking} editBooking={editBooking} saveTiming={saveTiming}
            />
            <MyModal visible={filterModal} setVisible={setFilterModal}>
                <TextField
                    type='date'
                    variant='standard'
                    size='small'
                    id="outlined-textareaa"
                    label="месяц с даты"
                    onChange={(e) => setFilter({...filter, date: e.target.value})}
                    value={filter.date}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                />
                <Button onClick={(e) => {
                    fetchShoots()
                    setFilterModal(false)
                }}>Применить</Button>
            </MyModal>
            <MyModal visible={modalResultVisible} setVisible={setModalResultVisible}>
                <TextField
                    required
                    id="outlined-required"
                    label="Ссылки на результат"
                    value={editResult}
                    onChange={(e) => setEditResult(e.target.value)}
                    margin="dense"
                    multiline
                    rows={4}
                />
                <Button variant="contained"
                        onClick={(e) => saveResult(editOrderId, editModelId)}>Сохранить</Button>
            </MyModal>
            <MyModal visible={modalCommentVisible} setVisible={setModalCommentVisible}>
                <TextField
                    required
                    id="outlined-required"
                    label="Комментарий съемочной команды"
                    value={editComment}
                    onChange={e => setEditComment(e.target.value)}
                    margin="dense"
                    multiline
                    rows={4}
                />
                <Button variant="contained"
                        onClick={(e) => saveComment(editOrderId, editModelId, editOrderRowId)}>Сохранить</Button>
            </MyModal>
            {shootsError &&
                <h3> Произошла ошибка: Вы не авторизованы <Link to="/login">Логин</Link></h3>}
            {isShootLoading
                ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}><Loader/></div>
                : <div>
                    <h3 style={{textAlign: "center"}}>Съемки</h3>
                    <div className="shoot_list">
                        {shoots.map((shoot) =>
                            <ShootTzItem
                                onClickDiv={onClickDiv}
                                key={shoot.id}
                                shoot={shoot}
                            />
                        )}
                    </div>
                </div>
            }
            <hr style={{margin: '10px'}}/>
            {shootTz &&
                <div className="vertical_left_conteiner">
                    <div className="horizont_left_conteiner" style={{alignItems: "center"}}>
                        <div>{shootTz.date}</div>
                        <div>{shootTz.shootingLocation.name}</div>
                        {shootTz.room &&
                            <div>{shootTz.room.roomName}</div>
                        }
                        <Button size="small" variant="outlined" onClick={(e) => {
                            setOrderFilter((oldOrderFilter) => oldOrderFilter.map(el => el.code !== "date"
                                ? {...el, checked: false}
                                : {
                                    ...el,
                                    checked: true,
                                    value: shootTz.dateToFilter,
                                    valueForChip: shootTz.dateToFilter
                                }
                            ).map(el => el.code !== "sortid"
                                ? {...el}
                                : {...el, checked: true, value: "desc", valueForChip: "По убыванию"}))
                            navigate("/admin/orders")
                        }}>Все заказы</Button>
                    </div>
                    <div className="vertical_left_conteiner_bordered">
                        {shootTz.bookings.sort(function (a, b) {
                            return a.planTimeLong - b.planTimeLong || a.modelPerson.name.localeCompare(b.modelPerson.name) || a.modelPerson.id - b.modelPerson.id;
                        }).map((booking) =>
                            <div key={booking.id} className="vertical_left_conteiner_bordered"
                                 style={{marginBottom: "3px"}}>
                                <div className="vertical_left_conteiner">
                                    <div className="horizont_left_conteiner" style={{alignItems: "center"}}>
                                        <div>{booking.modelPerson.name}</div>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={(e) => {
                                                setEditBooking(booking)
                                                setModalBookingVisible(true)
                                            }}
                                        >
                                            Задать тайминг
                                        </Button>
                                    </div>
                                    <div className="horizont_left_conteiner" style={{alignItems: "center"}}>
                                        <img style={{
                                            borderRadius: '8px',
                                            maxWidth: 'auto',
                                            maxHeight: '60px',
                                            cursor: 'pointer'
                                        }}
                                             src={`/images/medium/${booking.modelPerson.photo}`}
                                             alt={'Фото ' + booking.modelPerson.name}
                                             onClick={(e) => {
                                                 console.log(booking.modelPerson)
                                                 setShowModel(booking.modelPerson)
                                                 setShowModelModal(true)
                                             }
                                             }
                                        />
                                        <div className="vertical_left_conteiner"
                                             style={{border: "1px solid black", height: "100%"}}>
                                            <div>Статус брони: {booking.bookingStatusName}</div>
                                            <div>План. Время: {booking.planTime}</div>
                                            <div>План. Длительность: {booking.planDuration}ч</div>
                                            <div>Факт. Время: {booking.factTime}</div>
                                            <div>Факт. Длительность: {booking.factDuration}ч</div>
                                        </div>
                                        {booking.comment &&
                                            <div className="vertical_left_conteiner"
                                                 style={{border: "1px solid black", height: "100%"}}>
                                                <div>Коммент для букинга:</div>
                                                <div>{booking.comment}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="vertical_left_conteiner_bordered">
                                    {booking.orders.length === 0 &&
                                        <div>Нет заказов</div>
                                    }
                                    {booking.orders.sort(function (a, b) {
                                        return a.id - b.id;
                                    }).map((order) =>
                                        <div key={order.id} style={{margin: "2px"}}
                                             className="vertical_left_conteiner_bordered">
                                            <div className="vertical_left_conteiner">
                                                <div className="horizont_left_conteiner" style={{alignItems: "center"}}>
                                                    {order.manager &&
                                                        <div className="div_padding_left">{order.manager.name}</div>}
                                                    <div className="div_padding_left">
                                                        <strong style={{cursor: "pointer"}}
                                                                onClick={(e) => {
                                                                    setOrderFilter((oldOrderFilter) => oldOrderFilter.map(el => el.code !== "orderId"
                                                                        ? {...el, checked: false}
                                                                        : {
                                                                            ...el,
                                                                            checked: true,
                                                                            value: order.id,
                                                                            valueForChip: order.id
                                                                        }
                                                                    ).map(el => el.code !== "sortid"
                                                                        ? {...el}
                                                                        : {
                                                                            ...el,
                                                                            checked: true,
                                                                            value: "desc",
                                                                            valueForChip: "По убыванию"
                                                                        }))
                                                                    navigate("/admin/orders")
                                                                }}
                                                        >Заказ {order.id}</strong>
                                                    </div>
                                                    {order.client &&
                                                        <div className="div_padding_left">{order.client.name}</div>}
                                                    <div className="div_padding_left">{order.payStatusName}</div>
                                                    <div className="div_padding_left">{order.workOrderStatusName}</div>
                                                    <Button size="small"
                                                            variant="outlined"
                                                            onClick={(e) => {
                                                                setOrderFilter((oldOrderFilter) => oldOrderFilter.map(el => el.code !== "orderId"
                                                                    ? {...el, checked: false}
                                                                    : {
                                                                        ...el,
                                                                        checked: true,
                                                                        value: order.id,
                                                                        valueForChip: order.id
                                                                    }
                                                                ).map(el => el.code !== "sortid"
                                                                    ? {...el}
                                                                    : {
                                                                        ...el,
                                                                        checked: true,
                                                                        value: "desc",
                                                                        valueForChip: "По убыванию"
                                                                    }))
                                                                navigate("/admin/orders")
                                                            }}>В заказы</Button>
                                                </div>
                                                <div style={{
                                                    maxWidth: "300px",
                                                    border: "1px solid teal"
                                                }}>{order.managerComment}</div>
                                                <div style={{
                                                    maxWidth: "300px",
                                                    border: "1px solid teal"
                                                }}>{order.clientComment}</div>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    component="label"
                                                    onClick={(e) => {
                                                        setEditResult(order.result ? order.result : '')
                                                        setEditOrderId(order.id)
                                                        setEditModelId(booking.modelPerson.id)
                                                        setModalResultVisible(true)
                                                    }}
                                                >
                                                    Ссылка на фото
                                                </Button>
                                                <a href={order.result}>{order.result}</a>
                                            </div>
                                            <div className="horizont_grid">
                                                {order.orderRows.sort(function (a, b) {
                                                    return a.tarif.tarifTypeName.localeCompare(b.tarif.tarifTypeName) || a.id - b.id;
                                                }).map((orderRow) =>
                                                    <div key={orderRow.id}
                                                         className={orderRow.shooted ? "vertical_left_conteiner_order_checked" : "vertical_left_conteiner_order"}>
                                                        {orderRow.background &&
                                                            <div><strong>Фон:</strong>{orderRow.background.backName}
                                                            </div>
                                                        }
                                                        {orderRow.tarif &&
                                                            <div>
                                                                <strong>Тариф:</strong>{orderRow.tarif.tarifTypeName} {orderRow.tarif.tarifName}
                                                            </div>
                                                        }
                                                        <div>
                                                            <strong>Цена за шт:</strong>{orderRow.baseCost}
                                                        </div>
                                                        <div>
                                                            <strong>Кол-во:</strong>{orderRow.quantity}
                                                        </div>
                                                        <div>
                                                            <strong>Цена:</strong>{orderRow.cost}
                                                        </div>
                                                        <div>
                                                            <strong>Товар:</strong>{orderRow.good}
                                                        </div>
                                                        <div className="vertical_left_conteiner_bordered">
                                                            <div>
                                                                <strong>Детали</strong>
                                                            </div>
                                                            {formatWithHiperlink(orderRow.details)}
                                                        </div>
                                                        <div className="vertical_left_conteiner_bordered">
                                                            <div>
                                                                <strong>Образ</strong>
                                                            </div>
                                                            {formatWithHiperlink(orderRow.fasion)}
                                                        </div>
                                                        <div className="vertical_left_conteiner_bordered">
                                                            <div>
                                                                <strong>Важно</strong>
                                                            </div>
                                                            {formatWithHiperlink(orderRow.important)}
                                                        </div>
                                                        <div>
                                                            {orderRow.imageCollection !== null && orderRow.imageCollection.collection.length > 0
                                                                ? <div><Button variant="outlined"
                                                                               onClick={(e) => {
                                                                                   setImageCollectionWithSignature(true)
                                                                                   setModalCollectionVisible(true)
                                                                                   setImageCollection(orderRow.imageCollection)
                                                                               }}>Рефы</Button></div>
                                                                : <div><strong>Рефы</strong>:Нет</div>}
                                                        </div>
                                                        <div>
                                                            <strong>Отснят?</strong>
                                                            <Checkbox checked={orderRow.shooted}
                                                                      onChange={(e) => handleCheck(orderRow.id, booking.modelPerson.id, order.id, e)}/>
                                                        </div>
                                                        <div onClick={(e) => {
                                                            setEditComment(orderRow.shootComment ? orderRow.shootComment : '')
                                                            setEditOrderId(order.id)
                                                            setEditModelId(booking.modelPerson.id)
                                                            setEditOrderRowId(orderRow.id)
                                                            setModalCommentVisible(true)
                                                        }}><strong>Комент съемки: </strong>
                                                            {orderRow.shootComment}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default AdminShoot;