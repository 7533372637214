import React from 'react';
import Loader from "../Loader/Loader";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import cl from "./BlockOfDaysModel.module.css"

const BlockOfDaysModel = (props) => {
    return (
        <div className={cl.constructor_type_shoot_block}>
            {props.isDayLoading ?
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '60px'}}>
                    <Loader/></div>
                :
                <div className="vertical_conteiner">
                    <ToggleButtonGroup
                        color="primary"
                        value={props.day.id}
                        exclusive
                        aria-label="Platform"
                    >
                        <ToggleButton onClick={() => props.setDay({id:0})} value={0}>Индивидуальная
                            съемка</ToggleButton>
                        {props.days.map((but) => (
                            <ToggleButton
                                key={but.id}
                                onClick={() => {
                                    console.log(props.days.find((value) => (value.id == but.id)))
                                    props.setDay(props.days.find((value) => (value.id == but.id)))}
                            }
                                value={but.id}
                            >
                                {but.date}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    {props.days.length === 0 &&
                    <div>
                            На ближайшее время у модели не запланированны съемки. Доступна только индивидуальная съемка.
                    </div>
                    }
                    {props.day.id === 0 ?
                        <div className="vertical_conteiner">
                            {props.days.length !== 0 &&
                            <div>
                                Выбрана индивидуальная съёмка.
                            </div>
                            }
                            <div>
                                При индивидуальной съемке кроме тарифа оплачивается также почасовая работа модели.
                            </div>
                            <div>
                                При такой съемке мы рассматриваем любые виды заданий.
                            </div>
                        </div>
                        :
                        <div className="vertical_conteiner">
                            <div>
                                Выбрана сборная съёмка.
                            </div>
                            <div>
                                Стоимость услуг расчитана с учетом класса модели
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default BlockOfDaysModel;